import Visibility from "@mapmycustomers/shared/enum/Visibility";
import Team from "@mapmycustomers/shared/types/Team";
import User, { UserRef } from "@mapmycustomers/shared/types/User";

import { isMember, isOwner, isReadOnly } from "../../store/iam/util";

const getActivityAllowedUsers = (
  currentUserId: User["id"],
  users: User[],
  allowedByAssociationsUserIds: User["id"][],
  visibility: Visibility,
  teamIds: Team["id"][],
  assignee?: UserRef
): User["username"][] =>
  users
    .filter(
      (user) =>
        // public activity
        Visibility.SHARED_WITH_ORGANIZATION === visibility ||
        // org owners
        isOwner(user) ||
        // user himself
        user.id === currentUserId ||
        // assignee
        user.id === assignee?.id ||
        // users which have access to associated entities
        allowedByAssociationsUserIds.includes(user.id) ||
        // private activity - for user teammates
        (Visibility.PRIVATE === visibility &&
          user.viaTeamAccess &&
          !isMember(user) &&
          !isReadOnly(user)) ||
        // shared with teams activity - all selected teams users
        (Visibility.SHARED_WITH_TEAM === visibility &&
          user.teams.some(({ id }) => teamIds.includes(id)))
    )
    .map(({ username }) => username);

export default getActivityAllowedUsers;
