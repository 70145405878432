import { Editor, Range, Selection } from "slate";

const findMention = (
  selection: Selection,
  editor: Editor
): { search: string; target: Range } | undefined => {
  if (selection === null) {
    return;
  }

  const [start] = Range.edges(selection);

  const beforeRange = Editor.range(editor, { offset: 0, path: start.path }, start);
  const beforeText = Editor.string(editor, beforeRange);

  const lastMentionIndex = beforeText.lastIndexOf("@");

  if (lastMentionIndex === -1) {
    return;
  }

  const search = beforeText.slice(lastMentionIndex + 1);

  if (
    !search.match(/^[\w+-.!#$%&'*/=?^_`{|}~"(),:;<>@[\]()]*$/) ||
    (beforeText.at(lastMentionIndex - 1) !== " " && lastMentionIndex)
  ) {
    return;
  }

  // Create a target range from "@" to the cursor position
  const target = {
    anchor: { offset: lastMentionIndex, path: start.path },
    focus: start,
  };

  return { search, target };
};

export default findMention;
