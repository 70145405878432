import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Checkbox from "antd/es/checkbox";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { differenceInMinutes } from "date-fns/esm";
import { defineMessages, useIntl } from "react-intl";

import ActivityCompletion from "@mapmycustomers/shared/enum/activity/ActivityCompletion";
import ActivityRemindType from "@mapmycustomers/shared/enum/activity/ActivityRemindType";
import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { Activity, Deal, EntityType, Person } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Company from "@mapmycustomers/shared/types/entity/Company";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useRerenderer from "@mapmycustomers/shared/util/hook/useRerenderer";
import {
  Alert,
  FormItem,
  LoadingSpinner,
  SelectField,
  TextAreaWithMentions,
} from "@mapmycustomers/ui";

import AssigneeSearch from "@app/component/activity/AssigneeSearch";
import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "@app/component/ConditionalFormField";
import SelectDuplicateFieldsModal from "@app/component/createEditEntity/Activity/component/SelectDuplicateFieldsModal";
import { useRecurIntervalTypeValueChangeHandler } from "@app/component/createEditEntity/Activity/utils/useRecurIntervalTypeValueChangeHandler";
import FormFields from "@app/component/FormFields";
import {
  CompanyRelationshipField,
  DealRelationshipField,
  PersonRelationshipField,
} from "@app/component/FormFields/components/Associations";
import FormLayoutSelector from "@app/component/FormLayoutSelector";
import FormNoLayoutAlert from "@app/component/FormNoLayoutAlert";
import VisibilitySelectField from "@app/component/input/VisibilitySelectField";
import BaseModal from "@app/component/modal/BaseModal";
import { isPostCreationModalVisible } from "@app/component/PostCreationModal/store";
import { hideGlobalPostCreationModal } from "@app/component/PostCreationModal/store/actions";
import PreviewModeIndicator from "@app/component/PreviewModeIndicator";
import localSettings from "@app/config/LocalSettings";
import OrganizationSetting from "@app/enum/OrganizationSetting";
import { getActivityTypes } from "@app/store/activity";
import type AssociationsState from "@app/store/associations/AssociationsState";
import {
  getCurrentUser,
  getMe,
  getOrganizationSettingValue,
  isCurrentUserManager,
  isCurrentUserOwner,
} from "@app/store/iam";
import { getTeams, getUsersOfEntireOrganization } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import FileListItem from "@app/types/FileListItem";
import FrequencyCriteria from "@app/types/frequncy/FrequencyCriteria";
import HideAction from "@app/types/HideCallback";
import Iam from "@app/types/Iam";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import { ACTIVITY_DEFAULT_REMIND_TIME } from "@app/util/consts";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import useFormChangeTracker from "@app/util/hook/form/useFormChangeTracker";
import useActivityVisibilityOptions from "@app/util/hook/useActivityVisibilityOptions";
import useCustomFieldValidation from "@app/util/hook/useCustomFieldValidation";
import useErroredFieldsScroller from "@app/util/hook/useErroredFieldsScroller";
import isValidDate from "@app/util/isValidDate";
import { activityLayoutModel } from "@app/util/layout/impl";
import { getActivityVisibilityNote } from "@app/util/ui";
import useActivityTypeOptions from "@app/util/ui/useActivityTypeOptions";

import NotificationSettings from "../NotificationSettings";

import ActivityFooter from "./component/ActivityFooter";
import ActivityNameField from "./component/ActivityNameField";
import File from "./component/File";
import FrequencyCriteriaField from "./component/FrequencyCriteriaField";
import RelatedEntitiesMatching from "./component/RelatedEntitiesMatching";
import SubmitButton from "./component/SubmitButton";
import styles from "./CreateEditActivityModal.module.scss";
import RecurringIntervalType from "./enum/RecurringIntervalType";
import {
  getAssociationsState,
  getPrefilledValues,
  getUploadedFilesList,
  isCloningActivity,
  isCreating,
  isInitializing,
} from "./store";
import {
  changeAssociatedEntities,
  clearAllUploadedCreateEditActivityFiles,
  clearAssociations,
  createActivity,
  initializeActivityModal,
  relateUnrelateEntities,
} from "./store/actions";
import FormValues from "./types/FormValues";
import getFormInitialValues from "./utils/getFormInitialValues";
import inaccessibleEntityAlertMessage from "./utils/inaccessibleEntityAlertMessage";
import useActivityNameAutoFill from "./utils/useActivityNameAutoFill";
import useActivityTypeValidationRules from "./utils/useActivityTypeValidationRules";
import useCreateCallback from "./utils/useCreateCallback";
import useFrequencyCriteria from "./utils/useFrequencyCriteria";
import { useIsRecurrentValueChangeHandler } from "./utils/useIsRecurrentValueChangeHandler";
import useMentionsValidation from "./utils/useMentionsValidation";
import useSaveModeHandler from "./utils/useSaveModeHandler";

export const messages = defineMessages({
  duplicated: {
    id: "createActivityModal.cloningInfo",
    defaultMessage: 'Make any desired modifications and click "Create Activity" to duplicate.',
    description: "Information for duplicated activities",
  },
  requiredFields: {
    id: "createActivityModal.requiredFieldAlert",
    defaultMessage: "Fill out all required fields to log activity.",
    description: "Required Field alert while logging activity",
  },
});

interface OwnProps {
  analyticIssuer?: AnalyticsService;
  className?: string;
  defaultActivityTypeId?: ActivityType["id"];
  defaultStartAt?: Date;
  fixedCompany?: Company;
  fixedDeal?: Deal;
  fixedPerson?: Person;
  frequencyCriteria?: FrequencyCriteria;
  onSuccess?: () => void;
  previewLayout?: FormLayout;
  unavailableActivityType?: Pick<ActivityType, "id" | "name">;
}

interface Props extends OwnProps {
  activityTypeExtra?: ReactNode;
  activityTypes: ActivityType[];
  associations: AssociationsState;
  clearAllUploadedFiles: () => void;
  clearAssociations: typeof clearAssociations;
  cloning: boolean;
  createActivity: typeof createActivity.request;
  creating: boolean;
  currentUser: User;
  hideGlobalPostCreationModal: typeof hideGlobalPostCreationModal;
  initialActivityTypeId?: ActivityType["id"];
  initialCompletion: boolean;
  initialize: typeof initializeActivityModal.request;
  initializing: boolean;
  initialStartAt: Date;
  initialVisibility: ActivityVisibility;
  isManager: boolean;
  isOwner: boolean;
  isPostCreationModalVisible: boolean;
  me: Iam;
  onChangeAssociatedEntities: typeof changeAssociatedEntities.request;
  onHide: HideAction<Activity>;
  onRelateUnrelateEntities: typeof relateUnrelateEntities.request;
  prefilledValues?: Partial<Omit<Activity, "id">>;
  teams: Team[];
  uploadedFiles: FileListItem[];
  users: User[];
}

const CreateActivityModal: React.FC<Props> = ({
  activityTypeExtra,
  activityTypes,
  analyticIssuer,
  associations,
  className,
  clearAllUploadedFiles,
  clearAssociations,
  cloning,
  createActivity,
  creating,
  currentUser,
  fixedCompany,
  fixedDeal,
  fixedPerson,
  frequencyCriteria,
  hideGlobalPostCreationModal,
  initialActivityTypeId,
  initialCompletion,
  initialize,
  initializing,
  initialStartAt,
  initialVisibility,
  isManager,
  isOwner,
  isPostCreationModalVisible,
  me,
  onChangeAssociatedEntities,
  onHide,
  onRelateUnrelateEntities,
  onSuccess,
  prefilledValues,
  previewLayout,
  teams,
  unavailableActivityType,
  uploadedFiles,
  users,
}) => {
  const intl = useIntl();
  const forceRender = useRerenderer();

  const [formLayout, setFormLayout] = useState(activityLayoutModel.defaultFormLayout);
  const [formFieldsRef, handleFinishFail] = useErroredFieldsScroller();

  const [shouldCreateAnother, , , toggleCreateAnother] = useBoolean(false);
  const [duplicateFieldsModalVisible, showDuplicateFieldsModal, hideDuplicateFieldsModal] =
    useBoolean(false);
  const [isActivityNameChangedByUser, setIsActivityNameChangedByUserToTrue] = useBoolean(false);
  const handleCreateAnotherChange = useCallback(() => toggleCreateAnother(), [toggleCreateAnother]);
  const visibilityOptions = useActivityVisibilityOptions(isOwner, isManager);

  const noAssociations = useMemo(
    () =>
      !associations.associatedCompany &&
      !associations.associatedDeal &&
      !associations.associatedPerson,
    [associations.associatedCompany, associations.associatedDeal, associations.associatedPerson]
  );

  useEffect(() => {
    initialize({
      fixedCompany: fixedCompany || prefilledValues?.account,
      fixedDeal: fixedDeal || prefilledValues?.deal,
      fixedPerson: fixedPerson || prefilledValues?.contact,
    });
  }, [fixedCompany, fixedPerson, fixedDeal, initialize, prefilledValues]);

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);

  const isCheckInActivity = false;

  const activityTypeValidationRules = useActivityTypeValidationRules(intl, unavailableActivityType);
  const activityTypeOptions = useActivityTypeOptions(
    activityTypes,
    form.getFieldValue("activityTypeId"),
    useCallback(
      (type) => !type.active || (isCheckInActivity && !type.checkInEnabled),
      [isCheckInActivity]
    )
  );

  const handleCreateAnother = useCallback(() => {
    onSuccess?.();
    const fieldsToKeep = localSettings.getFollowUpFields();
    const formValuesToKeep: Partial<FormValues> = {};
    fieldsToKeep.forEach((fieldName) => {
      if (fieldName === ActivityFieldName.NAME) {
        formValuesToKeep.name = form.getFieldValue(ActivityFieldName.NAME);
      } else if (fieldName === ActivityFieldName.ACTIVITY_TYPE) {
        formValuesToKeep.activityTypeId = form.getFieldValue("activityTypeId");
      } else if (fieldName === ActivityFieldName.COMPANY) {
        formValuesToKeep.companyId = form.getFieldValue("companyId");
      } else if (fieldName === ActivityFieldName.PERSON) {
        formValuesToKeep.personId = form.getFieldValue("personId");
      } else if (fieldName === ActivityFieldName.DEAL) {
        formValuesToKeep.dealId = form.getFieldValue("dealId");
      }
    });
    form.resetFields();
    clearAssociations({
      [EntityType.COMPANY]: !fieldsToKeep.includes(ActivityFieldName.COMPANY),
      [EntityType.DEAL]: !fieldsToKeep.includes(ActivityFieldName.DEAL),
      [EntityType.PERSON]: !fieldsToKeep.includes(ActivityFieldName.PERSON),
    });
    form.setFieldsValue(formValuesToKeep);
    hideDuplicateFieldsModal();
  }, [clearAssociations, form, hideDuplicateFieldsModal, onSuccess]);

  const hideDuplicateFieldModal = useCallback(() => {
    onSuccess?.();
    hideDuplicateFieldsModal();
    onHide();
  }, [hideDuplicateFieldsModal, onHide, onSuccess]);

  const handleCancelClick = useCallback(() => {
    clearAssociations();
    clearAllUploadedFiles();
    onHide();
  }, [clearAssociations, clearAllUploadedFiles, onHide]);

  const handleActivityModalVisibility: HideAction<Activity> = useCallback(
    (updated?: boolean, activity?: Activity) => {
      onHide(updated, activity);
      if (isPostCreationModalVisible) {
        hideGlobalPostCreationModal();
      }
    },
    [isPostCreationModalVisible, hideGlobalPostCreationModal, onHide]
  );

  const handleCustomFieldValidate = useCustomFieldValidation(form, handleFinishFail, true);

  const handleFormFinish = useCreateCallback(
    activityTypes,
    createActivity,
    form,
    formLayout,
    shouldCreateAnother ? showDuplicateFieldsModal : handleActivityModalVisibility,
    analyticIssuer,
    handleCustomFieldValidate
  );

  const [, allowedUserEmails, handleAllMentionedUsersHaveAccessToActivity] = useMentionsValidation(
    me,
    users,
    associations.associatedCompany,
    associations.associatedDeal,
    associations.associatedPerson
  );

  const isRecurrentValueChangeHandler = useIsRecurrentValueChangeHandler(form);
  const recurIntervalTypeValueChangeHandler = useRecurIntervalTypeValueChangeHandler(form);
  const doesActivityMeetCriteria = useFrequencyCriteria(
    initialStartAt,
    form,
    initialActivityTypeId,
    frequencyCriteria
  );

  const [{ isActivityPublic }, saveModeHandler] = useSaveModeHandler();

  const handleActivityChange = useCallback(
    (values: FormValues, allValues: FormValues) => {
      if (allValues.endAt && allValues.startAt && allValues.endAt < allValues.startAt) {
        form.setFieldsValue({ endAt: allValues.startAt });
      }
    },
    [form]
  );

  const handleFormChangeRerender = useCallback(() => {
    handleFormChanges();
    forceRender();
  }, [forceRender, handleFormChanges]);

  const handleIsMentionDisabled = useCallback(
    (user: User) => !allowedUserEmails?.includes(user.username),
    [allowedUserEmails]
  );

  const [activityNameAutoFillValueChangeHandler] = useActivityNameAutoFill(
    form,
    isActivityNameChangedByUser
  );

  const handleValuesChange = useCallback(
    (changedValues, values) => {
      [
        activityNameAutoFillValueChangeHandler,
        isRecurrentValueChangeHandler,
        recurIntervalTypeValueChangeHandler,
        saveModeHandler,
        handleFormChanges,
        handleAllMentionedUsersHaveAccessToActivity,
        handleActivityChange,
      ].forEach((fn) => fn(changedValues, values));
    },
    [
      activityNameAutoFillValueChangeHandler,
      handleActivityChange,
      handleAllMentionedUsersHaveAccessToActivity,
      handleFormChanges,
      isRecurrentValueChangeHandler,
      recurIntervalTypeValueChangeHandler,
      saveModeHandler,
    ]
  );

  const isPreviewMode = !!previewLayout;

  const currentLayout = isPreviewMode ? previewLayout : formLayout;

  const submitButtonDisabled = creating || initializing || isPreviewMode || !currentLayout;
  const showConfirm =
    !isPreviewMode && !initializing && (isFormChanged || uploadedFiles.length > 0);

  const isCompleted = form.getFieldValue(ActivityFieldName.COMPLETED);
  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const isPastDate = isValidDate(dateValue) && differenceInMinutes(new Date(), dateValue) >= 60;

  const handleUserChange = useCallback(
    (user?: User) => {
      onChangeAssociatedEntities({
        assignee: user,
      });
    },
    [onChangeAssociatedEntities]
  );

  const defaultLayoutActivityTypeId = useMemo(() => {
    return currentLayout?.schema.find((field) => field.field === "crmActivityTypeId")
      ?.defaultValue?.[0] as ActivityType["id"];
  }, [currentLayout]);

  const initialValues = prefilledValues
    ? getFormInitialValues(
        prefilledValues,
        currentUser,
        initialActivityTypeId ?? defaultLayoutActivityTypeId,
        initialCompletion,
        initialVisibility,
        true
      )
    : {
        activityTypeId: initialActivityTypeId ?? defaultLayoutActivityTypeId,
        assignee: currentUser,
        assigneeId: currentUser.id,
        companyId: fixedCompany?.id,
        completed: initialCompletion,
        dealId: fixedDeal?.id,
        isRecurrent: false,
        name: "",
        personId: fixedPerson?.id,
        recurIntervalType: RecurringIntervalType.NEVER,
        reminders: localSettings.getActivityCreationNotifyPreferences() ?? [
          { remindBeforeMinutes: ACTIVITY_DEFAULT_REMIND_TIME, remindVia: ActivityRemindType.PUSH },
        ],
        startAt: initialStartAt,
        teamIds: [],
        visibility: initialVisibility,
      };

  const appendAddableFieldsWithFilter = useCallback(
    (field: IField) => (prefilledValues ? field.hasNonEmptyValueFor(prefilledValues) : false),
    [prefilledValues]
  );

  return (
    <>
      <BaseModal
        borderedHeader
        className={cn(styles.modal, className)}
        entityTypeName="Activity"
        extra={
          <div className={styles.extra}>
            <FrequencyCriteriaField
              doesActivityMeetCriteria={doesActivityMeetCriteria}
              frequencyCriteria={frequencyCriteria}
              isCompleted={isCompleted}
            />
            <RelatedEntitiesMatching
              associatedCompany={associations.associatedCompany}
              associatedDeal={associations.associatedDeal}
              associatedPerson={associations.associatedPerson}
            />
          </div>
        }
        footer={null}
        noContentScroll
        onCancel={handleCancelClick}
        showConfirm={showConfirm}
        subtitle={
          initializing || !currentLayout ? undefined : (
            <NotificationSettings form={form} onChange={handleFormChanges} />
          )
        }
        title={
          <>
            {intl.formatMessage({
              id: "createActivityModal.header",
              defaultMessage: "Create Activity",
              description: "Header of Create Activity modal",
            })}

            {isPreviewMode ? (
              <PreviewModeIndicator />
            ) : (
              <FormLayoutSelector
                disabled={creating}
                entityType={EntityType.ACTIVITY}
                layout={formLayout}
                onChange={setFormLayout}
              />
            )}
          </>
        }
      >
        {initializing ? (
          <LoadingSpinner />
        ) : currentLayout ? (
          <div className={styles.formContainer} ref={formFieldsRef}>
            <Form<FormValues>
              className={styles.form}
              form={form}
              initialValues={initialValues}
              layout="vertical"
              onFinish={handleFormFinish}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleValuesChange}
              preserve
            >
              <div className={styles.content}>
                {associations.inaccessibleEntity && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    message={intl.formatMessage(inaccessibleEntityAlertMessage)}
                    showIcon
                    type="warning"
                  />
                )}
                {cloning && (
                  <Alert
                    className={styles.inaccessibleEntityAlert}
                    closable
                    message={intl.formatMessage(messages.duplicated)}
                    showIcon
                    type="info"
                  />
                )}
                <FormFields
                  appendAddableFieldsWithFilter={appendAddableFieldsWithFilter}
                  entityType={EntityType.ACTIVITY}
                  fileComponent={File}
                  ignoreRequired={!isCompleted}
                  isCreateForm
                  layout={currentLayout}
                >
                  <ConditionalFormField feature={FieldFeature.ACTIVITY_TYPE_FIELD}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <>
                        <FormItem
                          label={label}
                          name="activityTypeId"
                          required={required}
                          rules={activityTypeValidationRules}
                        >
                          <SelectField<ActivityType["id"]>
                            className={styles.select}
                            disabled={disabled}
                            dropdownMatchSelectWidth={false}
                            filterOption={(inputValue, option) =>
                              (
                                activityTypes.find(
                                  (type) => type.id === parseInt(`${option?.value || 0}`)
                                )?.name as string
                              )
                                ?.toLowerCase()
                                .includes(inputValue.toLowerCase().trim()) ?? false
                            }
                            label={label}
                            placeholder={
                              unavailableActivityType?.name ??
                              intl.formatMessage({
                                id: "createEditActivityModal.field.activityType.placeholder",
                                defaultMessage: "Select",
                                description:
                                  "Placeholder for the Activity Type field on the Create Activity modal",
                              })
                            }
                            required={required}
                            showSearch
                          >
                            {isCheckInActivity && (
                              <Select.Option
                                className={styles.message}
                                disabled
                                key="Activity Types"
                                label={intl.formatMessage({
                                  id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                  defaultMessage:
                                    "Some activity types may be disabled for Check-In activities",
                                  description:
                                    "Message for the CheckIn Activity Types while editing CheckIn Activity",
                                })}
                                value={-1}
                              >
                                {intl.formatMessage({
                                  id: "createEditActivityModal.field.activityType.checkInTypes.message",
                                  defaultMessage:
                                    "Some activity types may be disabled for Check-In activities",
                                  description:
                                    "Message for the CheckIn Activity Types while editing CheckIn Activity",
                                })}
                              </Select.Option>
                            )}
                            {activityTypeOptions.map((type) => (
                              <Select.Option
                                className={cn(styles.option, { [styles.disabled]: type.disabled })}
                                disabled={type.disabled}
                                key={type.value}
                                label={type.label}
                                value={type.value}
                              >
                                {type.label}
                              </Select.Option>
                            ))}
                          </SelectField>
                        </FormItem>
                        {activityTypeExtra}
                      </>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NAME}>
                    <ActivityNameField
                      activityTypes={activityTypes}
                      onChange={setIsActivityNameChangedByUserToTrue}
                    />
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.ASSIGNEE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={label}
                        name="assignee"
                        required={required}
                        rules={[{ required }]}
                      >
                        <AssigneeSearch
                          allowedUserEmails={allowedUserEmails}
                          disabled={disabled}
                          onChange={handleUserChange}
                          users={users}
                        />
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.COMPANY}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <CompanyRelationshipField
                        allowAdd={!(disabled && (fixedCompany || fixedPerson || fixedDeal))}
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        entityType={EntityType.ACTIVITY}
                        label={label}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedCompanies={
                          noAssociations ? undefined : associations.availableCompanies
                        }
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.PERSON}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <PersonRelationshipField
                        allowAdd={!(disabled && (fixedPerson || fixedDeal))}
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        label={label}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedPeople={noAssociations ? undefined : associations.availablePeople}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.DEAL}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <DealRelationshipField
                        allowAdd={!(disabled && fixedDeal)}
                        associatedCompany={associations.associatedCompany}
                        associatedDeal={associations.associatedDeal}
                        associatedPerson={associations.associatedPerson}
                        disabled={disabled}
                        field={activityFieldModel.getByName(ActivityFieldName.DEAL)!}
                        onChangeAssociatedEntities={onChangeAssociatedEntities}
                        relateEntities={onRelateUnrelateEntities}
                        required={required}
                        suggestedDeals={noAssociations ? undefined : associations.availableDeals}
                      />
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.NOTE}>
                    {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.note !== curValues.note ||
                          prevValues.visibility !== curValues.visibility
                        }
                      >
                        {({ getFieldValue }) => (
                          <FormItem
                            label={label}
                            name={ActivityFieldName.NOTE}
                            required={required}
                            rules={[{ required }]}
                          >
                            <TextAreaWithMentions
                              allUsers={users}
                              disabled={disabled}
                              emptyTextForWithSkippedUsers={intl.formatMessage({
                                id: "createEditActivityModal.field.note.emptyTextForWithSkippedUsers",
                                defaultMessage:
                                  "Only users that have view or edit access to either the association or the activity can be mentioned",
                                description:
                                  "Message for the Activity Note mention dropdown when some disabled users are skipped",
                              })}
                              hideDisabledUsers
                              isMentionDisabled={handleIsMentionDisabled}
                              key={
                                getFieldValue(ActivityFieldName.VISIBILITY) +
                                stringHash(allowedUserEmails?.join() ?? "")
                              }
                              label={label}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              required={required}
                            />
                          </FormItem>
                        )}
                      </Form.Item>
                    )}
                  </ConditionalFormField>

                  <ConditionalFormField fieldName={ActivityFieldName.VISIBILITY}>
                    {({ disabled, required }: ConditionalFormFieldRenderProps) => (
                      <Form.Item
                        label={intl.formatMessage({
                          id: "createEditActivityModal.field.visibility",
                          defaultMessage: "Visible to",
                          description:
                            "Visibility field label in Create create edit activity modal",
                        })}
                        required={required}
                        shouldUpdate={(prev, next) =>
                          prev.visibility !== next.visibility || prev.teamIds !== next.teamIds
                        }
                      >
                        {({ getFieldValue, setFieldsValue }) => (
                          <VisibilitySelectField<ActivityVisibility>
                            disabled={disabled}
                            noteGetter={getActivityVisibilityNote}
                            onChange={(visibility: ActivityVisibility) =>
                              setFieldsValue({ visibility })
                            }
                            onTeamIdsChange={(teamIds: Team["id"][]) => setFieldsValue({ teamIds })}
                            options={visibilityOptions}
                            teamIds={getFieldValue("teamIds")}
                            teams={teams}
                            teamsRenderer={(node) => (
                              <Form.Item name={"teamIds"} noStyle>
                                {node}
                              </Form.Item>
                            )}
                            value={getFieldValue(ActivityFieldName.VISIBILITY)}
                            visibilityRenderer={(node) => (
                              <Form.Item name={ActivityFieldName.VISIBILITY} noStyle>
                                {node}
                              </Form.Item>
                            )}
                          />
                        )}
                      </Form.Item>
                    )}
                  </ConditionalFormField>
                </FormFields>
              </div>
              <div className={styles.footer}>
                <div className={styles.left}>
                  <ActivityFooter creating form={form} onChange={handleFormChangeRerender} />
                </div>

                <div className={styles.right}>
                  <Tooltip
                    placement="topRight"
                    title={
                      isPreviewMode
                        ? intl.formatMessage({
                            id: "createActivityModal.footer.previewMode.tooltip",
                            defaultMessage: "Please exit Preview Mode to log activity",
                            description:
                              "Create Activity modal - OK button tooltip in preview mode",
                          })
                        : null
                    }
                    trigger={isPreviewMode ? ["hover"] : []}
                  >
                    <span>
                      <SubmitButton
                        buttonTitle={
                          !isValidDate(dateValue)
                            ? intl.formatMessage({
                                id: "createActivityModal.footer.saveActivity",
                                defaultMessage: "Save Activity",
                                description: "Save Activity button title on Create Activity modal",
                              })
                            : isPastDate || isCompleted
                            ? intl.formatMessage({
                                id: "createActivityModal.footer.logActivity",
                                defaultMessage: "Log Activity",
                                description: "Log Activity button title on Create Activity modal",
                              })
                            : intl.formatMessage({
                                id: "createActivityModal.footer.scheduleActivity",
                                defaultMessage: "Schedule Activity",
                                description:
                                  "Schedule Activity button title on Create Activity modal",
                              })
                        }
                        disabled={submitButtonDisabled}
                        doesActivityMeetCriteria={doesActivityMeetCriteria}
                        isActivityPublic={isActivityPublic}
                        key="submit"
                        loading={creating}
                        onCreate={form.submit}
                      />
                    </span>
                  </Tooltip>

                  <Checkbox
                    checked={shouldCreateAnother}
                    disabled={submitButtonDisabled}
                    key="createAnother"
                    onChange={handleCreateAnotherChange}
                  >
                    {isPastDate || isCompleted
                      ? intl.formatMessage({
                          id: "createActivityModal.footer.createFollowUp.title",
                          defaultMessage: "Create follow-up",
                          description: "Create follow-up label on Create Activity modal",
                        })
                      : intl.formatMessage({
                          id: "createActivityModal.footer.createAnother.title",
                          defaultMessage: "Create another after scheduling",
                          description: "Create another label on Create Activity modal",
                        })}
                  </Checkbox>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <div className={styles.content}>
            <FormNoLayoutAlert />
          </div>
        )}
      </BaseModal>
      <SelectDuplicateFieldsModal
        onContinue={handleCreateAnother}
        onHide={hideDuplicateFieldModal}
        visible={duplicateFieldsModalVisible}
      />
    </>
  );
};

const mapStateToProps = (
  state: RootState,
  { defaultActivityTypeId, defaultStartAt, frequencyCriteria }: OwnProps
) => ({
  activityTypes: getActivityTypes(state),
  associations: getAssociationsState(state),
  cloning: isCloningActivity(state),
  creating: isCreating(state),
  currentUser: getCurrentUser(state)!,
  initialActivityTypeId: defaultActivityTypeId ?? frequencyCriteria?.activityTypesIds?.[0],
  initialCompletion:
    getOrganizationSettingValue(state)(OrganizationSetting.ACTIVITY_COMPLETION_STATUS) !==
    ActivityCompletion.INCOMPLETE,
  initializing: isInitializing(state),
  initialStartAt: frequencyCriteria?.dueDate ?? defaultStartAt ?? new Date(),
  initialVisibility: ActivityVisibility.PRIVATE as ActivityVisibility,
  isManager: isCurrentUserManager(state),
  isOwner: isCurrentUserOwner(state),
  isPostCreationModalVisible: isPostCreationModalVisible(state),
  me: getMe(state)!,
  prefilledValues: getPrefilledValues(state),
  teams: getTeams(state),
  uploadedFiles: getUploadedFilesList(state),
  users: getUsersOfEntireOrganization(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedCreateEditActivityFiles,
  clearAssociations: clearAssociations,
  createActivity: createActivity.request,
  hideGlobalPostCreationModal: hideGlobalPostCreationModal,
  initialize: initializeActivityModal.request,
  onChangeAssociatedEntities: changeAssociatedEntities.request,
  onRelateUnrelateEntities: relateUnrelateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateActivityModal);
