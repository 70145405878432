import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import Address from "@mapmycustomers/shared/types/Address";
import LongLat from "@mapmycustomers/shared/types/base/LongLat";
import CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import {
  Company,
  EntitiesSupportingRoutes,
  EntityTypesSupportingRoutes,
  EntityTypeSupportingLeadGen,
  Group,
  Lead,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import PersistentLeadFinderSettings from "@mapmycustomers/shared/types/persistent/PersistentLeadFinderSettings";

import RecordRouteModel from "../../types/RecordRouteModel";

import { LeadFinderLimit, LeadFinderProgressState, LeadFinderQueryState } from "./LeadFinderState";

export const startLeadFinderSearch = createAsyncAction(
  "map/leadFinder/startSearch/request",
  "map/leadFinder/startSearch/success",
  "map/leadFinder/startSearch/failure"
)<
  {
    callback?: (bounds?: google.maps.LatLngBounds) => void;
    onLimitThresholdReached?: (limit: LeadFinderLimit) => void;
    refresh?: boolean;
    search: string;
  },
  {
    records: Array<Lead>;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  void
>();
export const cancelLeadFinderSearch = createAction("map/leadFinder/cancelSearch")<void>();

export const setAddRecordsVisibility = createAction("map/leadFinder/setAddRecordsVisibility")<{
  visible: boolean;
}>();
export const initializeAddRecords = createAsyncAction(
  "map/leadFinder/initializeAddRecords/request",
  "map/leadFinder/initializeAddRecords/success",
  "map/leadFinder/initializeAddRecords/failure"
)<
  {
    entityType: EntityTypeSupportingLeadGen;
  },
  {
    routes: Route[];
  },
  void
>();

export const filterRoutes = createAsyncAction(
  "map/leadFinder/filterRoutes/request",
  "map/leadFinder/filterRoutes/success",
  "map/leadFinder/filterRoutes/failure"
)<
  {
    entityType: EntityTypesSupportingRoutes;
    query?: string;
  },
  Route[],
  void
>();

export const submitAddRecords = createAsyncAction(
  "map/leadFinder/submitAddRecords/request",
  "map/leadFinder/submitAddRecords/success",
  "map/leadFinder/submitAddRecords/failure"
)<
  {
    customFields?: CustomFieldValue[];
    entity: Partial<Company | Person>;
    entityType: EntityTypeSupportingLeadGen;
    groupIds?: Group["id"][];
    layoutId: FormLayout["id"];
    personId?: Person["id"];
    routeIdsOrRoutePayloads?: RecordRouteModel[];
  },
  void,
  void
>();
export const addRecordsToRoute = createAsyncAction(
  "map/leadFinder/addRecordsToRoute/request",
  "map/leadFinder/addRecordsToRoute/success",
  "map/leadFinder/addRecordsToRoute/failure"
)<
  {
    entityIds: EntitiesSupportingRoutes["id"][];
    entityType: EntityTypeSupportingLeadGen;
    routeId: Route["id"];
  },
  {
    isNewRoute?: boolean;
    routeId: Route["id"];
  },
  {
    errors: Array<string>;
    routeId: Route["id"];
  }
>();

export const hideLeads = createAsyncAction(
  "map/leadFinder/blacklist/request",
  "map/leadFinder/blacklist/success",
  "map/leadFinder/blacklist/failure"
)<
  {
    placeIds: Lead["place_id"][];
  },
  {
    placeIds: Lead["place_id"][];
  },
  void
>();

export const showHiddenLeads = createAsyncAction(
  "map/leadFinder/showHiddenLeads/request",
  "map/leadFinder/showHiddenLeads/success",
  "map/leadFinder/showHiddenLeads/failure"
)<
  {
    placeIds: Lead["place_id"][];
  },
  {
    placeIds: Lead["place_id"][];
  },
  void
>();

export const finishAddRecords = createAction("map/leadFinder/finishAddRecords")<void>();
export const updateAddRecordsProgress = createAction(
  "map/leadFinder/updateAddRecordsProgress"
)<LeadFinderProgressState>();

export const showLeadFinder = createAction("map/leadFinder/show")<void>();
export const hideLeadFinder = createAction("map/leadFinder/hide")<void>();
export const toggleLeadFinder = createAction("map/leadFinder/toggle")<void>();
export const setConfigurationVisibility = createAction(
  "map/leadFinder/setConfigurationVisibility"
)<{
  visible: boolean;
}>();

export const setOtherPins = createAction("map/leadFinder/setOtherPins")<{
  otherPins: boolean;
}>();

export const showDuplicates = createAction("map/leadFinder/showDuplicates")<{
  primaryRecord?: Lead;
  records?: DuplicateEntity[];
}>();
export const hideDuplicates = createAction("map/leadFinder/hideDuplicates")<void>();
export const setPrimaryRecord = createAction("map/leadFinder/setPrimaryRecord")<{
  primaryRecord: Lead;
}>();
export const setLeadFinderQuery = createAction("map/leadFinder/setLeadFinderQuery")<
  Partial<LeadFinderQueryState>
>();

export const geocodeLocation = createAsyncAction(
  "map/leadFinder/geocodeLocation/request",
  "map/leadFinder/geocodeLocation/success",
  "map/leadFinder/geocodeLocation/failure"
)<
  {
    point: LongLat;
  },
  {
    address?: Address;
    location?: string;
  },
  void
>();
export const clearLeadFinderLocation = createAction(
  "map/leadFinder/clearLeadFinderLocation"
)<void>();
export const clearLeadFinderRecords = createAction("map/leadFinder/clearLeadFinderRecords")<void>();

export const initializeLeadFinderMode = createAsyncAction(
  "map/leadFinder/initialize/request",
  "map/leadFinder/initialize/success",
  "map/leadFinder/initialize/failure"
)<
  {
    callback?: (bounds?: google.maps.LatLngBounds) => void;
  },
  void,
  void
>();
export const enterLeadFinderMode = createAction("map/leadFinder/enter")<void>();
export const exitLeadFinderMode = createAction("map/leadFinder/exit")<void>();

export const saveSearch = createAction("map/leadFinder/saveSearch")<{
  search: string;
}>();
export const removeSearchHistoryItem = createAction("map/leadFinder/removeSearchHistoryItem")<{
  search: string;
}>();
export const setSearchHistory = createAction("map/leadFinder/setSearchHistory")<
  Partial<PersistentLeadFinderSettings>
>();
export const showSavedSearchesModal = createAction("map/leadFinder/showSavedSearchesModal")<void>();
export const hideSavedSearchesModal = createAction("map/leadFinder/hideSavedSearchesModal")<void>();

export const toggleHiddenLeadsVisibility = createAction(
  "map/leadFinder/toggleHiddenLeadsVisibility"
)<void>();
export const toggleDuplicateLeadsVisibility = createAction(
  "map/leadFinder/toggleDuplicateLeadsVisibility"
)<void>();

export const setCenterPoint = createAction("map/leadFinder/setCenterPoint")<LongLat | undefined>();
export const setCurrentBounds = createAction("map/leadFinder/setCurrentBounds")<{
  bounds?: google.maps.LatLngBounds;
}>();

export const setCustomSearchDistances = createAction("map/leadFinder/setCustomSearchDistances")<{
  customDistances: Array<number>;
}>();
export const setSearchDistance = createAction("map/leadFinder/setSearchDistance")<{
  distance: number;
}>();
export const setDistanceSettings = createAction("map/leadFinder/setDistanceSettings")<
  Partial<Pick<PersistentLeadFinderSettings, "customDistances" | "searchDistance">>
>();

export const setLeadAnnotation = createAction("map/leadFinder/setLeadAnnotation")<
  Lead["place_id"] | undefined
>();

export type LeadFinderActions = ActionType<
  | typeof addRecordsToRoute
  | typeof cancelLeadFinderSearch
  | typeof clearLeadFinderLocation
  | typeof clearLeadFinderRecords
  | typeof enterLeadFinderMode
  | typeof exitLeadFinderMode
  | typeof filterRoutes
  | typeof finishAddRecords
  | typeof geocodeLocation
  | typeof hideDuplicates
  | typeof hideLeadFinder
  | typeof hideLeads
  | typeof hideSavedSearchesModal
  | typeof initializeAddRecords
  | typeof initializeLeadFinderMode
  | typeof removeSearchHistoryItem
  | typeof saveSearch
  | typeof setAddRecordsVisibility
  | typeof setCenterPoint
  | typeof setConfigurationVisibility
  | typeof setCurrentBounds
  | typeof setCustomSearchDistances
  | typeof setDistanceSettings
  | typeof setLeadAnnotation
  | typeof setLeadFinderQuery
  | typeof setOtherPins
  | typeof setPrimaryRecord
  | typeof setSearchDistance
  | typeof setSearchHistory
  | typeof showDuplicates
  | typeof showHiddenLeads
  | typeof showLeadFinder
  | typeof showSavedSearchesModal
  | typeof startLeadFinderSearch
  | typeof submitAddRecords
  | typeof toggleDuplicateLeadsVisibility
  | typeof toggleHiddenLeadsVisibility
  | typeof toggleLeadFinder
  | typeof updateAddRecordsProgress
>;
