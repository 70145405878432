import localSettings from "@app/config/LocalSettings";

import { FEET_IN_MILE, METERS_IN_MILE } from "../consts";

export enum DistanceUnit {
  METER = "m",
  KILOMETER = "km",
  MILE = "miles",
  FOOT = "ft",
}

const parseDistance = (distanceInMeters: number): { distance: number; unit: DistanceUnit } => {
  const doesUseSiUnits = localSettings.doesUseSiUnits();

  let distance = distanceInMeters;
  let unit = DistanceUnit.METER;

  if (doesUseSiUnits) {
    if (distance >= 1000) {
      distance = distance / 1000;
      unit = DistanceUnit.KILOMETER;
    }
  } else {
    const miles = distance / METERS_IN_MILE;
    const lessThan1000Feet = miles <= 1000 / FEET_IN_MILE;
    distance = lessThan1000Feet ? miles * FEET_IN_MILE : miles;
    unit = lessThan1000Feet ? DistanceUnit.FOOT : DistanceUnit.MILE;
  }

  return { distance, unit };
};

export default parseDistance;
