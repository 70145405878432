import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import { isEqual } from "lodash-es";
import { useIntl } from "react-intl";

import { EntityType, Person } from "@mapmycustomers/shared/types/entity";
import Company from "@mapmycustomers/shared/types/entity/Company";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import { Modal } from "@mapmycustomers/ui";

import noRecordsImageMap from "@app/component/associations/utils/noRecordsImages";
import CreatePersonModal from "@app/component/createEditEntity/CreatePersonModal";
import { isCurrentUserReadOnly } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./Association.module.scss";
import Associations from "./components/Associations";
import {
  filterPeople,
  getFilteredPeopleCount,
  getPeople,
  getTotalPeople,
  isLoading,
} from "./store";
import useEntitySorter from "./utils/useEntitySorter";

interface OwnProps {
  allowAdd?: boolean;
  assignedPeople?: Person[];
  associateWith?: Person["id"];
  defaultQuery?: string;
  fixedCompany?: Company;
  getListTitle?: (count: number, total: number) => string;
  hideOnSelect?: boolean;
  info?: React.ReactNode;
  multiselect?: boolean;
  okLoading?: boolean;
  okText?: string;
  onHide: (updated?: boolean) => void;
  onSelect?: (selectedPeopleIds: Person["id"][], removedPeopleIds?: Person["id"][]) => void;
  requestFilters?: PlatformFilterCondition[];
  suggestedPeople?: Person[];
  title?: string;
}

interface Props extends OwnProps {
  filtered?: number;
  isReadOnlyMember: boolean;
  loading?: boolean;
  onSearch: (payload: { query: string; requestFilters?: PlatformFilterCondition[] }) => void;
  people?: Person[];
  total: number;
  zIndex?: number;
}

const PeopleAssociation: React.FC<Props> = ({
  allowAdd = true,
  assignedPeople,
  associateWith,
  defaultQuery = "",
  filtered,
  fixedCompany,
  getListTitle,
  hideOnSelect = true,
  info,
  isReadOnlyMember,
  loading,
  multiselect = true,
  okLoading,
  okText,
  onHide,
  onSearch,
  onSelect,
  people,
  requestFilters,
  suggestedPeople,
  title,
  total,
  zIndex,
}) => {
  const intl = useIntl();

  // Person object has companies property which is used to filter all the already associated people based on fixedCompany
  const assignedList = useMemo(() => {
    return assignedPeople
      ? assignedPeople
      : people && fixedCompany && multiselect
      ? people.filter((person) => person.accounts.some(({ id }) => id === fixedCompany.id))
      : [];
  }, [assignedPeople, fixedCompany, multiselect, people]);

  const [query, setQuery] = useState<string>(defaultQuery);
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<Person["id"][]>(
    assignedList.map(({ id }) => id)
  );

  useEffect(() => {
    setSelectedPeopleIds(assignedList.map(({ id }) => id));
  }, [assignedList]);

  const removedPeopleIds = useMemo(
    () =>
      assignedList?.filter(({ id }) => !selectedPeopleIds.includes(id)).map(({ id }) => id) ?? [],
    [assignedList, selectedPeopleIds]
  );

  const [createdPeople, setCreatedPeople] = useState<Person[]>([]);
  const sortedPeople = useEntitySorter(
    people ?? [],
    createdPeople,
    query.trim().length > 0,
    assignedList,
    suggestedPeople
  );

  const handleOkClick = useCallback(() => {
    onSelect?.(selectedPeopleIds, removedPeopleIds);
    if (hideOnSelect) {
      onHide(true);
    }
  }, [hideOnSelect, onHide, onSelect, removedPeopleIds, selectedPeopleIds]);

  useEffect(() => {
    onSearch({ query: defaultQuery, requestFilters });
  }, [defaultQuery, onSearch, requestFilters]);

  const handleSearch = useCallback(
    (query: string) => onSearch({ query, requestFilters }),
    [onSearch, requestFilters]
  );

  const [createNewPersonVisible, showCreatePersonModal, hideCreatePersonModal] = useBoolean();
  const handleHideCreatePersonModal = useDynamicCallback((newPerson?: Person) => {
    const alreadyAssignedPeopleIds = new Set(
      [...assignedList, ...createdPeople].map(({ id }) => id)
    );
    const hadAnySelectedPeopleAlready = selectedPeopleIds.some(
      (id) => !alreadyAssignedPeopleIds.has(id)
    );
    if (newPerson) {
      setSelectedPeopleIds((ids) => [...ids, newPerson.id]);
      // we also store created people, just to show then in the top of the list, to not confuse user
      // because otherwise, newly added record could get filtered out according to current filter
      // that's also the reason why we reset the filter here
      setCreatedPeople((people) => [...people, newPerson]);
      setQuery("");
      onSearch({ query: "", requestFilters });
    }
    hideCreatePersonModal();

    // if user selected no people in the modal before adding new person, then just hide both modals
    if (!hadAnySelectedPeopleAlready && newPerson) {
      onSelect?.([newPerson.id], removedPeopleIds);
      if (hideOnSelect) {
        onHide(true);
      }
    }
  });

  const peopleUpdated = useMemo(
    () =>
      multiselect
        ? !isEqual(selectedPeopleIds.sort(), assignedList.map(({ id }) => id).sort())
        : selectedPeopleIds.length > 0,
    [assignedList, multiselect, selectedPeopleIds]
  );

  const alreadyAssignedEntities = useMemo(
    () => [...assignedList, ...createdPeople],
    [assignedList, createdPeople]
  );

  return (
    <Modal
      className={styles.modal}
      okButtonProps={{ disabled: !peopleUpdated || okLoading, loading: okLoading }}
      okText={
        okText ??
        intl.formatMessage(
          {
            id: "associations.people.footer.okButton",
            defaultMessage:
              "{addPeople, select, true {Add} other {Update}} {multiselect, select, true {People} other {Person}}",
            description: "Add button title on People Associations modal",
          },
          { addPeople: assignedList.length === 0, multiselect }
        )
      }
      onCancel={() => onHide(false)}
      onOk={handleOkClick}
      title={
        title ??
        intl.formatMessage(
          {
            id: "associations.people.header",
            defaultMessage: "Select {multiselect, select, true {People} other {Person}}",
            description: "Header of People Associations modal",
          },
          { multiselect }
        )
      }
      visible
      width="clamp(500px, 50vw, 750px)"
      zIndex={zIndex}
    >
      {info}
      <Associations<Person>
        alreadyAssociatedMessage={intl.formatMessage({
          id: "associations.people.alreadyAssociated",
          defaultMessage:
            "This person is already associated with this record. To remove the association, click the “Remove” icon from the edit panel.",
          description:
            "A tooltip over the checkbox when person is already assigned to a target record",
        })}
        assignedRecords={alreadyAssignedEntities}
        associateWith={associateWith}
        cantAssociateWithSelfMessage={intl.formatMessage({
          id: "associations.people.cantAssociateWithSelf",
          defaultMessage: "Assigning person to itself is not allowed",
          description: "A tooltip over the checkbox when person is already a target company",
        })}
        entities={sortedPeople}
        extras={
          allowAdd && !isReadOnlyMember ? (
            <Button onClick={showCreatePersonModal} type="link">
              {intl.formatMessage({
                id: "associations.people.addNewPersonButton",
                defaultMessage: "+ Add New Person",
                description: "Add new person button on Person association modal",
              })}
            </Button>
          ) : undefined
        }
        filtered={filtered}
        getListTitle={getListTitle}
        loading={loading}
        multiselect={multiselect}
        noDataImageSrc={noRecordsImageMap[EntityType.PERSON]}
        onChange={setSelectedPeopleIds}
        onChangeQuery={setQuery}
        onSearch={handleSearch}
        query={query}
        suggestedEntities={suggestedPeople}
        textFieldProps={{
          placeholder: intl.formatMessage({
            id: "associations.people.search.placeholder",
            defaultMessage: "Search for a person",
            description: "Search bar placeholder for person association modal",
          }),
        }}
        total={total}
        value={selectedPeopleIds}
      />
      {createNewPersonVisible && (
        <CreatePersonModal
          fixedCompany={fixedCompany}
          okText={intl.formatMessage({
            id: "associations.people.addNewPersonModal.okButton",
            defaultMessage: "Create and Add Person",
            description:
              "Create button title on Create Person modal when called from Select People modal",
          })}
          onHide={handleHideCreatePersonModal}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  filtered: getFilteredPeopleCount(state),
  isReadOnlyMember: isCurrentUserReadOnly(state),
  loading: isLoading(state),
  people: getPeople(state),
  total: getTotalPeople(state),
});

const mapDispatchToProps = {
  onSearch: filterPeople.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleAssociation);
