import { createSelector } from "reselect";

import { getHiddenTerritoriesIds } from "@app/store/iam";
import { VISIBLE_TERRITORIES_LIMIT } from "@app/util/consts";

import { RootState } from "../rootReducer";

const territoriesState = (state: RootState) => state.territories;

export const areTerritoriesLoading = createSelector(territoriesState, (state) => state.loading);

export const haveAnyTerritories = createSelector(
  territoriesState,
  (state) => state.territories.length > 0
);

export const getTerritories = createSelector(
  territoriesState,
  getHiddenTerritoriesIds,
  ({ territories }, hiddenIds) => {
    let visibleCount = 0;
    return territories.map((territory) => {
      const hidden = hiddenIds.includes(territory.id);
      visibleCount += hidden ? 0 : 1;
      return {
        ...territory,
        territoryDetail: {
          ...territory.territoryDetail,
          hidden: hidden || visibleCount > VISIBLE_TERRITORIES_LIMIT,
        },
      };
    });
  }
);

export const getTerritoryCount = createSelector(
  territoriesState,
  ({ territories }) => territories.length
);

export const isLoading = createSelector(territoriesState, ({ loading }) => loading);
export const isCreating = createSelector(territoriesState, ({ creating }) => creating);
export const isDeleting = createSelector(territoriesState, ({ deleting }) => deleting);
export const getTotal = createSelector(territoriesState, ({ total }) => total);
export const getTerritoriesHavingShapeFetched = createSelector(
  territoriesState,
  ({ shapeLoading }) => shapeLoading
);
