import { ColDef } from "@ag-grid-community/core";
import memoize from "fast-memoize";
import { createSelector } from "reselect";

import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { RootState } from "@app/store/rootReducer";

import ForecastViewState from "../component/ForecastPage/types/ForecastViewState";
import ViewMode from "../enum/ViewMode";
import BoardViewState from "../type/BoardViewState";

import BaseView from "./types/BaseView";

const dealState = (state: RootState) => state.scene.deal;

// Common view selectors
export const getCurrentView = createSelector(dealState, (dealState) =>
  memoize((viewMode: ViewMode): BaseView => {
    switch (viewMode) {
      case ViewMode.FORECAST:
        return dealState.forecastView;
      case ViewMode.KANBAN:
        return dealState.kanbanView;
      case ViewMode.LIST:
        return dealState.listView;
    }
  })
);

// List View related selectors
export const getListView = createSelector(dealState, ({ listView }) => listView);
export const getListViewState = createSelector(
  getListView,
  ({ viewState }): ViewState => viewState
);
export const isListViewLoading = createSelector(getListView, ({ loading }) => loading);
export const getListViewTotalFilteredRecordsCount = createSelector(
  getListView,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getListViewTotalRecordsCount = createSelector(
  getListView,
  ({ totalRecords }) => totalRecords
);
export const getListViewFunnelSummary = createSelector(
  getListView,
  ({ funnelSummary }) => funnelSummary
);
export const getListViewFunnelStagesSummary = createSelector(
  getListView,
  ({ funnelStagesSummary }) => funnelStagesSummary.data
);
export const getListViewFunnelStagesSummaryLoading = createSelector(
  getListView,
  ({ funnelStagesSummary }) => funnelStagesSummary.loading
);
export const getFunnelChartHidden = createSelector(
  getListView,
  ({ funnelChartHidden }) => funnelChartHidden
);
export const getFunnelChartFunnelId = createSelector(
  getListView,
  ({ funnelChartFunnelId }) => funnelChartFunnelId
);
export const getGridColumnDefs = createSelector(getListViewState, ({ columns }) =>
  memoize((forClientSideGrid?: boolean) => {
    // get field's gridProperties && also add width/pinned/hide props from the column model
    return columns
      .filter(({ field }) => field.isReadable || field.isSystemRequired)
      .map<ColDef>(({ field, pinned, visible, width }) => ({
        ...(field.gridProperties(forClientSideGrid) ?? {}),
        hide: !visible,
        pinned,
        width,
      }));
  })
);

// Kanban View related selectors
export const getKanbanView = createSelector(dealState, ({ kanbanView }) => kanbanView);
export const getKanbanViewState = createSelector(
  getKanbanView,
  ({ viewState }): BoardViewState => viewState
);
export const getKanbanDeals = createSelector(getKanbanView, ({ groupedDeals }) => groupedDeals);
export const isKanbanViewLoading = createSelector(getKanbanView, ({ loading }) => loading);
export const getKanbanViewTotalFilteredRecordsCount = createSelector(
  getKanbanView,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getKanbanViewTotalRecordsCount = createSelector(
  getKanbanView,
  ({ totalRecords }) => totalRecords
);

export const getKanbanHiddenColumnIds = createSelector(
  getKanbanViewState,
  ({ hiddenColumnIds }) => hiddenColumnIds
);

export const getKanbanViewFunnelSummary = createSelector(
  getKanbanView,
  ({ funnelSummary }) => funnelSummary
);

// Forecast View related selectors
export const getForecastView = createSelector(dealState, ({ forecastView }) => forecastView);
export const getForecastColumnCount = createSelector(
  getForecastView,
  ({ columnCount }) => columnCount
);
export const getForecastViewState = createSelector(
  getForecastView,
  ({ viewState }): ForecastViewState => viewState
);
export const getForecastViewMode = createSelector(getForecastViewState, ({ viewMode }) => viewMode);
export const getForecastHiddenColumnIds = createSelector(
  getForecastViewState,
  ({ hiddenColumnIds }) => hiddenColumnIds
);
export const getForecastDeals = createSelector(getForecastView, ({ groupedDeals }) => groupedDeals);
export const isForecastViewLoading = createSelector(getForecastView, ({ loading }) => loading);
export const getForecastViewTotalFilteredRecordsCount = createSelector(
  getForecastView,
  ({ totalFilteredRecords }) => totalFilteredRecords
);
export const getForecastViewTotalRecordsCount = createSelector(
  getForecastView,
  ({ totalRecords }) => totalRecords
);
export const getForecastViewFunnelSummary = createSelector(
  getForecastView,
  ({ funnelSummary }) => funnelSummary
);

// Other selectors

export const getViewMode = createSelector(dealState, ({ viewMode }) => viewMode);

export const getUpdateViewStateCallback = createSelector(
  dealState,
  ({ onViewStateChanged }) => onViewStateChanged
);

export const getOfferedFilters = createSelector(dealState, ({ offerFilters }) => offerFilters);
