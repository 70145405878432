import convert from "color-convert";
import { LAB } from "color-convert/conversions";

import AnyColor from "@mapmycustomers/shared/types/AnyColor";
import { isDefined } from "@mapmycustomers/shared/util/assert";

import anyColorToHex from "./anyColorToHex";

type ReducerResult = [smallestDistance: number, bestMatchingColor: AnyColor];

// The simplest yet suitable for us color difference algorithm CIE76:
// https://en.wikipedia.org/wiki/Color_difference#CIE76
const getDeltaE = ([l1, a1, b1]: LAB, [l2, a2, b2]: LAB) =>
  Math.sqrt((l2 - l1) ** 2 + (a2 - a1) ** 2 + (b2 - b1) ** 2);

const getClosestColor = <T>(
  colors: AnyColor[],
  color: AnyColor,
  defaultColor: T,
  // if colors are more distant that the threshold, the default color will be returned
  threshold?: number
): AnyColor | T => {
  // happy path, when color is included in colors array
  if (colors.some((testee) => anyColorToHex(testee) === anyColorToHex(color))) {
    return color;
  }

  const targetColor = convert.hex.lab(anyColorToHex(color));
  const [smallestDistance, bestMatchingColor] = colors.reduce<ReducerResult>(
    (result, color) => {
      const distance = getDeltaE(targetColor, convert.hex.lab(anyColorToHex(color)));
      return distance < result[0] ? [distance, color] : result;
    },
    [Number.MAX_VALUE, defaultColor] as ReducerResult
  );

  if (isDefined(threshold) && smallestDistance > threshold) {
    return defaultColor;
  }

  return bestMatchingColor;
};

export default getClosestColor;
