import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import FieldType from "@mapmycustomers/shared/enum/fieldModel/FieldType";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { Company, EntityType } from "@mapmycustomers/shared/types/entity";

import AddressField from "@app/util/fieldModel/impl/AddressField";
import { moneyFormatter } from "@app/util/fieldModel/impl/fieldUtil";
import TerritoryField from "@app/util/fieldModel/impl/TerritoryField";

import getEntityFilter from "../filters/EntityFilter";

import ColorField from "./impl/ColorField";
import CountryField from "./impl/CountryField";
import DateField from "./impl/DateField";
import DateTimeField from "./impl/DateTimeField";
import FieldModel from "./impl/FieldModel";
import { createField } from "./impl/fieldModelUtil";
import FrequencyDaysLeft from "./impl/FrequencyDaysLeft";
import FrequencyIntervalField from "./impl/FrequencyIntervalField";
import FrequencyStatusField from "./impl/FrequencyStatusField";
import GroupsField from "./impl/GroupsField";
import MapLocationField from "./impl/MapLocationField";
import NameField from "./impl/NameField";
import NestedAddressField from "./impl/NestedAddressField";
import NoCheckInField from "./impl/NoCheckInField";
import NoContactField from "./impl/NoContactField";
import NotesField from "./impl/NotesField";
import NoVerifiedCheckInField from "./impl/NoVerifiedCheckInField";
import RegionField from "./impl/RegionField";
import RoutesField from "./impl/RoutesField";
import SourceField from "./impl/SourceField";
import TeamField from "./impl/TeamField";
import UniqueIdField from "./impl/UniqueIdField";
import UserField from "./impl/UserField";
import ViewAsField from "./impl/ViewAsField";

export enum CompanyFieldName {
  NAME = "name",
  ADDRESS = "address",
  CITY = "city",
  REGION = "region",
  POSTAL_CODE = "postalCode",
  COUNTRY = "countryCode",
  PHONE = "phone",
  WEBSITE = "website",
  CRM_LINK = "crmLink",
  EMAIL = "email",
  FILES = "files",
  GROUPS = "groups",
  FREQUENCY_INTERVAL = "cadenceInterval",
  COLOR = "color",
  ANNUAL_REVENUE = "annualRevenue",
  NUM_EMPLOYEES = "numEmployees",
  CREATOR = "creator",
  CREATOR_NAME = "creatorName",
  CREATOR_EMAIL = "creator.username",
  USER = "user",
  OWNER_NAME = "ownerName",
  OWNER_EMAIL = "user.username",
  NEXT_ACTIVITY = "upcomingActivity.startAt",
  LAST_ACTIVITY_DATE = "lastCompletedActivity.completedAt",
  LAST_ACTIVITY_TYPE = "lastCompletedActivity.activityType",
  LAST_ACTIVITY_NAME = "lastCompletedActivity.name",
  FREQUENCY_STATUS = "cadenceDaysOut",
  DAYS_LEFT_IN_FREQUENCY = "daysLeftInFrequency",
  NO_CONTACT_IN = "noContactDaysOut",
  NOTES = "notes",
  UNIQUE_ID = "importId",
  GEOPOINT = "geoPoint",
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
  SOURCE = "sourceCreated",
  PARENT_ACCOUNT = "parentAccount",
  PARENT_ACCOUNT_ADDRESS = "parentAccount.address",
  PERSON = "contactId", // normally only used for company creation and never after
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
  TEAM = "team",
  TERRITORIES = "territories",
  VIEW_AS = "viewAs",
  ROUTES = "routes",
  PARENT_ACCOUNT_IMPORT_ID = "parentAccount.importId",
  NO_CHECK_IN = "noCheckIn",
  NO_VERIFIED_CHECK_IN = "noVerifiedCheckIn",
}

const displayNames = defineMessages<CompanyFieldName>({
  [CompanyFieldName.ADDRESS]: {
    id: "company.field.address",
    defaultMessage: "Street",
    description: "Street field of company",
  },
  [CompanyFieldName.ANNUAL_REVENUE]: {
    id: "company.field.annualRevenue",
    defaultMessage: "Annual Revenue",
    description: "Annual Revenue field of company",
  },
  [CompanyFieldName.CITY]: {
    id: "company.field.city",
    defaultMessage: "City",
    description: "City field of company",
  },
  [CompanyFieldName.COLOR]: {
    id: "company.field.color",
    defaultMessage: "Color",
    description: "Color field of company",
  },
  [CompanyFieldName.COUNTRY]: {
    id: "company.field.country",
    defaultMessage: "Country",
    description: "Country field of company",
  },
  [CompanyFieldName.CREATED_AT]: {
    id: "company.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created At field of company",
  },
  [CompanyFieldName.CREATOR]: {
    id: "company.field.creator",
    defaultMessage: "Creator",
    description: "Creator field of company",
  },
  [CompanyFieldName.CREATOR_EMAIL]: {
    id: "company.field.creatorEmail",
    defaultMessage: "Creator Email",
    description: "Creator Email field of company",
  },
  [CompanyFieldName.CREATOR_NAME]: {
    id: "company.field.creatorName",
    defaultMessage: "Creator Name",
    description: "Creator Name field of company",
  },
  [CompanyFieldName.CRM_LINK]: {
    id: "company.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM Link field of company",
  },
  [CompanyFieldName.DAYS_LEFT_IN_FREQUENCY]: {
    id: "company.field.daysLeftInFrequency",
    defaultMessage: "Days Left In Frequency",
    description: " Days Left In Frequency field of company",
  },
  [CompanyFieldName.EMAIL]: {
    id: "company.field.email",
    defaultMessage: "Email",
    description: "Email field of company",
  },
  [CompanyFieldName.FILES]: {
    id: "company.field.files",
    defaultMessage: "Files",
    description: "Files field of company",
  },
  [CompanyFieldName.FREQUENCY_INTERVAL]: {
    id: "company.field.frequencyInterval",
    defaultMessage: "Frequency Interval",
    description: "Frequency Interval field of company",
  },
  [CompanyFieldName.FREQUENCY_STATUS]: {
    id: "company.field.frequencyStatus",
    defaultMessage: "Frequency Status",
    description: "Frequency Status field of company",
  },
  [CompanyFieldName.GEOPOINT]: {
    id: "company.field.geopoint",
    defaultMessage: "Location",
    description: "Location field of company",
  },
  [CompanyFieldName.GROUPS]: {
    id: "company.field.groups",
    defaultMessage: "Group",
    description: "Groups field of company",
  },
  [CompanyFieldName.LAST_ACTIVITY_DATE]: {
    id: "company.field.lastActivity.completedAt",
    defaultMessage: "Date of Last Done Activity",
    description: "Date of Last Done Activity field of company",
  },
  [CompanyFieldName.LAST_ACTIVITY_NAME]: {
    id: "company.field.lastActivity.name",
    defaultMessage: "Name of Last Done Activity",
    description: "Name of Last Done Activity field of company",
  },
  [CompanyFieldName.LAST_ACTIVITY_TYPE]: {
    id: "company.field.lastActivity.activityType",
    defaultMessage: "Type of Last Done Activity",
    description: "Type of Last Done Activity field of company",
  },
  [CompanyFieldName.LATITUDE]: {
    id: "company.field.latitude",
    defaultMessage: "Latitude",
    description: "Latitude field of company",
  },
  [CompanyFieldName.LONGITUDE]: {
    id: "company.field.longitude",
    defaultMessage: "Longitude",
    description: "Longitude field of company",
  },
  [CompanyFieldName.NAME]: {
    id: "company.field.name",
    defaultMessage: "Name",
    description: "Name field of company",
  },
  [CompanyFieldName.NEXT_ACTIVITY]: {
    id: "company.field.nextActivity",
    defaultMessage: "Date of Next Activity",
    description: "Next Activity field of company",
  },
  [CompanyFieldName.NO_CHECK_IN]: {
    id: "company.field.noCheckIn",
    defaultMessage: "No Check-In for",
    description: "No CheckIns field of company",
  },
  [CompanyFieldName.NO_CONTACT_IN]: {
    id: "company.field.noContactIn",
    defaultMessage: "No Contact In",
    description: "No Contact In field of company",
  },
  [CompanyFieldName.NO_VERIFIED_CHECK_IN]: {
    id: "company.field.noVerifiedCheckIn",
    defaultMessage: "No Verified Check-In for",
    description: "No Verified CheckIns field of company",
  },
  [CompanyFieldName.NOTES]: {
    id: "company.field.notes",
    defaultMessage: "Notes",
    description: "Notes field of company",
  },
  [CompanyFieldName.NUM_EMPLOYEES]: {
    id: "company.field.numEmployees",
    defaultMessage: "Number of Employees",
    description: "Number of Employees field of company",
  },
  [CompanyFieldName.OWNER_EMAIL]: {
    id: "company.field.ownerEmail",
    defaultMessage: "Owner Email",
    description: "Owner Email field of company",
  },
  [CompanyFieldName.OWNER_NAME]: {
    id: "company.field.ownerName",
    defaultMessage: "Owner Name",
    description: "Owner Name field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT]: {
    id: "company.field.parentCompany",
    defaultMessage: "Parent Company",
    description: "Parent Company field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT_ADDRESS]: {
    id: "company.field.parentCompanyAddress",
    defaultMessage: "Parent Company Address",
    description: "Parent Company Address field of company",
  },
  [CompanyFieldName.PARENT_ACCOUNT_IMPORT_ID]: {
    id: "company.field.parentAccount.importId",
    defaultMessage: "Parent Company ID",
    description: "Parent Company Id field of company",
  },
  [CompanyFieldName.PERSON]: {
    id: "company.field.person",
    defaultMessage: "Person",
    description: "Child person of company",
  },
  [CompanyFieldName.PHONE]: {
    id: "company.field.phone",
    defaultMessage: "Phone",
    description: "Phone field of company",
  },
  [CompanyFieldName.POSTAL_CODE]: {
    id: "company.field.postalCode",
    defaultMessage: "Postal Code",
    description: "Postal Code field of company",
  },
  [CompanyFieldName.REGION]: {
    id: "company.field.region",
    defaultMessage: "State/Region",
    description: "State/Region field of company",
  },
  [CompanyFieldName.ROUTES]: {
    id: "company.field.routes",
    defaultMessage: "Routes",
    description: "Routes field of company",
  },
  [CompanyFieldName.SOURCE]: {
    id: "company.field.source",
    defaultMessage: "Source",
    description: "Source field of company",
  },
  [CompanyFieldName.TEAM]: {
    id: "company.field.team",
    defaultMessage: "Team",
    description: "Team field of company",
  },
  [CompanyFieldName.TERRITORIES]: {
    id: "company.field.territories",
    defaultMessage: "Territories",
    description: "Territories field of company",
  },
  [CompanyFieldName.UNIQUE_ID]: {
    id: "company.field.uniqueId",
    defaultMessage: "Unique ID",
    description: "Unique Id field of company",
  },
  [CompanyFieldName.UPDATED_AT]: {
    id: "company.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field of company",
  },
  [CompanyFieldName.USER]: {
    id: "company.field.user",
    defaultMessage: "Owner",
    description: "Owner field of company",
  },
  [CompanyFieldName.VIEW_AS]: {
    id: "company.field.viewAs",
    defaultMessage: "Visible to",
    description: "View As field of company",
  },
  [CompanyFieldName.WEBSITE]: {
    id: "company.field.website",
    defaultMessage: "Website",
    description: "Website field of company",
  },
});

const additionalDisplayNames = defineMessages({
  address: {
    id: "company.field.street.fieldManagementName",
    defaultMessage: "Address",
    description: "Field Management name for the street field of company",
  },
  parentCompanyId: {
    id: "company.field.parentAccount.importName",
    defaultMessage: "Parent Company - ID",
    description: "Import name for the parentAccount field of company",
  },
});

const companyFieldModel = new FieldModel(
  [
    new NameField({
      displayName: displayNames[CompanyFieldName.NAME],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.ALWAYS_VISIBLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.RECORD_PREVIEW_VISIBLE_BY_DEFAULT,
        FieldFeature.MAP_PINNED_FIELD,
        FieldFeature.WITH_CHECKBOX_SELECTION,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.REQUIRED,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      formProperties: { fullWidth: false },
      importProperties: { required: true },
      name: CompanyFieldName.NAME,
    }),
    new AddressField({
      displayName: displayNames[CompanyFieldName.ADDRESS],
      displayOrder: 1,
      fieldManagementDisplayName: additionalDisplayNames.address,
      name: CompanyFieldName.ADDRESS,
    }),
    {
      displayName: displayNames[CompanyFieldName.ADDRESS],
      features: [
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.BULK_EDIT,
        FieldFeature.BULK_ADDRESS,
      ],
      name: "address_bulk",
      platformFilterName: CompanyFieldName.ADDRESS,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[CompanyFieldName.CITY],
      displayOrder: 2,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.CITY,
      type: FieldType.STRING,
    },
    new RegionField({
      displayName: displayNames[CompanyFieldName.REGION],
      displayOrder: 3,
      name: CompanyFieldName.REGION,
    }),
    {
      displayName: displayNames[CompanyFieldName.POSTAL_CODE],
      displayOrder: 5,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.ADDRESS,
        FieldFeature.BULK_EDIT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.POSTAL_CODE,
      type: FieldType.STRING,
    },
    new CountryField({
      displayName: displayNames[CompanyFieldName.COUNTRY],
      displayOrder: 4,
      name: CompanyFieldName.COUNTRY,
      platformFilterName: "countryCode",
    }),
    {
      customGridProperties: {
        cellRenderer: "phoneCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.PHONE],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.PHONE,
      type: FieldType.STRING,
    },
    {
      customGridProperties: {
        cellRenderer: "websiteCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.WEBSITE],
      features: [
        FieldFeature.EXTERNAL_URL_FIELD,
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.BULK_EDIT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.WEBSITE,
      type: FieldType.STRING,
    },
    {
      customGridProperties: {
        cellRenderer: "crmLinkCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.CRM_LINK],
      features: [
        FieldFeature.EXTERNAL_URL_FIELD,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NON_IMPORT,
        FieldFeature.SORTABLE,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.DETAILS,
      ],
      name: CompanyFieldName.CRM_LINK,
      type: FieldType.STRING,
    },
    {
      customGridProperties: {
        cellRenderer: "emailCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.EMAIL],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.VISIBLE_BY_DEFAULT,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.EMAIL,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[CompanyFieldName.FILES],
      features: [
        FieldFeature.FILE_FIELD,
        // not visible anywhere, except in forms
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
      ],
      formProperties: {
        fullWidth: true,
      },
      name: CompanyFieldName.FILES,
      type: FieldType.LIST,
    },
    new GroupsField({
      displayName: displayNames[CompanyFieldName.GROUPS],
      name: CompanyFieldName.GROUPS,
    }),
    new FrequencyIntervalField({
      displayName: displayNames[CompanyFieldName.FREQUENCY_INTERVAL],
      features: [
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.ENGAGEMENT,
        FieldFeature.BULK_EDIT,
        FieldFeature.FREQUENCY_INTERVAL,
        FieldFeature.FILTERABLE,
        FieldFeature.NUMERIC,
        FieldFeature.FILTERABLE_ON_MAP,
      ],
      name: CompanyFieldName.FREQUENCY_INTERVAL,
    }),
    new FrequencyStatusField({
      displayName: displayNames[CompanyFieldName.FREQUENCY_STATUS],
      displayOrder: 13,
      name: CompanyFieldName.FREQUENCY_STATUS,
    }),
    new ColorField({
      displayName: displayNames[CompanyFieldName.COLOR],
      name: CompanyFieldName.COLOR,
    }),
    {
      displayName: displayNames[CompanyFieldName.ANNUAL_REVENUE],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NUMERIC,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.MONETARY_VALUE,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      name: CompanyFieldName.ANNUAL_REVENUE,
      type: FieldType.NUMBER,
      valueFormatter: moneyFormatter,
    },
    {
      displayName: displayNames[CompanyFieldName.NUM_EMPLOYEES],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.NUMERIC,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.EMAIL_DYNAMIC_VAR,
      ],
      formProperties: {
        numericOptions: { min: 1 },
      },
      name: CompanyFieldName.NUM_EMPLOYEES,
      type: FieldType.NUMBER,
    },
    new UserField({
      displayName: displayNames[CompanyFieldName.CREATOR],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_IMPORT,
        FieldFeature.DATA_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.CREATOR,
      platformFilterName: "createdBy",
      platformName: "createdBy",
    }),
    new UserField({
      displayName: displayNames[CompanyFieldName.CREATOR_NAME],
      exportColumnName: "creator",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "creator",
      name: CompanyFieldName.CREATOR_NAME,
      platformFilterName: "createdBy",
      valueGetter: (entity: unknown) => (entity as Company).creator,
    }),
    {
      displayName: displayNames[CompanyFieldName.CREATOR_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.CREATOR_EMAIL,
      type: FieldType.STRING,
    },
    new UserField({
      displayName: displayNames[CompanyFieldName.USER],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.BULK_EDIT,
        FieldFeature.DATA_VIEW,
        FieldFeature.HISTORY_FIELD,
      ],
      importProperties: {
        required: false,
      },
      name: CompanyFieldName.USER,
      platformFilterName: "userId",
      platformName: "userId",
    }),
    new UserField({
      displayName: displayNames[CompanyFieldName.OWNER_NAME],
      exportColumnName: "user",
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.OWNER_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      filterName: "user",
      name: CompanyFieldName.OWNER_NAME,
      platformFilterName: "userId",
      valueGetter: (entity: unknown) => (entity as Company).user,
    }),
    {
      displayName: displayNames[CompanyFieldName.OWNER_EMAIL],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.OWNER_EMAIL,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT_IMPORT_ID],
      features: [
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: "parentAccount.importId",
      type: FieldType.STRING,
      valueGetter: ["parentAccount", "importId"],
    },
    new DateField({
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.NEXT_ACTIVITY],
      displayOrder: 11,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.NEXT_ACTIVITY,
    }),
    new DateField({
      customGridProperties: {
        cellRenderer: "activityAnnotationLinkCellRenderer",
      },
      displayName: displayNames[CompanyFieldName.LAST_ACTIVITY_DATE],
      displayOrder: 12,
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.ENGAGEMENT,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.LAST_ACTIVITY_DATE,
    }),
    {
      displayName: displayNames[CompanyFieldName.LAST_ACTIVITY_NAME],
      features: [
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: CompanyFieldName.LAST_ACTIVITY_NAME,
      type: FieldType.STRING,
    },
    {
      displayName: displayNames[CompanyFieldName.LAST_ACTIVITY_TYPE],
      features: [
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_MAP_VIEW,
      ],
      name: CompanyFieldName.LAST_ACTIVITY_TYPE,
      type: FieldType.STRING,
    },
    new NoContactField({
      displayName: displayNames[CompanyFieldName.NO_CONTACT_IN],
      displayOrder: 14,
      name: CompanyFieldName.NO_CONTACT_IN,
    }),
    new NotesField({
      displayName: displayNames[CompanyFieldName.NOTES],
      name: CompanyFieldName.NOTES,
    }),
    new UniqueIdField({
      displayName: displayNames[CompanyFieldName.UNIQUE_ID],
      name: CompanyFieldName.UNIQUE_ID,
    }),
    new MapLocationField({
      displayName: displayNames[CompanyFieldName.GEOPOINT],
      displayOrder: 6,
      name: CompanyFieldName.GEOPOINT,
    }),
    {
      displayName: displayNames[CompanyFieldName.LATITUDE],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.LATITUDE,
      type: FieldType.NUMBER,
    },
    {
      displayName: displayNames[CompanyFieldName.LONGITUDE],
      features: [
        FieldFeature.NON_LIST_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
      ],
      name: CompanyFieldName.LONGITUDE,
      type: FieldType.NUMBER,
    },
    new SourceField({
      displayName: displayNames[CompanyFieldName.SOURCE],
      name: CompanyFieldName.SOURCE,
    }),
    {
      customFilterConfig: {
        defaultInstance: "entity",
        filterInstances: {
          entity: getEntityFilter(EntityType.COMPANY, [
            FilterOperator.IN_ANY,
            FilterOperator.NONE_OF,
          ]),
        },
        operators: [FilterOperator.IN_ANY, FilterOperator.NONE_OF],
      },
      displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT],
      features: [
        FieldFeature.FILTERABLE,
        FieldFeature.FILTERABLE_ON_MAP,
        FieldFeature.SORTABLE,
        FieldFeature.SUPPORTS_SET_FILTER,
        FieldFeature.HISTORY_FIELD,
        FieldFeature.RELATIONSHIPS,
      ],
      filterType: FieldType.ID,
      importProperties: {
        bottomPosition: true,
        displayName: additionalDisplayNames.parentCompanyId,
        required: false,
      },
      name: CompanyFieldName.PARENT_ACCOUNT,
      platformFilterName: "parentAccount.id",
      platformName: "parentAccountId",
      type: FieldType.STRING,
      valueGetter: ["parentAccount", "name"],
    },
    {
      displayName: displayNames[CompanyFieldName.PERSON],
      features: [
        FieldFeature.RELATIONSHIPS,
        FieldFeature.NON_MAP_VIEW,
        FieldFeature.NON_EXPORT_VIEW,
        FieldFeature.NON_INTEGRATION,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_LIST_VIEW,
      ],
      filterType: FieldType.ID,
      name: CompanyFieldName.PERSON,
      type: FieldType.ID,
    },
    new NestedAddressField(
      {
        displayName: displayNames[CompanyFieldName.PARENT_ACCOUNT_ADDRESS],
        name: CompanyFieldName.PARENT_ACCOUNT_ADDRESS,
      },
      ["parentAccount"]
    ),
    new DateTimeField({
      displayName: displayNames[CompanyFieldName.CREATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: CompanyFieldName.CREATED_AT,
    }),
    new DateTimeField({
      displayName: displayNames[CompanyFieldName.UPDATED_AT],
      features: [
        FieldFeature.SORTABLE,
        FieldFeature.FILTERABLE,
        FieldFeature.NON_ADDABLE_FORM_FIELD,
        FieldFeature.NON_IMPORT,
        FieldFeature.NON_INTEGRATION,
      ],
      name: CompanyFieldName.UPDATED_AT,
    }),
    new TeamField({
      displayName: displayNames[CompanyFieldName.TEAM],
      name: CompanyFieldName.TEAM,
      platformFilterName: "teamId",
    }),
    new TerritoryField({
      displayName: displayNames[CompanyFieldName.TERRITORIES],
      name: CompanyFieldName.TERRITORIES,
    }),
    // filter-only fields:
    new ViewAsField({
      displayName: displayNames[CompanyFieldName.VIEW_AS],
      name: CompanyFieldName.VIEW_AS,
    }),
    new RoutesField({
      displayName: displayNames[CompanyFieldName.ROUTES],
      name: CompanyFieldName.ROUTES,
    }),
    new FrequencyDaysLeft({
      displayName: displayNames[CompanyFieldName.DAYS_LEFT_IN_FREQUENCY],
      name: CompanyFieldName.DAYS_LEFT_IN_FREQUENCY,
    }),
    new NoCheckInField({
      displayName: displayNames[CompanyFieldName.NO_CHECK_IN],
      name: CompanyFieldName.NO_CHECK_IN,
    }),
    new NoVerifiedCheckInField({
      displayName: displayNames[CompanyFieldName.NO_VERIFIED_CHECK_IN],
      name: CompanyFieldName.NO_VERIFIED_CHECK_IN,
    }),
  ].map(createField)
);

export default companyFieldModel;
