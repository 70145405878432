import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import { Company, EntityType } from "@mapmycustomers/shared/types/entity";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import User from "@mapmycustomers/shared/types/User";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import { FormItem, LoadingSpinner, NumberField, TextField } from "@mapmycustomers/ui";

import ConditionalFormField, {
  ConditionalFormFieldRenderProps,
} from "@app/component/ConditionalFormField";
import DuplicatesTable from "@app/component/DuplicatesTable";
import FormFields from "@app/component/FormFields";
import PersonRelationshipField from "@app/component/FormFields/components/Associations/PersonRelationshipField";
import FormLayoutSelector from "@app/component/FormLayoutSelector";
import FormNoLayoutAlert from "@app/component/FormNoLayoutAlert";
import BaseModal from "@app/component/modal/BaseModal";
import { showGlobalPostCreationModal } from "@app/component/PostCreationModal/store/actions";
import PreviewModeIndicator from "@app/component/PreviewModeIndicator";
import { CompanyPayload } from "@app/store/api/ApiService";
import { fetchCompany } from "@app/store/company/actions";
import { getCurrentUserId } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import FileListItem from "@app/types/FileListItem";
import { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import useFormChangeTracker from "@app/util/hook/form/useFormChangeTracker";
import useCustomFieldValidation from "@app/util/hook/useCustomFieldValidation";
import useErroredFieldsScroller from "@app/util/hook/useErroredFieldsScroller";
import { companyLayoutModel } from "@app/util/layout/impl";

import GeocodeLimitField from "../components/GeocodeLimitField";
import { getAddress } from "../util/getAddress";

import File from "./components/File";
import { getDuplicates, getUploadedFilesList, isCreating, isInitializing } from "./store";
import { clearAllUploadedCompanyFiles, createCompany, initialize } from "./store/actions";
import { isGlobalAddCompany } from "./store/selectors";
import FormValues from "./types/FormValues";
import getInitialValues from "./util/getInitialValues";

interface OwnProps {
  className?: string;
  fixedParentCompanyId?: Company["id"];
  initialValues?: Partial<Company>;
  leadGen?: boolean;
  okText?: ReactNode;
  onHide: (newCompany?: Company) => void;
  previewLayout?: FormLayout;
  showPostCreationModal?: boolean;
}

interface Props extends OwnProps {
  clearAllUploadedFiles: () => void;
  createCompany: typeof createCompany.request;
  creating: boolean;
  currentUserId: User["id"];
  duplicates: DuplicateEntity[];
  initialize: () => void;
  initializing: boolean;
  onFetchCompany: typeof fetchCompany;
  onShowGlobalPostCreationModal: typeof showGlobalPostCreationModal;
  uploadedFiles: FileListItem[];
}

const CreateCompanyModal: React.FC<Props> = ({
  className,
  clearAllUploadedFiles,
  createCompany,
  creating,
  currentUserId,
  duplicates,
  fixedParentCompanyId,
  initialize,
  initializing,
  initialValues,
  leadGen,
  okText,
  onFetchCompany,
  onHide,
  onShowGlobalPostCreationModal,
  previewLayout,
  showPostCreationModal,
  uploadedFiles,
}) => {
  const intl = useIntl();

  const [formLayout, setFormLayout] = useState(companyLayoutModel.defaultFormLayout);

  const [form] = useForm<FormValues>();
  const [isFormChanged, handleFormChanges] = useFormChangeTracker(form);
  const [formContainer, handleFinishFail] = useErroredFieldsScroller();

  useEffect(() => {
    initialize();
  }, [form, initialize]);

  useEffect(() => {
    if (fixedParentCompanyId) {
      onFetchCompany({
        id: fixedParentCompanyId,
        callback: (company) => {
          form.setFieldsValue({ parentCompanyId: company.id, parentCompanyName: company.name });
        },
      });
    }
  }, [onFetchCompany, form, fixedParentCompanyId]);

  const handleCancelClick = useCallback(() => {
    clearAllUploadedFiles();
    onHide();
  }, [clearAllUploadedFiles, onHide]);
  const handleOkClick = useCallback(() => form.submit(), [form]);

  const handleVisibility = useCallback(
    (company: Company) => {
      if (showPostCreationModal) {
        onShowGlobalPostCreationModal({ entity: company, entityType: EntityType.COMPANY });
      }
      onHide(company);
    },
    [showPostCreationModal, onHide, onShowGlobalPostCreationModal]
  );

  const handleCustomFieldValidate = useCustomFieldValidation(form, handleFinishFail, true);

  const handleCreate = useCallback(
    (checkDuplicates = true) => {
      const layoutId = formLayout?.id;

      if (layoutId) {
        const values = form.getFieldsValue();
        const address = getAddress(values, initialValues?.geoManagementState);
        const company: CompanyPayload = {
          ...address,
          annualRevenue: values.annualRevenue,
          color: values.color,
          email: values.email,
          geoAddress: initialValues?.geoAddress ?? address.geoAddress,
          geoPoint: initialValues?.geoPoint ?? address.geoPoint,
          name: values.name,
          numEmployees: values.numEmployees,
          parentAccount: values.parentCompanyId ? { id: values.parentCompanyId } : undefined,
          phone: values.phone,
          user: values.user ? { id: values.user } : undefined,
          website: values.website,
        };

        createCompany({
          callback: handleVisibility,
          checkDuplicates,
          company,
          customFieldsValues: values.customFields
            ? Object.values(values.customFields).filter(isDefined)
            : [],
          customFieldsValueValidateCallback: handleCustomFieldValidate,
          groupsIdsToAdd: values.groups ? Array.from(values.groups) : [],
          layoutId,
          leadGen,
          personId: values.personId,
        });
      }
    },
    [
      createCompany,
      form,
      formLayout?.id,
      handleCustomFieldValidate,
      handleVisibility,
      initialValues?.geoAddress,
      initialValues?.geoManagementState,
      initialValues?.geoPoint,
      leadGen,
    ]
  );

  const isPreviewMode = !!previewLayout;
  const currentLayout = isPreviewMode ? previewLayout : formLayout;
  const submitButtonDisabled = creating || initializing || isPreviewMode || !currentLayout;
  const showConfirm =
    !isPreviewMode && !initializing && (isFormChanged || uploadedFiles.length > 0);

  const hasDuplicates = duplicates.length > 0;

  return (
    <BaseModal
      borderedHeader
      className={className}
      entityTypeName="Company"
      footer={
        hasDuplicates ? null : (
          <>
            <Tooltip
              placement="topRight"
              title={
                isPreviewMode
                  ? intl.formatMessage({
                      id: "createCompanyModal.footer.previewMode.tooltip",
                      defaultMessage: "Please exit Preview Mode to create company",
                      description: "Create company modal - OK button tooltip in preview mode",
                    })
                  : null
              }
              trigger={isPreviewMode ? ["hover"] : []}
            >
              <Button
                disabled={submitButtonDisabled}
                loading={creating}
                onClick={handleOkClick}
                type="primary"
              >
                {okText ??
                  intl.formatMessage({
                    id: "createCompanyModal.footer.ok",
                    defaultMessage: "Create Company",
                    description: "Create button title on Create Company modal",
                  })}
              </Button>
            </Tooltip>
          </>
        )
      }
      onCancel={handleCancelClick}
      onOk={handleOkClick}
      showConfirm={showConfirm}
      title={
        <>
          {intl.formatMessage({
            id: "createCompanyModal.title",
            defaultMessage: "Create Company",
            description: "Title of Create Company modal",
          })}

          {isPreviewMode ? (
            <PreviewModeIndicator />
          ) : (
            <FormLayoutSelector
              disabled={creating || initializing}
              entityType={EntityType.COMPANY}
              layout={formLayout}
              onChange={setFormLayout}
            />
          )}
        </>
      }
    >
      {initializing ? (
        <LoadingSpinner />
      ) : currentLayout ? (
        <>
          {hasDuplicates ? (
            <DuplicatesTable
              duplicates={duplicates}
              entityType={EntityType.COMPANY}
              onCreate={() => handleCreate(false)}
            />
          ) : null}

          <div hidden={!!duplicates.length} ref={formContainer}>
            <GeocodeLimitField />
            <Form
              form={form}
              initialValues={getInitialValues(currentUserId, initialValues)}
              layout="vertical"
              onFinish={handleCreate}
              onFinishFailed={handleFinishFail}
              onValuesChange={handleFormChanges}
              preserve
            >
              <FormFields
                entityType={EntityType.COMPANY}
                fileComponent={File}
                isCreateForm
                layout={currentLayout}
              >
                <ConditionalFormField fieldName={CompanyFieldName.PHONE}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <FormItem
                      label={label}
                      name={CompanyFieldName.PHONE}
                      required={required}
                      rules={[{ required }]}
                    >
                      <TextField
                        addonBefore={<FontAwesomeIcon icon={faPhone} />}
                        disabled={disabled}
                        label={label}
                        placeholder={label?.toString()}
                        required={required}
                      />
                    </FormItem>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={CompanyFieldName.NUM_EMPLOYEES}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <FormItem
                      label={label}
                      name={CompanyFieldName.NUM_EMPLOYEES}
                      required={required}
                      rules={[{ required }]}
                    >
                      <NumberField
                        disabled={disabled}
                        label={label}
                        min={1}
                        placeholder={typeof label === "string" ? label : undefined}
                        required={required}
                      />
                    </FormItem>
                  )}
                </ConditionalFormField>

                <ConditionalFormField fieldName={CompanyFieldName.PERSON}>
                  {({ disabled, label, required }: ConditionalFormFieldRenderProps) => (
                    <PersonRelationshipField
                      allowAdd={!disabled}
                      disabled={disabled}
                      label={label}
                      required={required}
                    />
                  )}
                </ConditionalFormField>
              </FormFields>
            </Form>
          </div>
        </>
      ) : (
        <FormNoLayoutAlert />
      )}
    </BaseModal>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  creating: isCreating(state),
  currentUserId: getCurrentUserId(state)!,
  duplicates: getDuplicates(state),
  initializing: isInitializing(state),
  showPostCreationModal: ownProps.showPostCreationModal ?? isGlobalAddCompany(state),
  uploadedFiles: getUploadedFilesList(state),
});

const mapDispatchToProps = {
  clearAllUploadedFiles: clearAllUploadedCompanyFiles,
  createCompany: createCompany.request,
  initialize: initialize.request,
  onFetchCompany: fetchCompany,
  onShowGlobalPostCreationModal: showGlobalPostCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompanyModal);
