import React, { useMemo } from "react";

import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import type IField from "@mapmycustomers/shared/types/fieldModel/IField";
import type {
  IFilterComponentProps,
  IFilterInstance,
} from "@mapmycustomers/shared/types/fieldModel/IFilterConfig";
import type User from "@mapmycustomers/shared/types/User";
import type { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";

import UserMultiSelectField from "@app/component/input/UserMultiSelectField";
import { userDisplayName } from "@app/util/formatters";

import getHumanReadableDescriptionForOptionsFilter, {
  OptionFilterOperator,
} from "./getHumanReadableDescriptionForOptionsFilter";

export const USERS_FILTER_OPERATORS = [FilterOperator.IN_ANY, FilterOperator.NONE_OF];

const doesSupportValue = (value: any, operator: FilterOperator) =>
  Array.isArray(value) &&
  value.every((item) => typeof item === "number") &&
  USERS_FILTER_OPERATORS.includes(operator);

interface UsersFilterProps extends IFilterComponentProps {}

const UsersFilter: IFilterInstance = {
  doesSupportOption: (option: FilterOption) => option === FilterOption.AVAILABLE_USERS,
  doesSupportValue,
  getComponent:
    (): React.FC<UsersFilterProps> =>
    ({ className, onChange, options, value }) => {
      const visibleUsers = useMemo(() => {
        const usersUsedInFilters = new Set(Array.isArray(value.value) ? value.value : []);
        return ((options?.availableUsers as User[]) ?? []).filter(
          (user) => user.viaTeamAccess || !user.deletedAt || usersUsedInFilters.has(user.id)
        );
      }, [options?.availableUsers, value.value]);

      return (
        <UserMultiSelectField
          className={className}
          onChange={(userIds: User["id"][]) => onChange?.({ ...value, value: userIds })}
          size="middle"
          users={visibleUsers}
          value={Array.isArray(value.value) ? value.value : []}
        />
      );
    },
  getHumanReadableDescription: (value: SimpleCondition, field: IField, options) => {
    if (!doesSupportValue(value.value, value.operator)) {
      return undefined;
    }

    const usersMap = new Map(
      ((options?.availableUsers ?? []) as User[]).map((user) => [user.id, userDisplayName(user)])
    );
    const selectedValues = value.value as User["id"][];
    return getHumanReadableDescriptionForOptionsFilter(
      field,
      value.operator as OptionFilterOperator,
      selectedValues.map((userId) => usersMap.get(userId) ?? String(userId))
    );
  },
};

export default UsersFilter;
