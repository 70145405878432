import notification from "antd/es/notification";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";
import { defineMessages } from "react-intl";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import invariant from "tiny-invariant";
import * as uuid from "uuid";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import CustomFieldValuesUpsertResponse from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import {
  Activity,
  Company,
  Deal,
  EntityType,
  Person,
  Route,
} from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { RawFile } from "@mapmycustomers/shared/types/File";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { PinLegendsFilter } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import { RELATIONSHIP_DATA_LOAD_LIMIT } from "@app/component/preview/util/consts";
import getFileRemovedNotificationNode from "@app/component/preview/util/getFileRemovedNotificationNode";
import getStartRange from "@app/component/preview/util/getStartRange";
import getYourDownloadWillStartShortlyNode from "@app/component/preview/util/getYourDownloadWillStartShortlyNode";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import { recapRangeIntervalMap } from "@app/enum/preview/RecapRange";
import { applyMapViewSettings, fetchPins } from "@app/scene/map/store/mapMode/actions";
import { getMapViewState } from "@app/scene/map/store/mapMode/selectors";
import { getActivityTypes } from "@app/store/activity";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getCurrentUser, getOrganization, getOrganizationId } from "@app/store/iam";
import { updateMetadata } from "@app/store/iam/actions";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { NOTIFICATION_DURATION_WITH_ACTION } from "@app/util/consts";
import { getLocalTimeZoneFormattedOffset } from "@app/util/dates";
import { allSettled, SettleResult } from "@app/util/effects";
import personFieldModel, { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import { downloadFileByUrl } from "@app/util/file";
import { activityLayoutModel } from "@app/util/layout/impl";
import getColorShapeForEntity from "@app/util/map/markerStyles/getColorShapeForEntity";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";

import {
  addPersonCompanies,
  addPersonDeals,
  changeActivitiesRecapRange,
  changeActivitiesSelectedActivityTypes,
  createPersonNote,
  deletePerson,
  deletePersonFile,
  deletePersonNote,
  downloadPersonFile,
  fetchMoreCompanies,
  fetchMoreDeals,
  fetchPerson,
  fetchPersonActivities,
  fetchPersonActivitiesCompletedByType,
  fetchPersonActivitiesTotal,
  fetchPersonRoutes,
  fetchPreviewData,
  fetchThumbnail,
  postponeActivity,
  removePersonCompany,
  removePersonDeal,
  removePersonFromRoute,
  setPersonColor,
  setPersonShape,
  toggleCompleteActivity,
  updateActivityNote,
  updatePerson,
  updatePersonFrequency,
  updatePersonNote,
  updatePersonPrimaryCompany,
  uploadPersonFiles,
} from "./actions";
import PersonRecordData from "./PersonRecordData";
import { getPerson, getRecordData } from "./selectors";

const messages = defineMessages({
  addCompanySuccess: {
    id: "personRecordPane.addCompany.success",
    defaultMessage: "Person successfully related to {count, select, 1 {Company} other {Companies}}",
    description: "Person successfully related to Companies message",
  },
  addDealSuccess: {
    id: "personRecordPane.addDeal.success",
    defaultMessage: "Person successfully related to {count, select, 1 {Deal} other {Deals}}",
    description: "Person successfully related to Deal",
  },
  deleteSuccess: {
    id: "personRecordPane.delete.success.title",
    defaultMessage: "Person successfully deleted",
    description: "Person deleted success message",
  },
  deleteSuccessDescription: {
    id: "personRecordPane.delete.success.description",
    defaultMessage: "To restore this record, go to Data in Settings.",
    description: "Person deleted success description",
  },
  postponeSuccess: {
    id: "personRecordPane.activities.postpone.success",
    defaultMessage: "Activity postponed successfully",
    description: "Activity postpone success message",
  },
  toggleCompleteSuccess: {
    id: "personRecordPane.activities.toggleComplete.success",
    defaultMessage: 'Activity{completed, select, true {} other { is no longer}} marked as "Done"',
    description: "Activity toggle complete success message",
  },
  updateError: {
    id: "personRecordPane.update.error",
    defaultMessage: "Error updating person. Please try again or contact support",
    description: "Person update error message",
  },
  updateSuccess: {
    id: "personRecordPane.update.success",
    defaultMessage: "Person updated successfully",
    description: "Person updated success message",
  },
});

function* getFetchPersonPayload(personId: Person["id"]) {
  const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
  const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);
  const mapViewState: MapViewState = yield select(getMapViewState);

  const pinLegendFilter: PinLegendsFilter = {
    $offset: getLocalTimeZoneFormattedOffset(),
    [EntityType.PERSON]: getColorShapeForEntity(
      mapViewState,
      colorPinLegends,
      shapePinLegends,
      EntityType.PERSON
    ),
  };

  return {
    $filters: {
      cadence: true,
      entities: {
        [EntityType.PERSON]: {
          $and: [{ id: personId }],
          includeGroups: true,
          includeTerritories: true,
        },
      },
      includeAccessStatus: true,
      includeAssociations: true,
      includeCustomFields: true,
      includeUsersWithAccess: true,
      pinLegends: pinLegendFilter,
    },
    $limit: 1,
  };
}

export function* onFetchPreviewData({
  payload: personId,
}: ReturnType<typeof fetchPreviewData.request>) {
  try {
    const organization: Organization = yield select(getOrganization);

    const response: ListResponse<Person> = yield callApi(
      "fetchPins",
      organization.id,
      yield call(getFetchPersonPayload, personId)
    );

    const person: Person | undefined = response.data[0];

    if (!person) {
      yield put(fetchPreviewData.failure());
      return;
    }

    const filesField: IField | undefined = yield call(
      [personFieldModel, personFieldModel.getByName],
      PersonFieldName.FILES
    );

    const notesField: IField | undefined = yield call(
      [personFieldModel, personFieldModel.getByName],
      PersonFieldName.NOTES
    );

    const [
      notes,
      files,
      dealsResponse,
      activitiesResponse,
      uncompletedActivitiesResponse,
      routesResponse,
      companiesResponse,
    ]: [
      ListResponse<Note>,
      ListResponse<RawFile>,
      ListResponse<Deal>,
      ListResponse<Activity>,
      ListResponse<Activity>,
      ListResponse<Route>,
      ListResponse<Company> | undefined
    ] = yield all([
      notesField?.isReadable
        ? callApi("fetchNotes", EntityType.PERSON, person.id, {
            $limit: 1000,
            $order: "-updatedAt",
          })
        : { data: [] },
      filesField?.isReadable
        ? callApi("fetchEntityFiles", organization.id, EntityType.PERSON, person.id, {
            $limit: 1000,
            $order: "-createdAt",
          })
        : { data: [] },
      callApi("fetchDeals", organization.id, {
        $filters: {
          $and: [
            {
              contactId: person.id,
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
        $order: "name",
      }),
      callApi("fetchActivities", organization.id, {
        $columns: ["id", "startAt"],
        $filters: {
          $and: [
            {
              contactId: person.id,
            },
          ],
          includeContactSharedActivities: true,
        },
        $limit: 0,
      }),
      callApi("fetchActivities", organization.id, {
        $columns: ["id", "startAt"],
        $filters: {
          $and: [
            {
              completed: false,
              contactId: person.id,
            },
          ],
          includeAccessStatus: true,
          includeContactSharedActivities: true,
        },
        $limit: 1000,
        $order: "startAt",
      }),
      callApi("fetchPeopleRoutes", organization.id, {
        $filters: {
          $and: [
            {
              contactId: person.id,
            },
          ],
          includeAccessStatus: true,
        },
        $limit: 1000,
        $order: "name",
      }),
      person.accounts && person.accounts.length > 0
        ? callApi("fetchCompanies", organization.id, {
            $filters: {
              $and: [
                {
                  id: {
                    $in: person.accounts
                      ?.map((account) => account.id)
                      .slice(0, RELATIONSHIP_DATA_LOAD_LIMIT),
                  },
                },
              ],
              includeAccessStatus: true,
              includeCustomFields: true,
              includeGroups: true,
              includeTerritories: true,
            },
            $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
            $order: "name",
          })
        : undefined,
    ]);

    const user: User = yield select(getCurrentUser);

    // get stored selected activity types ids
    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const selectedActivityTypesIds = user?.metaData?.recapChartTypesIds;

    // find corresponding activity types
    const selectedActivityTypes: ActivityType[] = selectedActivityTypesIds
      ? activityTypes.filter(({ id }) => selectedActivityTypesIds.includes(id))
      : activityTypes;

    yield put(
      fetchPreviewData.success({
        recordData: {
          activities: [],
          activitiesCompletedByType: [],
          activitiesTotal: activitiesResponse.total,
          companies: companiesResponse?.data ?? [],
          deals: dealsResponse.data,
          files: files.data,
          notes: notes.data,
          person,
          routes: routesResponse.data,
          totalCompanies: person.accounts.length,
          totalDeals: dealsResponse.total,
          uncompletedActivities: uncompletedActivitiesResponse.data,
        },
        selectedActivityTypes,
      })
    );
  } catch (error) {
    yield put(fetchPreviewData.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPersonActivities({
  payload: { activityTypes, filter, order, person, recapRange, search, selectedAssignees },
}: ReturnType<typeof fetchPersonActivities.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const recapInterval = recapRangeIntervalMap[recapRange];
    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          $and: [
            {
              completed:
                filter === ActivityStatusOption.COMPLETED
                  ? true
                  : filter === ActivityStatusOption.OVERDUE
                  ? false
                  : undefined,
              contactId: person.id,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              name: search ? { $in: search.trim() } : undefined,
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
          ],
          includeAccessStatus: true,
          includeContactSharedActivities: true,
          includeCustomFields: true,
          includeEmails: true,
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchPersonActivities.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchPersonActivities.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPersonActivitiesCompletedByType({
  payload: { activityTypes, filter, order, person, recapRange, selectedAssignees },
}: ReturnType<typeof fetchPersonActivitiesCompletedByType.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const recapInterval = recapRangeIntervalMap[recapRange];
    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          $and: [
            {
              contactId: person.id,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
          ],
          includeAccessStatus: true,
          includeContactSharedActivities: true,
          includeCustomFields: true,
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchPersonActivitiesCompletedByType.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchPersonActivitiesCompletedByType.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPersonActivitiesTotal({
  payload,
}: ReturnType<typeof fetchPersonActivitiesTotal.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $columns: ["id"],
        $filters: {
          $and: [
            {
              contactId: payload.id,
            },
          ],
          includeContactSharedActivities: true,
        },
        $limit: 0,
      }
    );
    yield put(fetchPersonActivitiesTotal.success(activitiesResponse.total));
  } catch (error) {
    yield put(fetchPersonActivitiesTotal.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPerson({ payload: person }: ReturnType<typeof fetchPerson.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const response: Person = yield callApi("fetchPerson", organization.id, person.id, {
      cadence: true,
      includeAccessStatus: true,
      includeGroups: true,
      includeTerritories: true,
      includeUsersWithAccess: true,
    });
    yield put(fetchPerson.success(response));
  } catch (error) {
    yield put(fetchPerson.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPersonRoutes({ payload }: ReturnType<typeof fetchPersonRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const routesResponse: ListResponse<Route> = yield callApi(
      "fetchPeopleRoutes",
      organization.id,
      {
        $filters: { $and: [{ contactId: payload }], includeAccessStatus: true },
        $limit: 1000,
        $order: "name",
      }
    );

    yield put(fetchPersonRoutes.success(routesResponse.data));
    yield put(
      notifyAboutChanges({ entityType: EntityType.PEOPLE_ROUTE, updated: routesResponse.data })
    );
  } catch (error) {
    yield put(fetchPersonRoutes.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchThumbnail({ payload }: ReturnType<typeof fetchThumbnail>) {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      payload.fileId,
      false,
      true,
      EntityType.PERSON,
      person.id,
      { responseType: "blob" }
    );
    yield call(payload.callback, fileData);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onAddPersonCompany({
  payload: { id, companyIds, primaryCompanyId },
}: ReturnType<typeof addPersonCompanies.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const [person, companiesResponse]: [Person, ListResponse<Company>] = yield all([
      callApi("updatePerson", org.id, undefined, {
        id,
        accounts: companyIds.map((id) => ({ id })),
        primaryAccount: primaryCompanyId ? { id: primaryCompanyId } : null,
      }),
      callApi("fetchCompanies", org.id, {
        $filters: {
          $and: [
            {
              id: { $in: companyIds },
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
        $order: "name",
      }),
    ]);

    notification.success({
      message: i18nService.formatMessage(
        messages.addCompanySuccess,
        "Person successfully related to Companies",
        { count: companyIds.length }
      ),
    });

    yield put(
      addPersonCompanies.success({
        companies: companiesResponse.data,
        total: person.accounts.length,
      })
    );
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));
  } catch (error) {
    yield put(addPersonCompanies.failure());
    yield put(handleError({ error }));
  }
}

function* onRemovePersonCompany({
  payload: { id, companyId, layoutId },
}: ReturnType<typeof removePersonCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const recordData: PersonRecordData = yield select(getRecordData);

    // Need to fetch person as all companies might not be loaded for record
    const response: Person = yield callApi("fetchPerson", org.id, id);
    const person: Person = yield callApi("updatePerson", org.id, layoutId, {
      id,
      accounts: response.accounts.filter(({ id }) => id !== companyId).map(({ id }) => ({ id })),
    });

    yield put(
      removePersonCompany.success(recordData.companies?.filter(({ id }) => id !== companyId) ?? [])
    );
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));
  } catch (error) {
    yield put(removePersonCompany.failure());
    yield put(handleError({ error }));
  }
}

function* onAddPersonDeals({
  payload: { id, dealIds, removedDealsIds },
}: ReturnType<typeof addPersonDeals.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    // Remove Deals Association
    yield all(
      (removedDealsIds ?? []).map((dealId) =>
        callApi("updateDeal", org.id, undefined, {
          id: dealId,
          contact: null,
        })
      )
    );

    yield all(
      (dealIds ?? []).map((dId: Deal["id"]) =>
        callApi("updateDeal", org.id, undefined, {
          id: dId,
          contact: { id },
        })
      )
    );

    notification.success({
      message: i18nService.formatMessage(
        messages.addDealSuccess,
        "Person successfully related to Deal",
        { count: dealIds?.length }
      ),
    });

    const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", org.id, {
      $filters: { $and: [{ contactId: id }], includeAccessStatus: true },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $order: "name",
    });

    yield put(addPersonDeals.success({ deals: dealsResponse.data, total: dealsResponse.total }));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: dealsResponse.data }));
  } catch (error) {
    yield put(addPersonDeals.failure());
    yield put(handleError({ error }));
  }
}

function* onRemovePersonDeal({ payload: dealId }: ReturnType<typeof removePersonDeal.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi("updateDeal", org.id, undefined, {
      id: dealId,
      contact: null,
    });
    yield put(removePersonDeal.success(dealId));
  } catch (error) {
    yield put(removePersonDeal.failure(dealId));
    yield put(handleError({ error }));
  }
}

function* onRemovePersonFromRoute({
  payload: { id, routeId },
}: ReturnType<typeof removePersonFromRoute.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi("removePeopleFromRoute", org.id, routeId, [id]);
    yield put(removePersonFromRoute.success(routeId));
  } catch (error) {
    yield put(removePersonFromRoute.failure(routeId));
    yield put(handleError({ error }));
  }
}

export function* onUpdatePerson({
  payload: {
    callback,
    customFields,
    customFieldsValueValidateCallback,
    groupIdsToAdd,
    groupIdsToRemove,
    layoutId,
    person,
  },
}: ReturnType<typeof updatePerson.request>) {
  const intl = i18nService.getIntl();
  try {
    const org: Organization = yield select(getOrganization);
    const recordData: PersonRecordData = yield select(getRecordData);

    const readOnlyFields = new Set(
      personFieldModel.fields
        .filter((field) => !field.isEditable || field.hasFeature(FieldFeature.CALCULATED_FIELD))
        .map((field) => field.name)
    );

    const changedCustomFields = Object.values(customFields ?? {}).filter((customField) => {
      if (!customField) {
        return false;
      }
      if (readOnlyFields.has(customField.esKey)) {
        return false;
      }

      const oldCustomField = recordData.person?.customFields?.find(
        ({ customField: { id } }) => id === customField.customField.id
      );
      return !oldCustomField || !isEqual(oldCustomField.value, customField.value);
    });

    // Validate custom fields
    if (changedCustomFields.length && customFieldsValueValidateCallback) {
      invariant(!!layoutId, "Must use layoutId to validate custom fields");
      const customFieldValidateResponse: CustomFieldValuesUpsertResponse[] = yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.PERSON,
        person.id,
        changedCustomFields,
        true
      );

      const erroredCustomFields = customFieldValidateResponse[0].errorCF.filter(
        ({ error }) => error.length > 0
      );

      if (erroredCustomFields.length) {
        yield call(customFieldsValueValidateCallback, erroredCustomFields);
        yield put(updatePerson.failure());
        return;
      }
    }

    const strippedPerson = readOnlyFields.size
      ? // TODO: implement proper fields deletion and type casting
        (omit(person, Array.from(readOnlyFields)) as Identified)
      : person;
    yield callApi("updatePerson", org.id, layoutId, strippedPerson);

    if (changedCustomFields.length) {
      invariant(!!layoutId, "Must use layoutId to update custom fields");
      yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.PERSON,
        person.id,
        changedCustomFields
      );
    }

    const peopleIds: Person["id"][] = [person.id];
    yield all([
      ...(groupIdsToAdd ?? []).map((groupId) =>
        callApi("addToGroup", org.id, groupId, EntityType.PERSON, peopleIds)
      ),
      ...(groupIdsToRemove ?? []).map((groupId) =>
        callApi("deleteFromGroup", org.id, groupId, EntityType.PERSON, peopleIds)
      ),
    ]);

    const response: ListResponse<Person> = yield callApi(
      "fetchPins",
      org.id,
      yield call(getFetchPersonPayload, person.id)
    );
    const updatedPerson: Person = response.data[0];

    if (intl) {
      notification.success({
        message: getSuccessNotificationNode(intl, intl.formatMessage(messages.updateSuccess)),
      });
    }
    yield put(updatePerson.success(updatedPerson));
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [updatedPerson] }));
    callback?.(updatedPerson);
  } catch (error) {
    if (intl) {
      notification.success({
        message: getSuccessNotificationNode(intl, intl.formatMessage(messages.updateError)),
      });
    }
    yield put(updatePerson.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreatePersonNote({ payload }: ReturnType<typeof createPersonNote.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    const note: Note = yield callApi("createNote", org.id, EntityType.PERSON, person.id, payload);
    yield put(createPersonNote.success(note));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.PERSON,
        updated: [{ ...person, notes: [...(person.notes ?? []), note] }],
      })
    );
  } catch (error) {
    yield put(createPersonNote.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdatePersonNote({ payload }: ReturnType<typeof updatePersonNote.request>) {
  try {
    const person: Person = yield select(getPerson);
    const updatedNote: Note = yield callApi("updateNote", EntityType.PERSON, person.id, payload);
    yield put(updatePersonNote.success(updatedNote));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.PERSON,
        updated: [
          {
            ...person,
            notes: (person.notes ?? []).map((note) =>
              note.id === updatedNote.id ? updatedNote : note
            ),
          },
        ],
      })
    );
  } catch (error) {
    yield put(updatePersonNote.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUploadPersonFiles({ payload }: ReturnType<typeof uploadPersonFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    const fileGroupId = payload.fileGroupId ?? uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, false, EntityType.PERSON, person.id, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadPersonFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadPersonFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeletePersonFile({ payload }: ReturnType<typeof deletePersonFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);

    const notificationKey = `file_removal_${payload.id}`;
    const cancelled = new Promise<true>((resolve) => {
      notification.info({
        duration: NOTIFICATION_DURATION_WITH_ACTION,
        key: notificationKey,
        message: getFileRemovedNotificationNode(i18nService.getIntl(), payload, () => {
          resolve(true);
          notification.close(notificationKey);
        }),
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let markWindowClosed = (flag: true) => {};
    const windowClosed = new Promise<boolean>((resolve) => {
      markWindowClosed = resolve; // I need to "extract" it from here, so that I can call removeEventListener later
    });
    const documentListener = () => {
      if (document.visibilityState === "hidden") {
        markWindowClosed(true);
        notification.close(notificationKey);
      }
    };
    document.addEventListener("visibilitychange", documentListener);
    const windowListener = () => markWindowClosed(true);
    window.addEventListener("beforeunload", windowListener);

    const result: { cancelled?: Promise<true>; delete?: true; windowClosed?: Promise<true> } =
      yield race({
        cancelled,
        delete: delay(NOTIFICATION_DURATION_WITH_ACTION * 1000 /* since it is in seconds */),
        windowClosed,
      });

    window.removeEventListener("beforeunload", windowListener);
    document.removeEventListener("visibilitychange", documentListener);

    if (result.delete || result.windowClosed) {
      yield callApi("deleteEntityFile", org.id, EntityType.PERSON, person.id, payload.id);
      yield put(deletePersonFile.success({ file: payload, removed: true }));
    } else {
      yield put(deletePersonFile.success({ file: payload, removed: false }));
    }
  } catch (error) {
    yield put(deletePersonFile.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onDownloadPersonFile({ payload: file }: ReturnType<typeof downloadPersonFile>) {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);

    const key = `download_file_${file.id}`;
    const timeout = setTimeout(() => {
      notification.info({
        duration: 0, // don't close automatically, we'll close it ourselves
        key,
        message: getYourDownloadWillStartShortlyNode(i18nService.getIntl(), file.name),
      });
    }, 2000);

    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      EntityType.PERSON,
      person.id,
      { responseType: "blob", timeout: 0 }
    );
    clearTimeout(timeout);
    notification.close(key);

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDeletePersonNote({ payload }: ReturnType<typeof deletePersonNote.request>) {
  try {
    const person: Person = yield select(getPerson);
    yield callApi("deleteNote", EntityType.PERSON, person.id, payload);
    yield put(deletePersonNote.success(payload.id));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.PERSON,
        updated: [
          {
            ...person,
            notes: (person.notes ?? []).filter((note) => note.id !== payload.id),
          },
        ],
      })
    );
  } catch (error) {
    yield put(deletePersonNote.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onPostponeActivity({ payload }: ReturnType<typeof postponeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload
    );
    yield put(postponeActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(messages.postponeSuccess, "Activity postponed successfully")
      ),
    });
  } catch (error) {
    yield put(postponeActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onToggleCompleteActivity({
  payload,
}: ReturnType<typeof toggleCompleteActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const response: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...payload,
      completed: !payload.completed,
    });
    yield put(toggleCompleteActivity.success(response));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(
          messages.toggleCompleteSuccess,
          `Activity marked as "${response.completed ? "Done" : "Not yet done"}"`,
          { completed: response.completed }
        )
      ),
    });
  } catch (error) {
    yield put(toggleCompleteActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUpdateActivityNote({ payload }: ReturnType<typeof updateActivityNote.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    const response: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload.activity
    );
    yield put(
      updateActivityNote.success({
        id: response.id,
        note: response.note,
      })
    );
    payload.onSuccess();
  } catch (error) {
    yield put(updateActivityNote.failure(payload.activity.id));
    yield put(handleError({ error }));
  }
}

export function* onChangeActivitiesRecapRange({
  payload: recapRange,
}: ReturnType<typeof changeActivitiesRecapRange>) {
  yield call(localSettings.setRecapChartRange, recapRange);
}

export function* onChangeActivitiesSelectedActivityTypes({
  payload: activityTypes,
}: ReturnType<typeof changeActivitiesSelectedActivityTypes>) {
  yield put(updateMetadata.request({ recapChartTypesIds: activityTypes.map(({ id }) => id) }));
}

export function* onDeletePerson() {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    yield callApi("deletePerson", org.id, person.id);
    yield put(deletePerson.success());
    notification.success({
      description: i18nService.formatMessage(
        messages.deleteSuccessDescription,
        "To restore this record, go to Data in Settings."
      ),
      message: i18nService.formatMessage(messages.deleteSuccess, "Person successfully deleted"),
    });
    yield put(notifyAboutChanges({ deletedIds: [person.id], entityType: EntityType.PERSON }));
  } catch (error) {
    yield put(deletePerson.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdatePersonFrequency({
  payload: { personId },
}: ReturnType<typeof updatePersonFrequency.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<Person> = yield callApi(
      "fetchPins",
      orgId,
      yield call(getFetchPersonPayload, personId)
    );
    const person: Person = response.data[0];
    yield put(updatePersonFrequency.success(person));
  } catch (error) {
    yield put(updatePersonFrequency.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchDeals() {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    const recordData: PersonRecordData = yield select(getRecordData);
    const $offset = recordData.deals.length;

    const response: ListResponse<Deal> = yield callApi("fetchDeals", org.id, {
      $filters: {
        $and: [
          {
            contactId: person.id,
          },
        ],
        includeAccessStatus: true,
        includeCustomFields: true,
        includeGroups: true,
        includeTerritories: true,
      },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $offset,
      $order: "name",
    });
    yield put(fetchMoreDeals.success(response.data));
  } catch (error) {
    yield put(fetchMoreDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCompanies() {
  try {
    const org: Organization = yield select(getOrganization);
    const person: Person = yield select(getPerson);
    const recordData: PersonRecordData = yield select(getRecordData);
    const offset = recordData.companies.length;

    const response: ListResponse<Company> = yield callApi("fetchCompanies", org.id, {
      $filters: {
        $and: [
          {
            id: {
              $in: person.accounts
                ?.map((account) => account.id)
                .slice(offset, offset + RELATIONSHIP_DATA_LOAD_LIMIT),
            },
          },
        ],
        includeAccessStatus: true,
        includeCustomFields: true,
        includeGroups: true,
        includeTerritories: true,
      },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $order: "name",
    });
    yield put(fetchMoreCompanies.success(response.data));
  } catch (error) {
    yield put(fetchMoreCompanies.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPins({ payload }: ReturnType<typeof fetchPins.request>) {
  try {
    const isColorChanged = !!payload.request?.colorKey?.[EntityType.PERSON];
    const isShapeChanged = !!payload.request?.shapeKey?.[EntityType.PERSON];

    if (!isColorChanged && !isShapeChanged) {
      return;
    }

    const person: Person | undefined = yield select(getPerson);
    if (!person) {
      return;
    }

    const organization: Organization = yield select(getOrganization);

    yield take(applyMapViewSettings);

    const response: ListResponse<Person> = yield callApi(
      "fetchPins",
      organization.id,
      yield call(getFetchPersonPayload, person.id)
    );

    const fetchedPerson: Person = response.data[0];

    if (isColorChanged) {
      yield put(setPersonColor(fetchedPerson.color));
    }

    if (isShapeChanged) {
      yield put(setPersonShape(fetchedPerson.shape));
    }
  } catch (error) {
    handleError({ error });
  }
}

export function* onUpdatePersonPrimaryCompany({
  payload,
}: ReturnType<typeof updatePersonPrimaryCompany.request>) {
  try {
    const { id, accounts, primaryCompanyId } = payload;
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const [person, companiesResponse]: [Person, ListResponse<Company>] = yield all([
      callApi("updatePerson", orgId, undefined, {
        id,
        accounts,
        primaryAccount: primaryCompanyId ? { id: primaryCompanyId } : null,
      }),
      callApi("fetchCompanies", orgId, {
        $filters: {
          $and: [
            {
              id: { $in: accounts.map(({ id }) => id) },
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
        $order: "name",
      }),
    ]);
    yield put(notifyAboutChanges({ entityType: EntityType.PERSON, updated: [person] }));

    yield put(
      updatePersonPrimaryCompany.success({
        companies: companiesResponse.data,
        total: person.accounts.length,
      })
    );
  } catch (error) {
    yield put(updatePersonPrimaryCompany.failure());
    yield put(handleError({ error }));
  }
}

export function* personRecordSaga() {
  yield takeEvery(addPersonDeals.request, onAddPersonDeals);
  yield takeEvery(addPersonCompanies.request, onAddPersonCompany);
  yield takeEvery(createPersonNote.request, onCreatePersonNote);
  yield takeEvery(deletePersonFile.request, onDeletePersonFile);
  yield takeEvery(deletePersonNote.request, onDeletePersonNote);
  yield takeEvery(downloadPersonFile, onDownloadPersonFile);
  yield takeLatest(fetchPersonRoutes.request, onFetchPersonRoutes);
  yield takeLatest(fetchPersonActivities.request, onFetchPersonActivities);
  yield takeLatest(
    fetchPersonActivitiesCompletedByType.request,
    onFetchPersonActivitiesCompletedByType
  );
  yield takeLatest(fetchPreviewData.request, onFetchPreviewData);
  yield takeLatest(fetchPersonActivitiesTotal.request, onFetchPersonActivitiesTotal);
  yield takeLatest(fetchPerson.request, onFetchPerson);
  yield takeLatest(fetchMoreDeals.request, onFetchDeals);
  yield takeEvery(postponeActivity.request, onPostponeActivity);
  yield takeEvery(removePersonDeal.request, onRemovePersonDeal);
  yield takeEvery(removePersonCompany.request, onRemovePersonCompany);
  yield takeEvery(removePersonFromRoute.request, onRemovePersonFromRoute);
  yield takeEvery(toggleCompleteActivity.request, onToggleCompleteActivity);
  yield takeEvery(updateActivityNote.request, onUpdateActivityNote);
  yield takeEvery(updatePerson.request, onUpdatePerson);
  yield takeEvery(uploadPersonFiles.request, onUploadPersonFiles);
  yield takeEvery(updatePersonNote.request, onUpdatePersonNote);
  yield takeEvery(changeActivitiesRecapRange, onChangeActivitiesRecapRange);
  yield takeEvery(changeActivitiesSelectedActivityTypes, onChangeActivitiesSelectedActivityTypes);
  yield takeEvery(deletePerson.request, onDeletePerson);
  yield takeEvery(fetchThumbnail, onFetchThumbnail);
  yield takeEvery(updatePersonFrequency.request, onUpdatePersonFrequency);
  yield takeLeading(fetchMoreCompanies.request, onFetchCompanies);
  yield takeEvery(fetchPins.request, onFetchPins);
  yield takeLatest(updatePersonPrimaryCompany.request, onUpdatePersonPrimaryCompany);
}
