import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import cn from "classnames";
import { useIntl } from "react-intl";

import Identified from "@mapmycustomers/shared/types/base/Identified";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import { LoadingSpinner, TextField, TextFieldProps } from "@mapmycustomers/ui";

import NoData from "@app/component/preview/components/NoData";

import AssociationRow from "./AssociationRow";
import AssociationRowWithPrimaryEntity from "./AssociationRowWithPrimaryEntity";
import styles from "./Associations.module.scss";
import messages from "./messages";

interface Props<T extends Identified> {
  alreadyAssociatedMessage: ReactNode;
  assignedRecords?: T[];
  associateWith?: T["id"];
  cantAssociateWithSelfMessage: ReactNode;
  className?: string;
  entities?: T[];
  extras?: ReactNode;
  filtered?: number;
  getListTitle?: (count: number, total: number) => string;
  loading?: boolean;
  multiselect?: boolean;
  noDataImageSrc?: string;
  onChange?: (selectedRows: Identified["id"][]) => void;
  onChangeQuery?: (query: string) => void;
  onSearch?: (text: string) => void;
  onUpdatePrimaryCompany?: (companyId?: Company["id"]) => void;
  primaryCompanyId?: Company["id"];
  query: string;
  suggestedEntities?: T[];
  supportsPrimaryEntity?: boolean;
  textFieldProps?: TextFieldProps;
  total?: number;
  value?: T["id"][];
}

type SupportedEntities = Company | Deal | Person;

const Associations = <T extends SupportedEntities>({
  alreadyAssociatedMessage,
  assignedRecords,
  associateWith,
  cantAssociateWithSelfMessage,
  className,
  entities,
  extras,
  filtered,
  getListTitle,
  loading,
  multiselect,
  noDataImageSrc,
  onChange,
  onChangeQuery,
  onSearch,
  onUpdatePrimaryCompany,
  primaryCompanyId,
  query,
  suggestedEntities,
  supportsPrimaryEntity,
  textFieldProps,
  total,
  value,
}: Props<T>) => {
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = useState<Set<Identified["id"]>>(new Set(value ?? []));
  const [showAllEntities, setShowAllEntities] = useState(false);

  useEffect(() => {
    setSelectedRows(new Set(value ?? []));
  }, [value]);

  const assignedRows = useMemo(
    () => new Set(assignedRecords?.map(({ id }) => id) ?? []),
    [assignedRecords]
  );

  const primaryCompanyName = useMemo(
    () => entities?.find(({ id }) => id === primaryCompanyId)?.name,
    [entities, primaryCompanyId]
  );

  const notifyAboutSearch = useDebouncedCallback(
    [
      useCallback(
        (searchText: string) => {
          onSearch?.(searchText.trim());
        },
        [onSearch]
      ),
      500,
    ],
    [onSearch]
  );

  const handleSetQuery = useCallback(
    (query: string) => {
      onChangeQuery?.(query);
      notifyAboutSearch(query);
    },
    [notifyAboutSearch, onChangeQuery]
  );

  const handleChange = useCallback(
    (entityId: Identified["id"], value: boolean) => {
      const updatedSelection = new Set(multiselect ? selectedRows : []);

      if (value) {
        updatedSelection.add(entityId);
      } else {
        updatedSelection.delete(entityId);
      }
      setSelectedRows(updatedSelection);

      onChange?.(Array.from(updatedSelection));
    },
    [multiselect, onChange, selectedRows, setSelectedRows]
  );

  const hasQuery = query?.trim().length > 0;

  return (
    <section className={cn(styles.container, className)}>
      <div className={styles.searchBar}>
        <TextField
          {...textFieldProps}
          allowClear={hasQuery}
          autoFocus
          className={cn(styles.searchField, textFieldProps?.className)}
          onChange={handleSetQuery}
          suffix={<FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />}
          value={query}
        />

        {extras && <div>{extras}</div>}
      </div>

      {showAllEntities && (
        <div className={styles.sortOrder}>
          {getListTitle?.(filtered ?? entities?.length ?? total ?? 0, total ?? 0) ??
            intl.formatMessage(messages.allRecords, {
              count: filtered ?? entities?.length ?? total ?? 0,
              total,
            })}
        </div>
      )}

      {suggestedEntities && !showAllEntities && !hasQuery && (
        <div className={styles.sortOrder}>
          {intl.formatMessage(messages.associated, {
            suggested: suggestedEntities.length,
            total,
          })}
        </div>
      )}

      {!showAllEntities && suggestedEntities && suggestedEntities.length === 0 && !hasQuery ? (
        <></>
      ) : (
        <div className={styles.listContainer}>
          <div className={styles.list}>
            {loading ? (
              <LoadingSpinner />
            ) : entities?.length || suggestedEntities?.length ? (
              <>
                {(!showAllEntities && suggestedEntities && !hasQuery
                  ? suggestedEntities
                  : entities
                )?.map((entity) => {
                  return supportsPrimaryEntity ? (
                    <AssociationRowWithPrimaryEntity
                      entity={entity}
                      key={entity.id}
                      multiselect={multiselect}
                      onChange={handleChange}
                      onUpdatePrimaryCompany={onUpdatePrimaryCompany}
                      primaryCompanyId={primaryCompanyId}
                      primaryCompanyName={primaryCompanyName}
                      selected={selectedRows.has(entity.id)}
                      selectedRowsCount={selectedRows.size}
                      tooltipMessage={
                        entity.id === associateWith
                          ? cantAssociateWithSelfMessage
                          : assignedRows.has(entity.id)
                          ? alreadyAssociatedMessage
                          : null
                      }
                    />
                  ) : (
                    <AssociationRow
                      entity={entity}
                      key={entity.id}
                      multiselect={multiselect}
                      onChange={handleChange}
                      selected={selectedRows.has(entity.id)}
                      tooltipMessage={
                        entity.id === associateWith
                          ? cantAssociateWithSelfMessage
                          : assignedRows.has(entity.id)
                          ? alreadyAssociatedMessage
                          : null
                      }
                    />
                  );
                })}
              </>
            ) : (
              <NoData
                imageSrc={noDataImageSrc ?? ""}
                text={intl.formatMessage(messages.noRecords)}
              />
            )}
          </div>
        </div>
      )}
      {!showAllEntities && suggestedEntities && !hasQuery && (
        <div className={styles.showAll}>
          <span className={styles.cantFind}>
            {intl.formatMessage(
              suggestedEntities.length > 0 ? messages.cantFind : messages.noAssociatedRecords
            )}
          </span>
          <Button onClick={() => setShowAllEntities(true)} type="link">
            {intl.formatMessage(messages.showAll)}
          </Button>
        </div>
      )}
    </section>
  );
};

export default Associations;
