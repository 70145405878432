import React, { useMemo } from "react";
import { connect } from "react-redux";

import cn from "classnames";
import { useIntl } from "react-intl";

import RoleEnum from "@mapmycustomers/shared/enum/Role";
import type Role from "@mapmycustomers/shared/types/Role";
import User from "@mapmycustomers/shared/types/User";

import UserSelectField from "@app/component/input/UserSelectField";
import TextWithInfo from "@app/component/typography/TextWithInfo";
import { getRole } from "@app/store/iam";
import type { RootState } from "@app/store/rootReducer";

import styles from "./ViewAsFilterComponent.module.scss";

interface Props {
  className?: string;
  currentUserRole?: Role;
  onChange: (user: undefined | User) => void;
  users: User[];
  value: undefined | User;
}

const ViewAsFilterComponent: React.FC<Props> = ({
  className,
  currentUserRole,
  onChange,
  users,
  value,
}) => {
  const intl = useIntl();

  const availableUsers = useMemo(() => {
    return users.filter(
      (user) =>
        ((user.viaTeamAccess && !user.deletedAt) || user.id === value?.id) &&
        // owner can see everybody
        (currentUserRole?.key === RoleEnum.OWNER ||
          // managers can see other managers and reps
          (currentUserRole?.key === RoleEnum.MANAGER && user.role.key !== RoleEnum.OWNER) ||
          // reps are visible to everybody
          user.role.key === RoleEnum.MEMBER)
    );
  }, [currentUserRole, users, value?.id]);

  return (
    <div className={cn(styles.container, className)}>
      <UserSelectField
        className={styles.selectField}
        defaultUsersOption={false}
        label={false}
        onChange={onChange}
        size="middle"
        users={availableUsers}
        value={value}
      />
      <TextWithInfo
        info={intl.formatMessage({
          id: "filters.viewAs.tooltip",
          defaultMessage:
            "Filtering by what’s visible to another user shows only the records they are permitted to see",
          description: "Tooltip in the view as filter",
        })}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUserRole: getRole(state),
});

export default connect(mapStateToProps, {})(ViewAsFilterComponent);
