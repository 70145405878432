import { useMemo } from "react";

import { IntlShape } from "react-intl";

const useEmailSendVariants = (
  intl: IntlShape,
  onSendViaMMCEmail: () => void,
  onEmail: () => void,
  email: string,
  isReadOnlyMember?: boolean
) =>
  useMemo(() => {
    return [
      {
        disabled: isReadOnlyMember,
        key: "sendViaMMCEmail",
        label: intl.formatMessage({
          id: "utils.email.sendVariants.sendViaMMC",
          defaultMessage: "Send via MMC",
          description: "Send via MMC email",
        }),
        onClick: onSendViaMMCEmail,
      },
      {
        disabled: isReadOnlyMember,
        key: "sendInMyEmailClient",
        label: intl.formatMessage({
          id: "utils.email.sendVariants.sendInMyEmailClient",
          defaultMessage: "Send in my email client",
          description: "Send in my email client",
        }),
        onClick: onEmail,
      },
      {
        key: "copyEmail",
        label: intl.formatMessage({
          id: "utils.email.sendVariants.copyEmail",
          defaultMessage: "Copy Email",
          description: "Copy Email",
        }),
        onClick: () => navigator.clipboard.writeText(email),
      },
    ];
  }, [email, intl, isReadOnlyMember, onEmail, onSendViaMMCEmail]);

export default useEmailSendVariants;
