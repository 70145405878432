import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import Trip from "@mapmycustomers/shared/types/entity/Trip";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import type { RawFile } from "@mapmycustomers/shared/types/File";
import ColumnModel from "@mapmycustomers/shared/types/viewModel/internalModel/ColumnModel";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";

import type FileListItem from "@app/types/FileListItem";
import { ListFetcherPayload } from "@app/types/viewModel/ListFetcher";

import TripStats from "../../components/MileageTracking/types/TripsStats";

import TripRecordData from "./TripRecordData";

export const initializeTripListView = createAsyncAction(
  "reports/mileageTracking/initialize/request",
  "reports/mileageTracking/initialize/success",
  "reports/mileageTracking/initialize/failure"
)<void, { firstDateWithData: Date | undefined }, unknown>();

export const fetchTripList = createAsyncAction(
  "reports/mileageTracking/fetch/request",
  "reports/mileageTracking/fetch/success",
  "reports/mileageTracking/fetch/failure"
)<
  ListFetcherPayload<Trip>,
  {
    stats: TripStats;
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyTripListViewSettings = createAction(
  "reports/mileageTracking/applyListViewSettings"
)<Partial<ListRequest>>();

export const updateTrip = createAsyncAction(
  "reports/mileageTracking/updateTrip/request",
  "reports/mileageTracking/updateTrip/success",
  "reports/mileageTracking/updateTrip/failure"
)<{ entity: Trip; onSuccess?: (trip: Trip) => void }, Trip, unknown>();

export const deleteTrip = createAsyncAction(
  "reports/mileageTracking/deleteTrip/request",
  "reports/mileageTracking/deleteTrip/success",
  "reports/mileageTracking/deleteTrip/failure"
)<{ reason: string }, void, void>();

export const deleteTrips = createAsyncAction(
  "reports/mileageTracking/deleteTrips/request",
  "reports/mileageTracking/deleteTrips/success",
  "reports/mileageTracking/deleteTrips/failure"
)<{ onSuccess?: () => void; reason: string; tripIds: Trip["id"][] }, void, void>();

export const fetchTripPreviewData = createAsyncAction(
  "reports/mileageTracking/fetchTripPreviewData/request",
  "reports/mileageTracking/fetchTripPreviewData/success",
  "reports/mileageTracking/fetchTripPreviewData/failure"
)<Trip["id"], TripRecordData, void>();

export const clearAllUploadedTripFiles = createAction(
  "reports/mileageTracking/clearAllUploadedTripFiles"
)<void>();

export const fetchThumbnail = createAction("reports/mileageTracking/fetchThumbnail")<{
  callback: (data: Blob) => void;
  fileId: RawFile["id"];
}>();

export const downloadTripFile = createAction("reports/mileageTracking/downloadTripFile")<RawFile>();

export const uploadTripFiles = createAsyncAction(
  "reports/mileageTracking/uploadTripFiles/request",
  "reports/mileageTracking/uploadTripFiles/success",
  "reports/mileageTracking/uploadTripFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    fileGroupId?: string;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const deleteTripFile = createAsyncAction(
  "reports/mileageTracking/deleteTripFile/request",
  "reports/mileageTracking/deleteTripFile/success",
  "reports/mileageTracking/deleteTripFile/failure"
)<RawFile, { file: RawFile; removed: boolean }, RawFile>();

export const downloadTripList = createAction("reports/mileageTracking/downloadList")<{
  columns: ColumnModel<IField>;
}>();

export type MileageTrackingActions = ActionType<
  | typeof applyTripListViewSettings
  | typeof clearAllUploadedTripFiles
  | typeof deleteTrip
  | typeof deleteTripFile
  | typeof deleteTrips
  | typeof downloadTripFile
  | typeof downloadTripList
  | typeof fetchThumbnail
  | typeof fetchTripList
  | typeof fetchTripPreviewData
  | typeof initializeTripListView
  | typeof updateTrip
  | typeof uploadTripFiles
>;
