import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";

import RoundButton from "@app/component/RoundButton";
import { isCurrentUserReadOnly } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";
import isEmailActivity from "@app/util/email/isEmailActivity";

import PostponeButton from "../../PostponeButton";
import EditActivityFocusableField from "../../type/EditActivityFocusableField";
import { isPostponeLoading, isToggleCompleteLoading } from "../store";

import styles from "./Footer.module.scss";

interface Props {
  activity: Activity;
  analyticIssuer?: AnalyticsService;
  isReadOnlyMember: boolean;
  onEdit: (focusedFieldName?: EditActivityFocusableField) => void;
  onPostponeActivity: (activity: Activity) => void;
  onToggleComplete: () => void;
  postponeLoading?: boolean;
  toggleCompleteLoading?: boolean;
}

const Footer: React.FC<Props> = ({
  activity,
  analyticIssuer,
  isReadOnlyMember,
  onEdit,
  onPostponeActivity,
  onToggleComplete,
  postponeLoading,
  toggleCompleteLoading,
}) => {
  const intl = useIntl();

  const handleCompleteClick = useCallback(() => {
    onToggleComplete();
    if (!activity.completed) {
      analyticIssuer?.clicked(["Mark Complete"]);
    } else {
      analyticIssuer?.clicked(["Mark Incomplete"]);
    }
  }, [activity.completed, analyticIssuer, onToggleComplete]);

  return (
    <div className={styles.container}>
      <RoundButton leftIcon={<FontAwesomeIcon icon={faPen} />} onClick={() => onEdit()}>
        {intl.formatMessage({
          id: "component.activityAnnotation.footer.edit",
          defaultMessage: "Edit",
          description: "Label for Edit button",
        })}
      </RoundButton>
      {!activity.completed && (
        <PostponeButton
          activity={activity}
          analyticIssuer={analyticIssuer}
          disabled={isReadOnlyMember}
          loading={postponeLoading}
          onSelectAnotherDate={() => onEdit("startAt")}
          onUpdate={onPostponeActivity}
        />
      )}
      <Tooltip
        title={
          isEmailActivity(activity)
            ? intl.formatMessage({
                id: "component.activityAnnotation.footer.toggleComplete.disabledTooltip.done",
                defaultMessage:
                  "Auto Email activities can’t be undone because they are logged once an email is successfully sent.",
                description: "Email activities cannot be scheduled for done action",
              })
            : undefined
        }
        trigger={"hover"}
      >
        <div>
          <RoundButton
            active={activity.completed}
            disabled={isEmailActivity(activity)||isReadOnlyMember}
            leftIcon={<FontAwesomeIcon icon={faCheckCircle} />}
            loading={toggleCompleteLoading}
            onClick={handleCompleteClick}
          >
            {intl.formatMessage(
              {
                id: "component.activityAnnotation.footer.completeToggle",
                defaultMessage: "{completed, select, true {Done} other {Mark Done}}",
                description: "Label for done button",
              },
              { completed: activity.completed }
            )}
          </RoundButton>
        </div>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isReadOnlyMember: isCurrentUserReadOnly(state),
  postponeLoading: isPostponeLoading(state),
  toggleCompleteLoading: isToggleCompleteLoading(state),
});

export default connect(mapStateToProps)(Footer);
