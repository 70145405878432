import React from "react";

import Badge, { BadgeProps } from "antd/es/badge";

import Colored from "@mapmycustomers/shared/types/base/Colored";
import Named from "@mapmycustomers/shared/types/base/Named";

import colors from "@app/styles/_colors.module.scss";
import anyColorToHex from "@app/util/colors/anyColorToHex";

interface Props {
  className?: string;
  entity: Colored & Named;
  hideName?: boolean;
  size?: BadgeProps["size"];
  textColor?: string;
}

const EntityBadge: React.FC<Props> = ({ className, entity, hideName, textColor, ...props }) => {
  const color = textColor ? textColor : entity.color ? anyColorToHex(entity.color) : colors.slate;

  return (
    <Badge className={className} color={color} text={hideName ? "" : entity.name} {...props} />
  );
};

export default EntityBadge;
