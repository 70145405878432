import { defineMessages, IntlShape } from "react-intl";

import DistanceUnit from "@mapmycustomers/shared/enum/DistanceUnit";

const messages = defineMessages({
  [DistanceUnit.KM]: {
    id: "distanceUnit.kilometer",
    defaultMessage: "Kilometers",
    description: "Kilometer distance unit",
  },
  [DistanceUnit.MILE]: {
    id: "distanceUnit.mile",
    defaultMessage: "Miles",
    description: "Mile distance unit",
  },
});

const getDistanceUnitDisplayName = (intl: IntlShape, unit: DistanceUnit) =>
  intl.formatMessage(messages[unit]);

export default getDistanceUnitDisplayName;
