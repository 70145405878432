import React, { useCallback, useMemo } from "react";

import { faAlarmSnooze } from "@fortawesome/pro-solid-svg-icons/faAlarmSnooze";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import { defineMessages, useIntl } from "react-intl";

import ActivityStatus from "@mapmycustomers/shared/enum/activity/ActivityStatus";
import PostponedDateGetter from "@mapmycustomers/shared/types/entity/activities/PostponedDateGetter";
import Activity from "@mapmycustomers/shared/types/entity/Activity";

import RoundButton from "@app/component/RoundButton";
import ButtonType from "@app/enum/ButtonType";
import getActivityStatus from "@app/util/activity/getActivityStatus";
import { AnalyticsService } from "@app/util/analytic/AnalyticsService";

import postponeActivity from "../../util/activity/postponeActivity";

import { getPostponeMenuItems } from "./utils/postpone";

const messages = defineMessages({
  selectAnotherDateTime: {
    id: "activityList.activityAnnotation.footer.postpone",
    defaultMessage: "Select another date and time",
    description: "Additional item for postpone options in activity annotation",
  },
});

interface Props {
  activity: Activity;
  analyticIssuer?: AnalyticsService;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onSelectAnotherDate?: () => void;
  onUpdate: (activity: Activity) => void;
}

const PostponeButton: React.FC<Props> = ({
  activity,
  analyticIssuer,
  className,
  disabled,
  loading,
  onSelectAnotherDate,
  onUpdate,
}) => {
  const intl = useIntl();

  const status = getActivityStatus(activity);

  const handlePostpone = useCallback(
    (postponedDateGetter: PostponedDateGetter) => {
      onUpdate(postponeActivity(activity, postponedDateGetter));
    },
    [activity, onUpdate]
  );

  const menuItems = useMemo(() => {
    const items = getPostponeMenuItems(intl, status === ActivityStatus.OVERDUE, handlePostpone);
    if (onSelectAnotherDate) {
      items.push({
        label: intl.formatMessage(messages.selectAnotherDateTime),
        onClick: onSelectAnotherDate,
      });
    }
    return items;
  }, [intl, handlePostpone, status, onSelectAnotherDate]);

  const handleClick = useCallback(() => {
    analyticIssuer?.clicked(["Postpone"]);
  }, [analyticIssuer]);

  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <Menu>
          {menuItems.map((item, index) => (
            <Menu.Item key={index} onClick={item.onClick}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
    >
      <RoundButton
        className={className}
        disabled={disabled}
        hasMenu
        leftIcon={<FontAwesomeIcon icon={faAlarmSnooze} />}
        loading={loading}
        onClick={handleClick}
        type={ButtonType.SECONDARY}
      >
        {intl.formatMessage({
          id: "activity.postpone",
          defaultMessage: "Postpone",
          description: "Label for postpone activity action button",
        })}
      </RoundButton>
    </Dropdown>
  );
};

export default PostponeButton;
