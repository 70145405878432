import { defineMessages, IntlShape } from "react-intl";

import { TripType } from "@mapmycustomers/shared/enum";
import Trip from "@mapmycustomers/shared/types/entity/Trip";

const messages = defineMessages<"unclassified" | TripType>({
  [TripType.BUSINESS]: {
    id: "tripType.business",
    defaultMessage: "Business",
    description: "Name of business trip type",
  },
  [TripType.PERSONAL]: {
    id: "tripType.personal",
    defaultMessage: "Personal",
    description: "Name of personal trip type",
  },
  unclassified: {
    id: "tripType.unclassified",
    defaultMessage: "Unclassified",
    description: "Name of unclassified trip type",
  },
});

const getTripTypeDisplayName = (intl: IntlShape, tripType: Trip["type"]) =>
  intl.formatMessage(messages[tripType ?? "unclassified"]);

export default getTripTypeDisplayName;
