import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";

import { SupportedSources } from "@app/enum/SourceType";
import CardConfiguration, {
  DefaultDateRangeConfiguration,
} from "@app/types/dashboard/CardConfiguration";
import BasePreferences from "@app/types/dashboard/cards/BasePreferences";

export enum CustomerFaceTimeAggregationType {
  SUM = "SUM",
  AVG = "AVG",
}

export interface CustomerFaceTimeCriteria {
  activityTypeIds: ActivityType["id"][];
  aggregationType: CustomerFaceTimeAggregationType;
  includeUnverified?: boolean;
  sources?: SupportedSources[];
}

export interface CustomerFaceTimePreferences extends BasePreferences {
  showTotal?: boolean;
  showUnverified?: boolean;
  showVerified?: boolean;
  verifiedBreakdown?: boolean;
}

export default interface CustomerFaceTimeCardConfiguration
  extends CardConfiguration<
    CustomerFaceTimeCriteria,
    DefaultDateRangeConfiguration,
    CustomerFaceTimePreferences
  > {}
