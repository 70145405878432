import React, { useMemo } from "react";

import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons/faCheckDouble";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons/faCommentDots";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils";
import { faBolt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip, { TooltipPropsWithTitle } from "antd/es/tooltip";
import cn from "classnames";
import camelCase from "lodash-es/camelCase";
import { useIntl } from "react-intl";

import { ActivityTypeRef } from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import anyColorToHex from "@mapmycustomers/shared/util/color/anyColorToHex";

import CustomActivityTypeColor, { colorToName } from "@app/enum/CustomActivityTypeColor";
import getClosestColor from "@app/util/colors/getClosestColor";

import styles from "./ActivityTypeIcon.module.scss";

const defaultActivityNameFormatting = {
  Call: {
    className: styles.call,
    icon: faPhone,
  },
  Email: {
    className: styles.email,
    icon: faEnvelope,
  },
  Lunch: {
    className: styles.lunch,
    icon: faUtensils,
  },
  Meeting: {
    className: styles.meeting,
    icon: faUserFriends,
  },
  Task: {
    className: styles.task,
    icon: faCheckDouble,
  },
  Text: {
    className: styles.text,
    icon: faCommentDots,
  },
  Visit: {
    className: styles.visit,
    icon: faMapMarkerAlt,
  },
} as const;

type DefaultActivityType = keyof typeof defaultActivityNameFormatting;

interface Props {
  activityType?: ActivityTypeRef;
  activityTypeIconClassName?: string;
  className?: string;
  isAutoEmail?: boolean;
  size?: "lg" | "md" | "sm";
  tooltipProps?: false | TooltipPropsWithTitle;
}

const ActivityTypeIcon: React.FC<Props> = ({
  activityType,
  activityTypeIconClassName,
  className: externalClassName,
  isAutoEmail,
  size = "md",
  tooltipProps,
}) => {
  const intl = useIntl();

  const isDefaultActivityType = activityType && activityType.name in defaultActivityNameFormatting;
  let { className, icon } = isDefaultActivityType
    ? defaultActivityNameFormatting[activityType.name as DefaultActivityType]
    : { className: styles.default, icon: faCalendarCheck };

  const activityTypeColor = useMemo(() => {
    if (!activityType?.color) {
      return undefined;
    }
    const closestColor = getClosestColor(
      Object.values(CustomActivityTypeColor),
      activityType.color,
      undefined,
      50
    );
    return closestColor && anyColorToHex(closestColor);
  }, [activityType?.color]);

  if (!isDefaultActivityType && activityTypeColor && colorToName[activityTypeColor]) {
    className = styles[`custom_${camelCase(colorToName[activityTypeColor])}`];
  }

  const tooltipText = useMemo(
    () =>
      isAutoEmail
        ? intl.formatMessage({
            id: "component.activityTypeIcon.autoEmail",
            defaultMessage: "Auto Email",
            description: "Auto email tooltip text",
          })
        : activityType?.name ?? null,
    [activityType?.name, intl, isAutoEmail]
  );

  const iconContent = (
    <div
      className={cn(
        styles.activityTypeIcon,
        styles[size],
        className,
        activityTypeIconClassName,
        externalClassName
      )}
    >
      {isAutoEmail && <FontAwesomeIcon className={styles.autoEmail} icon={faBolt} />}
      <FontAwesomeIcon icon={icon} />
    </div>
  );

  return tooltipProps !== false ? (
    <Tooltip title={tooltipText} {...tooltipProps}>
      {iconContent}
    </Tooltip>
  ) : (
    iconContent
  );
};

export default ActivityTypeIcon;
