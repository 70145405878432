import { HexColor } from "@mapmycustomers/shared/types/AnyColor";

import cssColors from "@app/component/activity/_activityTypeColors.module.scss";

const CustomActivityTypeColor = {
  AMBER: cssColors.amber,
  COFFEE: cssColors.coffee,
  DENIM: cssColors.denim,
  FOREST: cssColors.forest,
  GRAPE: cssColors.grape,
  LATTE: cssColors.latte,
  LILAC: cssColors.lilac,
  MANDARIN: cssColors.mandarin,
  MAROON: cssColors.maroon,
  MIDNIGHT: cssColors.midnight,
  MOSS: cssColors.moss,
  OCEAN: cssColors.ocean,
  OLIVE: cssColors.olive,
  ORCHID: cssColors.orchid,
  PURPLE: cssColors.purple,
  ROYAL_BLUE: cssColors.royalBlue,
  SEAWEED: cssColors.seaweed,
  STONE: cssColors.stone,
  TERRACOTTA: cssColors.terracotta,
  WATERMELON: cssColors.watermelon,
};

export const colorToName = Object.entries(CustomActivityTypeColor).reduce(
  (result, [name, value]) => Object.assign(result, { [value]: name }),
  {} as Record<HexColor, string>
);

export default CustomActivityTypeColor;
