import { partition } from "lodash-es";
import { Action } from "redux";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel, {
  Condition,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";

import SchedulerViewState from "@app/component/view/SchedulerView/types/SchedulerViewState";
import localSettings from "@app/config/LocalSettings";
import CalendarViewMode from "@app/enum/CalendarViewMode";
import getActivityDateRange from "@app/scene/activity/utils/getActivityDateRange";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { getSelectedFilter } from "@app/store/savedFilters";
import { initializeSavedFiltersForView } from "@app/store/savedFilters/actions";
import { onSetUserColors } from "@app/store/userColor/sagas";
import { isActivity } from "@app/util/activity/assert";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";
import { activityLayoutModel } from "@app/util/layout/impl";
import { parseApiDate } from "@app/util/parsers";
import { isSimpleCondition } from "@app/util/viewModel/assert";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  applySchedulerViewSettings,
  exportSchedulerActivities,
  fetchSchedulerActivities,
  initializeSchedulerView,
  moveResizeActivity,
} from "./actions";
import { getSchedulerViewState, getSchedulerViewTotalFilteredRecordsCount } from "./selectors";

export const SCHEDULER_VIEW_STATE = "activity/schedulerView";

export function* onInitializeSchedulerView() {
  const savedFilterGetter: (entityType: EntityType) => SavedFilter | undefined = yield select(
    getSelectedFilter
  );
  const savedFilter = savedFilterGetter(EntityType.ACTIVITY);
  if (!savedFilter) {
    yield put(
      initializeSavedFiltersForView({
        entityType: EntityType.ACTIVITY,
        viewKey: SCHEDULER_VIEW_STATE,
      })
    );
  }
  yield put(initializeSchedulerView.success());
  // now fetch initial portion of activities. Not passing any viewState, because we don't need any changes there
  yield put(fetchSchedulerActivities.request({ viewState: {} }));
}

const getRangeCondition = (startAt: Date, viewMode: CalendarViewMode): Condition => {
  const [start, end] = getActivityDateRange(startAt, viewMode);
  return { $gte: start, $lte: end };
};

const getSchedulerRequestFilters = (
  viewState: SchedulerViewState,
  ignoreFilters: boolean = false
): PlatformFilterModel => {
  // Add special filters for the date range.
  // By default, we only keep startAt in filter model, but in fact we should send more details to
  // the backend to get appropriate result. First, we need to generate range for the startAt
  // field. Second, we're not only interested in activities which start in the generated timeframe, but
  // also in ones which end there. Thus, we need to add a filter for the endAt field too.
  // And combine both these filters under the $or conjunction.
  // We also have a special processing for quickFilter filter.

  const { quickFilter, startAt, ...filters } = ignoreFilters
    ? ({} as FilterModel)
    : viewState.filter;
  const platformFilterModel = convertToPlatformFilterModel(
    filters,
    viewState.columns,
    activityFieldModel,
    true,
    viewState.viewAs
  );

  if (!ignoreFilters && startAt && isSimpleCondition(startAt)) {
    const startDate = parseApiDate(startAt.value);
    const rangeCondition = getRangeCondition(startDate, viewState.viewMode);
    platformFilterModel.$or = [{ startAt: rangeCondition }, { endAt: rangeCondition }];
  }

  if (!ignoreFilters && quickFilter && isSimpleCondition(quickFilter)) {
    const { value } = quickFilter;
    platformFilterModel.$and?.push({
      $or: [
        { name: { $in: value } },
        { "account.name": { $in: value } },
        { "contact.name": { $in: value } },
        { "deal.name": { $in: value } },
      ],
    });
  }
  platformFilterModel.includeNotes = true;
  platformFilterModel.includeCustomFields = true;

  return platformFilterModel;
};

export function* onExportSchedulerActivities({
  payload: { viewState },
}: ReturnType<typeof exportSchedulerActivities>) {
  const total: number = yield select(getSchedulerViewTotalFilteredRecordsCount);
  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      platformRequest: {
        $filters: getSchedulerRequestFilters(viewState),
        $order: convertToPlatformSortModel(viewState.sort),
      },
      total,
      viewState: { columns: viewState.columns },
    })
  );
}

export function* onFetchSchedulerActivities({
  payload,
}: ReturnType<typeof fetchSchedulerActivities.request>) {
  try {
    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applySchedulerViewSettings(payload.viewState));
    }
    const organizationId: Organization["id"] = yield select(getOrganizationId);

    const viewState: SchedulerViewState = yield select(getSchedulerViewState);

    if (!payload.fetchOnlyWithoutFilters && !payload.updateOnly) {
      localSettings.setViewSettings(viewState, SCHEDULER_VIEW_STATE);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.startRow
        : viewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.endRow - payload.viewState.range.startRow
        : viewState.range.endRow - viewState.range.startRow;

    const requestPayload = {
      $filters: getSchedulerRequestFilters(viewState, payload.fetchOnlyWithoutFilters),
      $limit,
      $offset,
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organizationId,
      requestPayload
    );

    const [activities] = partition(response.data, isActivity);

    payload.dataCallback?.({ ...response, data: activities });
    yield put(
      fetchSchedulerActivities.success({
        activities,
        totalFilteredRecords: activities.length,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchSchedulerActivities.failure());
    yield put(handleError({ error }));
  }
}

export function* onMoveResizeActivity({ payload }: ReturnType<typeof moveResizeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload.activity
    );
    yield put(moveResizeActivity.success(updatedActivity));
    yield put(fetchSchedulerActivities.request({ viewState: {} })); // just reload, don't change view
  } catch (error) {
    if (payload.failCallback) {
      yield call(payload.failCallback, payload.activity);
    }
    yield put(moveResizeActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onApplySchedulerViewSettings({
  payload,
}: ReturnType<typeof applySchedulerViewSettings>) {
  yield call(onSetUserColors, payload);
}

export function* schedulerSaga() {
  yield takeEvery(initializeSchedulerView.request, onInitializeSchedulerView);
  yield takeLatest(
    (action: Action) =>
      isActionOf(fetchSchedulerActivities.request)(action) && !action.payload.updateOnly,
    onFetchSchedulerActivities
  );
  yield takeEvery(
    (action: Action) =>
      isActionOf(fetchSchedulerActivities.request)(action) && !!action.payload.updateOnly,
    onFetchSchedulerActivities
  );
  yield takeEvery(moveResizeActivity.request, onMoveResizeActivity);
  yield takeEvery(applySchedulerViewSettings, onApplySchedulerViewSettings);
  yield takeEvery(exportSchedulerActivities, onExportSchedulerActivities);
}
