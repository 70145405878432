import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "antd/es/badge";
import Button from "antd/es/button";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Popconfirm from "antd/es/popconfirm";
import Popover from "antd/es/popover";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import Color from "@mapmycustomers/shared/enum/Color";
import EntityType from "@mapmycustomers/shared/enum/EntityType";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import Located from "@mapmycustomers/shared/types/base/Located";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import { isNotEmptyString } from "@mapmycustomers/shared/util/assert";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { getFormattedAddressForUi } from "@mapmycustomers/shared/util/formatters";
import { Avatar } from "@mapmycustomers/ui";

import FieldGrid from "@app/component/FieldGrid";
import Checkbox from "@app/component/input/Checkbox";
import RadioButton from "@app/component/input/RadioButton";
import EntityColumnsConfiguration from "@app/store/recordPreview/EntityColumnsConfiguration";
import { getRecordPreviewConfigurationColumns } from "@app/store/recordPreview/selectors";
import { RootState } from "@app/store/rootReducer";
import anyColorToHex from "@app/util/colors/anyColorToHex";
import { TOPMOST_MODAL_ZINDEX } from "@app/util/consts";
import locatedToAddress from "@app/util/locatedToAddress";
import loggingService from "@app/util/logging";

import styles from "./AssociationRowWithPrimaryEntity.module.scss";
import messages from "./messages";

interface Props {
  columns: EntityColumnsConfiguration;
  disabled?: boolean;
  entity: Company | Deal | Person;
  multiselect?: boolean;
  onChange?: (entityId: Identified["id"], value: boolean) => void;
  onUpdatePrimaryCompany?: (accountId?: Company["id"]) => void;
  primaryCompanyId?: Company["id"];
  primaryCompanyName?: Company["name"];
  selected: boolean;
  selectedRowsCount: number;
  tooltipMessage?: ReactNode;
}

const AssociationRowWithPrimaryEntity: React.FC<Props> = ({
  columns,
  disabled,
  entity,
  multiselect,
  onChange,
  onUpdatePrimaryCompany,
  primaryCompanyId,
  primaryCompanyName,
  selected = false,
  selectedRowsCount,
  tooltipMessage,
}) => {
  const intl = useIntl();
  const [popconfirmOpen, setPopconfirmOpen] = useState(false);

  const primary = primaryCompanyId === entity.id;

  const entityColumns = useMemo(() => {
    return (columns?.[entity.entity] ?? []).filter(
      (field) =>
        !field.hasFeature(FieldFeature.MAP_PINNED_FIELD) &&
        field.isReadable &&
        field.hasNonEmptyValueFor(entity)
    );
  }, [columns, entity]);

  const labelId = `association_${entity.entity}:${entity.id}`;

  const style = useMemo(
    () => ({
      borderColor: anyColorToHex(
        (entity.entity === EntityType.DEAL ? entity.stage.color : entity.color) ?? Color.BLACK
      ),
    }),
    [entity]
  );

  const handleChange = useCallback(
    (checked: boolean, event: CheckboxChangeEvent) => {
      event.stopPropagation();
      if (primary && selected) {
        onUpdatePrimaryCompany?.();
      }

      if (checked && !primaryCompanyId && selectedRowsCount === 0) {
        onUpdatePrimaryCompany?.(entity.id);
      }
      onChange?.(entity.id, checked);
    },
    [
      entity.id,
      onChange,
      onUpdatePrimaryCompany,
      primary,
      primaryCompanyId,
      selected,
      selectedRowsCount,
    ]
  );

  const handleClick = useCallback(() => {
    if (primary && selected) {
      onUpdatePrimaryCompany?.();
    }

    if (!selected && !primaryCompanyId && selectedRowsCount === 0) {
      onUpdatePrimaryCompany?.(entity.id);
    }
    onChange?.(entity.id, !selected);
  }, [
    entity.id,
    onChange,
    onUpdatePrimaryCompany,
    primary,
    primaryCompanyId,
    selected,
    selectedRowsCount,
  ]);

  const info = useMemo(() => {
    if ([EntityType.COMPANY, EntityType.PERSON].includes(entity.entity)) {
      const companyOrPerson = entity as Company | Person;
      return [
        getFormattedAddressForUi(locatedToAddress(companyOrPerson as Located)),
        companyOrPerson.phone,
        companyOrPerson.email,
      ]
        .filter(isNotEmptyString)
        .join(" • ");
    } else if (entity.entity === EntityType.DEAL) {
      const deal = entity as Deal;
      return (
        <>
          <Badge className={styles.badge} color={deal.stage.color} text={deal.stage.name} />
          {` • ${[deal.funnel.name, deal.account?.name, deal.contact?.name]
            .filter(isNotEmptyString)
            .join(" • ")}`}
        </>
      );
    } else {
      loggingService.error(`Unexpected entity type in AssociationRow's entity: ${entity.entity}`, {
        entity,
      });
      return null;
    }
  }, [entity]);

  const handleConfirm = useCallback(() => {
    onUpdatePrimaryCompany?.(entity.id);
  }, [entity.id, onUpdatePrimaryCompany]);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!primaryCompanyName && open) {
        handleConfirm();
      } else {
        setPopconfirmOpen(open);
      }
    },
    [handleConfirm, primaryCompanyName]
  );

  const handleUnsetPrimaryCompany = useCallback(
    (e?: React.MouseEvent<HTMLElement>) => {
      stopEvents(e);
      onUpdatePrimaryCompany?.();
    },
    [onUpdatePrimaryCompany]
  );

  const handleCancel = useCallback(() => {
    setPopconfirmOpen(false);
  }, []);

  return (
    <div
      className={cn(styles.item, { [styles.disabled]: disabled })}
      onClick={handleClick}
      style={style}
    >
      {multiselect !== undefined && (
        <div className={styles.control}>
          <Tooltip title={tooltipMessage} trigger={disabled && tooltipMessage ? ["hover"] : []}>
            {multiselect ? (
              <Checkbox
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            ) : (
              <RadioButton
                checked={selected}
                disabled={disabled}
                id={labelId}
                onChange={handleChange}
              />
            )}
          </Tooltip>
        </div>
      )}
      <div className={cn(styles.details, { [styles.disabled]: disabled })}>
        <div className={styles.name}>
          <span>{entity.name}</span>
          {entityColumns.length > 0 && (
            <span className={styles.info}>
              <Popover
                arrowPointAtCenter
                content={
                  <div className={styles.content}>
                    <div className={styles.title}>{intl.formatMessage(messages.pinnedFields)}</div>
                    <div className={styles.body}>
                      <FieldGrid
                        className={styles.grid}
                        columns={entityColumns}
                        entity={entity}
                        showEmpty={false}
                      />
                    </div>
                  </div>
                }
                destroyTooltipOnHide
                mouseLeaveDelay={0}
                placement="rightTop"
                // modal has TOPMOST_MODAL_ZINDEX, so we need to override popover's z-index to make it appear over the modal
                zIndex={TOPMOST_MODAL_ZINDEX}
              >
                <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
              </Popover>
            </span>
          )}
        </div>
        {("user" in entity || primary) && (
          <span className={styles.infoText}>
            {primary && (
              <Tag
                className={styles.tag}
                icon={<FontAwesomeIcon className={styles.star} icon={faStar} />}
              >
                {intl.formatMessage(messages.primary)}
              </Tag>
            )}
            {"user" in entity && (
              <Avatar key={`${entity.id}_${entity.user.id}`} size={16} user={entity.user} />
            )}
            {info}
          </span>
        )}
      </div>

      {primary ? (
        <Tooltip title={intl.formatMessage(messages.replacePrimaryTooltip)}>
          <Button danger onClick={handleUnsetPrimaryCompany} type="link">
            {intl.formatMessage(messages.unsetPrimary)}
          </Button>
        </Tooltip>
      ) : selected ? (
        <div onClick={stopEvents}>
          <Popconfirm
            cancelText={intl.formatMessage(messages.no)}
            okText={intl.formatMessage(messages.yes)}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onOpenChange={handleOpenChange}
            open={popconfirmOpen}
            title={intl.formatMessage(messages.replacePrimary, {
              b: (text) => <b>{text}</b>,
              companyName: primaryCompanyName,
            })}
          >
            <Tooltip title={intl.formatMessage(messages.setPrimaryTooltip)}>
              <Button
                className={cn(styles.setPrimary, { [styles.buttonVisible]: popconfirmOpen })}
                type="link"
              >
                {intl.formatMessage(messages.setPrimary)}
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  columns: getRecordPreviewConfigurationColumns(state),
});

export default connect(mapStateToProps)(AssociationRowWithPrimaryEntity);
