enum FieldFeature {
  // normally all fields without ADDRESS or CUSTOM_FIELD feature are considered standard
  // but sometimes we may need to specify that explicitly, to guarantee field will fall
  // into standard fields even if it has ADDRESS or CUSTOM_FIELD features
  STANDARD = "standard",
  ADDRESS = "address",
  ENGAGEMENT = "engagement",
  ALWAYS_VISIBLE = "alwaysVisible",
  CUSTOM_FIELD = "customField",
  FILTERABLE = "filterable",
  FILTERABLE_ON_MAP = "filterableOnMap", // it is allowed to filter by this field on the map page
  // Fields display attributes for map records list and annotations
  RECORD_PREVIEW_VISIBLE_BY_DEFAULT = "recordPreviewVisibleByDefault", // field to be displayed by default on record preview
  MAP_PINNED_FIELD = "mapPinnedField", // field to be displayed on top (and prevent to be hidden)
  // BULK_EDIT used to mark fields available for bulk editing
  BULK_EDIT = "bulkEdit",
  CHECK_IN = "checkIn",
  // this is different from FieldType.NUMBER, as we can have a field of other type
  // which should behave as a numeric field. Look at the monetary custom field type.
  NUMERIC = "numeric",
  SORTABLE = "sortable",
  WITH_CHECKBOX_SELECTION = "withCheckboxSelection",
  // SUPPORTS_SET_FILTER is needed because of the special handling when converting platform filter model
  // back into our filter model. See /src/util/viewModel/convertFromPlatformFilterModel.ts
  SUPPORTS_SET_FILTER = "supportsSetFilter",
  // REGION_FIELD used for region
  REGION_FIELD = "regionField",
  FUNNEL_FIELD = "funnelField",
  // GROUP_FIELD is needed because of the special handling when converting our filter model into
  // platform filter model. See /src/util/viewModel/convertToPlatformFilterModel.ts
  GROUP_FIELD = "groupField",
  FILE_FIELD = "fileField",
  // EXTERNAL_URL_FIELD is needed to display external links
  EXTERNAL_URL_FIELD = "EXTERNAL_URL_FIELD",
  // GEO_POINT_FIELD is needed for the same purpose as a GROUP_FIELD feature
  GEO_POINT_FIELD = "geoPointField",
  SOURCE_FIELD = "sourceField",
  STAGE_FIELD = "stageField",
  // TEAM_FIELD is needed for the same purpose as a GROUP_FIELD feature
  TEAM_FIELD = "teamField",
  // TERRITORY_FIELD is needed for the same purpose as a GROUP_FIELD feature
  TERRITORY_FIELD = "territoryField",
  // OWNER_FIELD is needed for the same purpose as a GROUP_FIELD feature
  OWNER_FIELD = "ownerField",
  // ACTIVITY_TYPE_FIELD is needed for the same purpose as a GROUP_FIELD feature
  ACTIVITY_TYPE_FIELD = "activityTypeField",
  // CADENCE_STATUS_FIELD is needed for the same purpose as a GROUP_FIELD feature
  FREQUENCY_STATUS_FIELD = "frequencyStatusField",
  // ROTTING_DAYS_OUT_FIELD is needed for the same purpose as a GROUP_FIELD feature
  ROTTING_DAYS_OUT_FIELD = "rottingDaysOutField",
  // NO_CONTACT_FIELD is needed for the same purpose as a GROUP_FIELD feature
  NO_CONTACT_FIELD = "noContactField",
  // NO_CHECK_IN_FIELD is needed filtering purposes in FilterButton, it's not a real field
  NO_CHECK_IN_FIELD = "noCheckInField",
  // VIEW_AS_FIELD is needed filtering purposes in FilterButton, it's not a real field
  VIEW_AS_FIELD = "viewAsField",
  // fields that are displayed when user opens list view for the very first time
  VISIBLE_BY_DEFAULT = "visibleByDefault",
  // used to mark fields which are rendered along with other related field
  FORM_RENDERED_WITH_OTHER_FIELD = "formRenderedWithOtherField",
  // used to mark fields which are not allowed to be added manually into forms
  NON_ADDABLE_FORM_FIELD = "nonAddableFormField",
  // used to mark fields which are not supported in integrations
  NON_INTEGRATION = "nonIntegration",
  // used to mark fields which shouldn't be on list grid
  NON_LIST_VIEW = "nonListView",
  // used to mark fields which shouldn't be on map annotations and entity records
  NON_MAP_VIEW = "nonMapView",
  // used to mark fields which are not supported in imports
  NON_IMPORT = "nonImport",
  // used to mark fields which shouldn't be on export grid
  NON_EXPORT_VIEW = "nonExportView",
  // uses org colors, e.g. in color field filter
  USES_COLORS = "usesColors",
  // use this to put field to relationships category
  RELATIONSHIPS = "relationships",
  // to mark field as metric
  METRIC = "metric",
  // use this to use field as bulk address field. Generally used for field with name === 'address' for all addressed entities
  BULK_ADDRESS = "bulkAddress",
  // to mark country field
  COUNTRY_FIELD = "countryField",
  // to mark color
  COLOR_FIELD = "colorField",
  // to check if integration sync direction is incoming
  INCOMING = "incoming",
  // to check if a integration sync direction is outgoing
  OUTGOING = "outgoing",
  // this field participates in data view
  DATA_VIEW = "dataView",
  // this field is monetary or the value represent some amount of money
  MONETARY_VALUE = "monetaryValue",
  // this field is route
  ROUTE_FIELD = "routeField",
  // field can be used for filtering in entity history view
  HISTORY_FIELD = "historyField",
  // to mark regular fields as required for categorization
  REQUIRED = "required",
  // to mark regular fields as details for categorization
  DETAILS = "details",
  // to mark regular fields as Parent Company Address Field for categorization
  PARENT_COMPANY_ADDRESS_FIELD = "parentCompanyAddressField",
  // to mark regular fields as Company Address Field for categorization
  COMPANY_ADDRESS_FIELD = "companyAddressField",
  // to mark regular fields as People Address Field for categorization
  PEOPLE_ADDRESS_FIELD = "peopleAddressField",
  // to mark regular fields as Deal Address Field for categorization
  DEAL_ADDRESS_FIELD = "dealAddressField",
  // to mark field as calculated (For custom fields)
  CALCULATED_FIELD = "calculatedField",
  // to mark field as frequency interval
  FREQUENCY_INTERVAL = "frequencyInterval",
  // this field can be used as email dynamic variable
  EMAIL_DYNAMIC_VAR = "emailDynamicVar",
}

export default FieldFeature;
