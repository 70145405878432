import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import {
  EntitiesSupportingRoutes,
  EntityTypesSupportingRoutes,
  Route,
} from "@mapmycustomers/shared/types/entity";
import { RoutePayload } from "@mapmycustomers/shared/types/entity/Route";

export const initialize = createAsyncAction(
  "@component/addToRouteModal/initialize/request",
  "@component/addToRouteModal/initialize/success",
  "@component/addToRouteModal/initialize/failure"
)<
  {
    autoAssignOnCreate?: boolean;
    entities: EntitiesSupportingRoutes[];
    entityType: EntityTypesSupportingRoutes;
  },
  {
    routes: Route[];
  },
  void
>();

export const addToRoute = createAsyncAction(
  "@component/addToRouteModal/addToRoute/request",
  "@component/addToRouteModal/addToRoute/success",
  "@component/addToRouteModal/addToRoute/failure"
)<
  {
    callback: (success: boolean) => void;
    entities: EntitiesSupportingRoutes[];
    entityType: EntityTypesSupportingRoutes;
    routeId?: Route["id"];
    showNotification?: boolean;
  },
  void,
  void
>();

export const createRoute = createAsyncAction(
  "@component/addToRouteModal/createRoute/request",
  "@component/addToRouteModal/createRoute/success",
  "@component/addToRouteModal/createRoute/failure"
)<
  {
    callback?: (route: Route) => void;
    route: RoutePayload;
  },
  Route,
  void
>();

export const filterRoutes = createAsyncAction(
  "@component/addToRouteModal/filterRoutes/request",
  "@component/addToRouteModal/filterRoutes/success",
  "@component/addToRouteModal/filterRoutes/failure"
)<
  {
    entityType: EntityTypesSupportingRoutes;
    query: string;
  },
  Route[],
  void
>();

export const selectRoute = createAction("@component/addToRouteModal/selectRoute")<
  Route["id"] | undefined
>();

export const showCreateRouteModal = createAction(
  "@component/addToRouteModal/showCreateRouteModal"
)<void>();
export const hideCreateRouteModal = createAction(
  "@component/addToRouteModal/hideCreateRouteModal"
)<void>();

export type Actions = ActionType<
  | typeof addToRoute
  | typeof createRoute
  | typeof filterRoutes
  | typeof hideCreateRouteModal
  | typeof initialize
  | typeof selectRoute
  | typeof showCreateRouteModal
>;
