import compact from "lodash-es/compact";
import { createReducer, getType } from "typesafe-actions";

import { RawFile } from "@mapmycustomers/shared/types/File";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { fileCreatedAtDescComparator } from "@app/component/preview/util/comparators";
import localSettings from "@app/config/LocalSettings";
import FileListItem from "@app/types/FileListItem";
import tripFieldModel from "@app/util/fieldModel/tripFieldModel";

import TripStats from "../../components/MileageTracking/types/TripsStats";

import {
  applyTripListViewSettings,
  clearAllUploadedTripFiles,
  deleteTrip,
  deleteTripFile,
  deleteTrips,
  fetchTripList,
  fetchTripPreviewData,
  initializeTripListView,
  MileageTrackingActions,
  updateTrip,
  uploadTripFiles,
} from "./actions";
import TripRecordData from "./TripRecordData";

const INITIAL_RECORD_DATA: Readonly<TripRecordData> = {
  files: [],
  trip: undefined,
};

export interface MileageTrackingState extends ViewState {
  deleteLoading: boolean;
  deletingTrips: boolean;
  error: undefined | unknown;
  filesAdded: FileListItem[];
  filesToDeletedIds: RawFile["id"][];
  filesUploading: boolean;
  firstDateWithData: Date | undefined;
  initialized: boolean;
  loading: boolean;
  previewData: TripRecordData;
  previewLoading: boolean;
  stats: TripStats;
  totalFilteredRecords: number;
  totalRecords: number;
}

export const mileageTrackingInitialState: MileageTrackingState = {
  ...tripFieldModel.getDefaultListViewState(),
  deleteLoading: false,
  deletingTrips: false,
  error: undefined,
  filesAdded: [],
  filesToDeletedIds: [],
  filesUploading: false,
  firstDateWithData: undefined,
  initialized: false,
  // loading is true by default to avoid flashing a "no rows" overlay when the page
  // is just opened but loading is not yet started
  loading: true,
  previewData: INITIAL_RECORD_DATA,
  previewLoading: false,
  stats: {
    parkingFee: 0,
    tollFee: 0,
    totalCount: 0,
    totalDistance: 0,
    value: 0,
  },
  totalFilteredRecords: 0,
  totalRecords: 0,
};

const mileageTracking = createReducer<MileageTrackingState, MileageTrackingActions>(
  mileageTrackingInitialState
)
  .handleType(getType(initializeTripListView.request), (state) => ({
    ...state,
    initialized: false,
  }))
  .handleType(getType(initializeTripListView.success), (state, { payload }) => ({
    ...state,
    ...payload,
    ...localSettings.getViewSettings("mileageTracking/listView", tripFieldModel),
    initialized: true,
  }))
  .handleType(getType(applyTripListViewSettings), (state, { payload }) => ({
    ...state,
    columns: payload.columns ?? state.columns,
    filter: payload.filter ?? state.filter,
    range: payload.range ?? state.range,
    // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
    selectedSavedFilterId:
      "selectedSavedFilterId" in payload
        ? payload.selectedSavedFilterId
        : state.selectedSavedFilterId,
    sort: payload.sort ?? state.sort,
    // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
    viewAs: "viewAs" in payload ? payload.viewAs : state.viewAs,
  }))
  .handleType(getType(fetchTripList.request), (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleType(getType(fetchTripList.success), (state, { payload }) => ({
    ...state,
    loading: false,
    stats: payload.stats,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleType(getType(fetchTripList.failure), (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
  }))
  .handleType(getType(deleteTrips.request), (state) => ({ ...state, deletingTrips: true }))
  .handleType(getType(deleteTrips.success), (state) => ({ ...state, deletingTrips: false }))
  .handleType(getType(deleteTrips.failure), (state) => ({ ...state, deletingTrips: false }))
  .handleType(getType(fetchTripPreviewData.request), (state) => ({
    ...state,
    previewData: INITIAL_RECORD_DATA,
    // hasChanges: false,
    previewLoading: true,
  }))
  .handleType(getType(fetchTripPreviewData.success), (state, { payload }) => ({
    ...state,
    previewData: payload,
    // hasChanges: false,
    previewLoading: false,
  }))
  .handleType(getType(fetchTripPreviewData.failure), (state) => ({
    ...state,
    previewLoading: false,
  }))
  .handleType(getType(uploadTripFiles.request), (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleType(getType(uploadTripFiles.success), (state, action) => {
    const filesUploaded = compact(action.payload.map(({ uploadedFile }) => uploadedFile));
    return {
      ...state,
      filesAdded: [...state.filesAdded, ...action.payload],
      filesUploading: false,
      previewData: {
        ...state.previewData,
        files: [...filesUploaded, ...state.previewData.files].sort(fileCreatedAtDescComparator),
      },
    };
  })
  .handleType(getType(uploadTripFiles.failure), (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleType(getType(deleteTripFile.request), (state, { payload }) => ({
    ...state,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== payload.id),
    filesToDeletedIds: [...state.filesToDeletedIds, payload.id],
    previewData: {
      ...state.previewData,
      files: state.previewData.files.filter((file) => file.id !== payload.id),
    },
  }))
  .handleType(getType(deleteTripFile.success), (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.file.id),
    recordData: payload.removed
      ? state.previewData
      : {
          ...state.previewData,
          files: [...state.previewData.files, payload.file].sort(fileCreatedAtDescComparator),
        },
  }))
  .handleType(getType(deleteTripFile.failure), (state, { payload }) => ({
    ...state,
    filesToDeletedIds: state.filesToDeletedIds.filter((id) => id !== payload.id),
    recordData: {
      ...state.previewData,
      files: [...state.previewData.files, payload].sort(fileCreatedAtDescComparator),
    },
  }))
  .handleType(getType(clearAllUploadedTripFiles), (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleType(getType(deleteTrip.request), (state) => ({
    ...state,
    deleteLoading: true,
  }))
  .handleType([getType(deleteTrip.success), getType(deleteTrip.failure)], (state) => ({
    ...state,
    deleteLoading: false,
  }))
  .handleType(getType(updateTrip.success), (state, { payload }) => ({
    ...state,
    previewData: {
      ...state.previewData,
      trip: payload,
    },
  }));

export default mileageTracking;
