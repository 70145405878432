import MarkerShape from "@mapmycustomers/shared/enum/MarkerShape";

import Size from "@app/types/map/Size";

const getSvgForShape = (
  shape: MarkerShape,
  fillColor: string,
  strokeColor: string
): {
  badgeAnchor: Size;
  badgeShapeIncreaser?: Size;
  shapeAnchor: Size;
  shapeSize: Size;
  shapeSvg: string;
} => {
  switch (shape) {
    case MarkerShape.BOOKMARK: {
      const size = [42, 54] as Size;
      return {
        badgeAnchor: [20, -12],
        badgeShapeIncreaser: [8, 12],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon
        shapeSize: size,
        shapeSvg: `
          <path d="M36 5.75V42L21 33.25L6 42V5.75C6 3.67969 7.67969 2 9.75 2H32.25C34.3203 2 36 3.67969 36 5.75Z" fill="${fillColor}"/>
          <path d="M35.4961 42.8638L37 43.741V42V5.75C37 3.1274 34.8726 1 32.25 1H9.75C7.1274 1 5 3.1274 5 5.75V42V43.741L6.50387 42.8638L21 34.4077L35.4961 42.8638Z" stroke="${strokeColor}" stroke-width="3"/>
        `,
      };
    }
    case MarkerShape.CIRCLE: {
      const size = [47, 47] as Size;
      return {
        badgeAnchor: [24, -12],
        badgeShapeIncreaser: [10, 10],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon
        shapeSize: size,
        shapeSvg: `
          <path d="M23.5 1C13.2804 1 5 9.28038 5 19.5C5 29.7196 13.2804 38 23.5 38C33.7196 38 42 29.7196 42 19.5C42 9.28038 33.7196 1 23.5 1Z" fill="${fillColor}" stroke="${strokeColor}" stroke-width="3"/>
        `,
      };
    }
    case MarkerShape.FLAG: {
      const size = [52, 67] as Size;
      return {
        badgeAnchor: [30, -8],
        badgeShapeIncreaser: [8, 8],
        shapeAnchor: [size[0] * 0.15, size[1]], // near left bottom corner of flag icon
        shapeSize: size,
        shapeSvg: `
          <path d="M33.1083 10.7603C28.8554 10.7603 25.3429 7.99975 19.9959 7.99975C18.0153 7.99975 16.2412 8.34768 14.5955 8.95316C14.8236 8.3575 14.9206 7.7197 14.8801 7.08316C14.7394 4.82633 12.8894 3.0161 10.63 2.92419C8.09087 2.82086 6 4.84864 6 7.36483C6 8.87483 6.75373 10.2081 7.90476 11.0113V54.9839C7.90476 56.0358 8.75754 56.8886 9.80952 56.8886H11.0794C12.1313 56.8886 12.9841 56.0358 12.9841 54.9839V34.1585C15.231 33.201 18.0303 32.4028 22.0663 32.4028C26.3193 32.4028 29.8317 35.1633 35.1787 35.1633C39.0017 35.1633 42.0571 33.8701 44.9013 31.9206C45.5905 31.4483 46 30.6641 46 29.8287V10.5351C46 8.67848 44.0739 7.45014 42.3901 8.23221C39.6648 9.49792 36.3223 10.7603 33.1083 10.7603Z" fill="${fillColor}"/>
          <path d="M27.5861 8.53363C29.5755 9.19589 31.271 9.7603 33.1083 9.7603C36.0925 9.7603 39.2682 8.57955 41.9688 7.32526C44.315 6.23555 47 7.94715 47 10.5351V29.8287C47 30.9909 46.4304 32.085 45.4667 32.7455C42.5058 34.7749 39.2565 36.1633 35.1787 36.1633C32.3309 36.1633 29.9831 35.4248 27.8971 34.7321C27.7934 34.6976 27.6906 34.6634 27.5885 34.6294C25.5991 33.9672 23.9036 33.4028 22.0663 33.4028C18.5389 33.4028 16.0169 34.0329 13.9841 34.8296V54.9839C13.9841 56.5881 12.6836 57.8886 11.0794 57.8886H9.80952C8.20526 57.8886 6.90476 56.5881 6.90476 54.9839V11.5002C5.74086 10.5032 5 9.02114 5 7.36483C5 4.28271 7.56146 1.7985 10.6706 1.92502L27.5861 8.53363ZM27.5861 8.53363C27.4841 8.49965 27.3812 8.46542 27.2776 8.43099C25.1915 7.73822 22.8437 6.99975 19.9959 6.99975C18.5319 6.99975 17.1706 7.17924 15.8872 7.50587M27.5861 8.53363L15.8872 7.50587M15.8872 7.50587C15.8914 7.34426 15.8884 7.18224 15.8781 7.02028C15.7053 4.25215 13.4418 2.03775 10.6707 1.92502L15.8872 7.50587Z" stroke="${strokeColor}" stroke-width="3" stroke-linejoin="round" stroke-linecap="round"/>
        `,
      };
    }
    case MarkerShape.HEART: {
      const size = [47, 42] as Size;
      return {
        badgeAnchor: [24, -10],
        badgeShapeIncreaser: [9, 9],
        shapeAnchor: [size[0] / 2, size[1]], // bottom center of heart icon
        shapeSize: size,
        shapeSvg: `
          <path d="M6 12.1683V11.7718C6 6.99349 9.45352 2.91791 14.1621 2.13314C17.2178 1.61429 20.4512 2.63011 22.6797 4.86205L23.5 5.68099L24.2588 4.86205C26.5488 2.63011 29.7207 1.61429 32.8379 2.13314C37.5479 2.91791 41 6.99349 41 11.7718V12.1683C41 15.0052 39.8242 17.7191 37.7461 19.6537L25.3936 31.1859C24.8809 31.6644 24.2041 31.931 23.5 31.931C22.7959 31.931 22.1191 31.6644 21.6064 31.1859L9.25322 19.6537C7.17783 17.7191 6.00002 15.0052 6.00002 12.1683H6Z" fill="${fillColor}"/>
          <path d="M5 13.1683V12.1683V11.7718C5 6.50532 8.80604 2.01278 13.9962 1.147C17.3704 0.57458 20.9294 1.69386 23.3872 4.15534C23.3872 4.15539 23.3873 4.15544 23.3873 4.15548L23.4719 4.23994L23.5253 4.1824L23.5426 4.1637L23.5608 4.14591C26.0738 1.6967 29.564 0.57445 33.0021 1.14671L33.0022 1.14674C38.1947 2.0119 42 6.50493 42 11.7718V12.1683C42 15.2814 40.7099 18.2606 38.4279 20.3852C38.4278 20.3853 38.4276 20.3854 38.4275 20.3856L26.076 31.9168L26.0759 31.9169C25.3775 32.5687 24.4567 32.931 23.5 32.931C22.5433 32.931 21.6225 32.5687 20.9241 31.9169L20.924 31.9169L8.57137 20.3851L5 13.1683ZM5 13.1683H5.04458M5 13.1683H5.04458M5.04458 13.1683C5.28973 15.9133 6.53771 18.4892 8.57082 20.3846L5.04458 13.1683Z" stroke="${strokeColor}" stroke-width="3" stroke-linejoin="round" stroke-linecap="round"/>
        `,
      };
    }
    case MarkerShape.HEXAGON: {
      const size = [47, 44] as Size;
      return {
        badgeAnchor: [24, -12],
        badgeShapeIncreaser: [10, 12],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon
        shapeSize: size,
        shapeSvg: `
          <path d="M29.5089 2C31.2622 2 32.8968 2.9151 33.8049 4.41419L40.2665 15.0349C41.2445 16.6416 41.2445 18.6534 40.2665 20.2601L33.8049 30.878C32.8968 32.3799 31.2622 33.295 29.5089 33.295H17.431C15.7405 33.295 14.1059 32.3799 13.1977 30.878L6.73337 20.2601C5.75554 18.6534 5.75554 16.6416 6.73337 15.0349L13.1977 4.41419C14.1059 2.9151 15.7405 2 17.431 2H29.5089Z" fill="${fillColor}"/>
          <path d="M5.87914 20.78L5.87922 20.7801L12.342 31.3954C12.3423 31.3959 12.3426 31.3964 12.3429 31.3968C13.4299 33.1935 15.3864 34.295 17.431 34.295H29.5089C31.6093 34.295 33.5692 33.1995 34.6598 31.3968C34.6601 31.3964 34.6604 31.3959 34.6607 31.3954L41.1207 20.78C42.2931 18.854 42.2931 16.441 41.1207 14.515L34.6602 3.89606C34.6601 3.89584 34.66 3.89563 34.6599 3.89541C33.5692 2.09555 31.6094 1 29.5089 1H17.431C15.3865 1 13.4301 2.10139 12.343 3.89507C12.3428 3.8954 12.3426 3.89573 12.3424 3.89606L5.87916 14.515L5.87914 14.515C4.70695 16.441 4.70695 18.854 5.87914 20.78Z" stroke="${strokeColor}" stroke-width="3" stroke-linejoin="round" stroke-linecap="round"/>
        `,
      };
    }
    case MarkerShape.LEAD: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
          <g filter="url(#shadow)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="white" stroke-linejoin="round" stroke-linecap="round"/>
            <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
          <path d="M27 13C23.6855 13 21 15.6855 21 19C21 22.3145 23.6855 25 27 25C30.3145 25 33 22.3145 33 19C33 15.6855 30.3145 13 27 13ZM30.4839 19.6774C30.4839 19.8371 30.3532 19.9677 30.1935 19.9677H27.9677V22.1935C27.9677 22.3532 27.8371 22.4839 27.6774 22.4839H26.3226C26.1629 22.4839 26.0323 22.3532 26.0323 22.1935V19.9677H23.8065C23.6468 19.9677 23.5161 19.8371 23.5161 19.6774V18.3226C23.5161 18.1629 23.6468 18.0323 23.8065 18.0323H26.0323V15.8065C26.0323 15.6468 26.1629 15.5161 26.3226 15.5161H27.6774C27.8371 15.5161 27.9677 15.6468 27.9677 15.8065V18.0323H30.1935C30.3532 18.0323 30.4839 18.1629 30.4839 18.3226V19.6774Z" fill="url(#gradient)" stroke-linejoin="round" stroke-linecap="round"/>
          <defs>
            <filter id="shadow" x="0" y="0" width="54" height="50.6682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="r1"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="6"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="r1" result="r2"/>
              <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
            </filter>
            <linearGradient id="gradient" x1="21" y1="-3.7213" x2="36.3558" y2="-2.1507" gradientUnits="userSpaceOnUse">
              <stop stop-color="#24B275"/>
              <stop offset="0.557292" stop-color="#3063E4"/>
              <stop offset="0.979167" stop-color="#F0AE00"/>
            </linearGradient>
          </defs>`,
      };
    }
    case MarkerShape.LEAD_CLICKED: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
          <g filter="url(#shadow)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="#EAEFFC" stroke-linejoin="round" stroke-linecap="round"/>
            <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
          <path d="M27 13C23.6855 13 21 15.6855 21 19C21 22.3145 23.6855 25 27 25C30.3145 25 33 22.3145 33 19C33 15.6855 30.3145 13 27 13ZM30.4839 19.6774C30.4839 19.8371 30.3532 19.9677 30.1935 19.9677H27.9677V22.1935C27.9677 22.3532 27.8371 22.4839 27.6774 22.4839H26.3226C26.1629 22.4839 26.0323 22.3532 26.0323 22.1935V19.9677H23.8065C23.6468 19.9677 23.5161 19.8371 23.5161 19.6774V18.3226C23.5161 18.1629 23.6468 18.0323 23.8065 18.0323H26.0323V15.8065C26.0323 15.6468 26.1629 15.5161 26.3226 15.5161H27.6774C27.8371 15.5161 27.9677 15.6468 27.9677 15.8065V18.0323H30.1935C30.3532 18.0323 30.4839 18.1629 30.4839 18.3226V19.6774Z" fill="url(#gradient)" stroke-linejoin="round" stroke-linecap="round"/>
          <defs>
            <filter id="shadow" x="0" y="0" width="54" height="50.6682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="r1"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="6"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="r1" result="r2"/>
              <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
            </filter>
            <linearGradient id="gradient" x1="21" y1="-3.7213" x2="36.3558" y2="-2.1507" gradientUnits="userSpaceOnUse">
              <stop stop-color="#24B275"/>
              <stop offset="0.557292" stop-color="#3063E4"/>
              <stop offset="0.979167" stop-color="#F0AE00"/>
            </linearGradient>
          </defs>`,
      };
    }
    case MarkerShape.LEAD_DUPLICATE: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
          <g filter="url(#shadow)">
            <mask id="clip" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" stroke-linejoin="round" stroke-linecap="round"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="white" stroke-linejoin="round" stroke-linecap="round"/>
            <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" mask="url(#clip)" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
          <path d="M32.8649 22.4534C33.2495 23.1408 32.7668 24 31.9988 24H22.0011C21.2316 24 20.7512 23.1394 21.1349 22.4534L26.1338 13.5153C26.5185 12.8276 27.4821 12.8289 27.8662 13.5153L32.8649 22.4534ZM27 20.6055C26.4707 20.6055 26.0417 21.0479 26.0417 21.5938C26.0417 22.1396 26.4707 22.582 27 22.582C27.5293 22.582 27.9583 22.1396 27.9583 21.5938C27.9583 21.0479 27.5293 20.6055 27 20.6055ZM26.0902 17.0531L26.2447 19.975C26.2519 20.1117 26.3615 20.2188 26.4943 20.2188H27.5057C27.6385 20.2188 27.7481 20.1117 27.7553 19.975L27.9099 17.0531C27.9177 16.9054 27.8036 16.7812 27.6602 16.7812H26.3398C26.1963 16.7812 26.0823 16.9054 26.0902 17.0531Z" fill="#F0AE00" stroke-linejoin="round" stroke-linecap="round"/>
          <defs>
            <filter id="shadow" x="0" y="0" width="54" height="50.6682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="r1"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="6"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="r1" result="r2"/>
              <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
            </filter>
          </defs>`,
      };
    }
    case MarkerShape.LEAD_DUPLICATE_CLICKED: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
          <g filter="url(#shadow)">
            <mask id="clip" fill="white">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" stroke-linejoin="round" stroke-linecap="round"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="#EAEFFC" stroke-linejoin="round" stroke-linecap="round"/>
            <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" mask="url(#clip)" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
          <path d="M32.8649 22.4534C33.2495 23.1408 32.7668 24 31.9988 24H22.0011C21.2316 24 20.7512 23.1394 21.1349 22.4534L26.1338 13.5153C26.5185 12.8276 27.4821 12.8289 27.8662 13.5153L32.8649 22.4534ZM27 20.6055C26.4707 20.6055 26.0417 21.0479 26.0417 21.5938C26.0417 22.1396 26.4707 22.582 27 22.582C27.5293 22.582 27.9583 22.1396 27.9583 21.5938C27.9583 21.0479 27.5293 20.6055 27 20.6055ZM26.0902 17.0531L26.2447 19.975C26.2519 20.1117 26.3615 20.2188 26.4943 20.2188H27.5057C27.6385 20.2188 27.7481 20.1117 27.7553 19.975L27.9099 17.0531C27.9177 16.9054 27.8036 16.7812 27.6602 16.7812H26.3398C26.1963 16.7812 26.0823 16.9054 26.0902 17.0531Z" fill="#F0AE00" stroke-linejoin="round" stroke-linecap="round"/>
          <defs>
            <filter id="shadow" x="0" y="0" width="54" height="50.6682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="r1"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="6"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="r1" result="r2"/>
              <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
            </filter>
          </defs>`,
      };
    }
    case MarkerShape.LEAD_HIDDEN: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
        <g filter="url(#filter0_d_613_8370)">
        <mask id="path-1-inside-1_613_8370" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="white"/>
        <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" mask="url(#path-1-inside-1_613_8370)"/>
        </g>
        <path d="M26.7194 21.1871L27.483 22.278C27.2393 22.3009 26.9947 22.3125 26.75 22.3125C23.942 22.3125 21.4773 20.8137 20.1558 18.5712C20.0538 18.3981 20 18.2009 20 18C20 17.7991 20.0538 17.6019 20.1558 17.4288C20.7188 16.4734 21.4897 15.6529 22.4063 15.0256L23.7441 16.9367C23.6266 17.2692 23.5625 17.627 23.5625 18C23.5625 19.7516 24.9719 21.1707 26.7194 21.1871ZM33.3443 18.5712C32.6021 19.8306 31.4986 20.8554 30.1761 21.512L30.1764 21.5123L31.1483 22.8998C31.3264 23.1544 31.2646 23.505 31.01 23.6831L30.7028 23.8983C30.4482 24.0764 30.0976 24.0145 29.9195 23.76L22.3517 13.1002C22.1736 12.8456 22.2355 12.495 22.49 12.3169L22.7973 12.1017C23.0518 11.9236 23.4024 11.9855 23.5806 12.24L24.7768 13.942C25.4066 13.776 26.0681 13.6875 26.75 13.6875C29.5581 13.6875 32.0228 15.1863 33.3443 17.4289C33.4463 17.6019 33.5 17.7991 33.5 18C33.5 18.2009 33.4463 18.3981 33.3443 18.5712ZM29.9375 18C29.9375 16.2381 28.5116 14.8125 26.75 14.8125C26.3296 14.8125 25.9284 14.8939 25.5611 15.0415L26.0134 15.6757C26.6197 15.4842 27.3035 15.5313 27.8978 15.8494H27.8971C27.3428 15.8494 26.8935 16.2987 26.8935 16.853C26.8935 17.4067 27.3422 17.8566 27.8971 17.8566C28.4514 17.8566 28.9007 17.4073 28.9007 16.853V16.8523C29.3334 17.6606 29.283 18.6784 28.7073 19.4527V19.4529L29.16 20.0872C29.6445 19.5281 29.9375 18.7986 29.9375 18ZM26.141 20.3608L24.3239 17.7649C24.2063 18.9928 25.0109 20.0701 26.141 20.3608Z" fill="#677788"/>
        <defs>
        <filter id="filter0_d_613_8370" x="0" y="0" width="54" height="50.668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="6"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_613_8370"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_613_8370" result="shape"/>
        </filter>
        </defs>
        `,
      };
    }
    case MarkerShape.LEAD_HIDDEN_CLICKED: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
        <g filter="url(#filter0_d_769_21802)">
        <mask id="path-1-inside-1_769_21802" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.17 15.9233 27.4236 20.9551 27.9461L25.4004 33.8688C26.2005 34.9347 27.7996 34.9347 28.5996 33.8688L33.0449 27.9461C38.0767 27.4236 42 23.17 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="#EAEFFC"/>
        <path d="M20.9551 27.9461L21.355 27.6459L21.2239 27.4713L21.0067 27.4487L20.9551 27.9461ZM25.4004 33.8688L25.0005 34.169H25.0005L25.4004 33.8688ZM28.5996 33.8688L28.1997 33.5687L28.5996 33.8688ZM33.0449 27.9461L32.9933 27.4487L32.7761 27.4713L32.645 27.6459L33.0449 27.9461ZM12.5 18C12.5 12.7533 16.7533 8.5 22 8.5V7.5C16.201 7.5 11.5 12.201 11.5 18H12.5ZM21.0067 27.4487C16.2271 26.9525 12.5 22.9112 12.5 18H11.5C11.5 23.4288 15.6195 27.8948 20.9034 28.4434L21.0067 27.4487ZM20.5552 28.2462L25.0005 34.169L25.8003 33.5687L21.355 27.6459L20.5552 28.2462ZM25.0005 34.169C26.0006 35.5013 27.9994 35.5013 28.9995 34.169L28.1997 33.5687C27.5997 34.3681 26.4003 34.3681 25.8003 33.5687L25.0005 34.169ZM28.9995 34.169L33.4448 28.2462L32.645 27.6459L28.1997 33.5687L28.9995 34.169ZM41.5 18C41.5 22.9112 37.7729 26.9525 32.9933 27.4487L33.0966 28.4434C38.3805 27.8948 42.5 23.4288 42.5 18H41.5ZM32 8.5C37.2467 8.5 41.5 12.7533 41.5 18H42.5C42.5 12.201 37.799 7.5 32 7.5V8.5ZM22 8.5H32V7.5H22V8.5Z" fill="#648AEB" mask="url(#path-1-inside-1_769_21802)"/>
        </g>
        <path d="M26.7194 21.1871L27.483 22.278C27.2393 22.3009 26.9947 22.3125 26.75 22.3125C23.942 22.3125 21.4773 20.8137 20.1558 18.5712C20.0538 18.3981 20 18.2009 20 18C20 17.7991 20.0538 17.6019 20.1558 17.4288C20.7188 16.4734 21.4897 15.6529 22.4063 15.0256L23.7441 16.9367C23.6266 17.2692 23.5625 17.627 23.5625 18C23.5625 19.7516 24.9719 21.1707 26.7194 21.1871ZM33.3443 18.5712C32.6021 19.8306 31.4986 20.8554 30.1761 21.512L30.1764 21.5123L31.1483 22.8998C31.3264 23.1544 31.2646 23.505 31.01 23.6831L30.7028 23.8983C30.4482 24.0764 30.0976 24.0145 29.9195 23.76L22.3517 13.1002C22.1736 12.8456 22.2355 12.495 22.49 12.3169L22.7973 12.1017C23.0518 11.9236 23.4024 11.9855 23.5806 12.24L24.7768 13.942C25.4066 13.776 26.0681 13.6875 26.75 13.6875C29.5581 13.6875 32.0228 15.1863 33.3443 17.4289C33.4463 17.6019 33.5 17.7991 33.5 18C33.5 18.2009 33.4463 18.3981 33.3443 18.5712ZM29.9375 18C29.9375 16.2381 28.5116 14.8125 26.75 14.8125C26.3296 14.8125 25.9284 14.8939 25.5611 15.0415L26.0134 15.6757C26.6197 15.4842 27.3035 15.5313 27.8978 15.8494H27.8971C27.3428 15.8494 26.8935 16.2987 26.8935 16.853C26.8935 17.4067 27.3422 17.8566 27.8971 17.8566C28.4514 17.8566 28.9007 17.4073 28.9007 16.853V16.8523C29.3334 17.6606 29.283 18.6784 28.7073 19.4527V19.4529L29.16 20.0872C29.6445 19.5281 29.9375 18.7986 29.9375 18ZM26.141 20.3608L24.3239 17.7649C24.2063 18.9928 25.0109 20.0701 26.141 20.3608Z" fill="#677788"/>
        <defs>
        <filter id="filter0_d_769_21802" x="0" y="0" width="54" height="50.668" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="6"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_769_21802"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_769_21802" result="shape"/>
        </filter>
        </defs>
        `,
      };
    }
    case MarkerShape.LEAD_SELECTED: {
      const size = [54, 51] as Size;
      return {
        badgeAnchor: size,
        shapeAnchor: [size[0] / 2, 36], // anchor point of icon
        shapeSize: size,
        shapeSvg: `
          <g filter="url(#shadow)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 8C16.4772 8 12 12.4772 12 18C12 23.1699 15.9232 27.4235 20.9548 27.946L25.4002 33.8688C26.2002 34.9347 27.7993 34.9347 28.5993 33.8688L33.0446 27.9461C38.0765 27.4238 42 23.1701 42 18C42 12.4772 37.5228 8 32 8H22Z" fill="#3063E4" stroke-linejoin="round" stroke-linecap="round"/>
            <path d="M20.9548 27.946L21.7546 27.3457L21.4924 26.9965L21.0581 26.9514L20.9548 27.946ZM25.4002 33.8688L24.6004 34.4691L25.4002 33.8688ZM28.5993 33.8688L27.7995 33.2685L27.7995 33.2685L28.5993 33.8688ZM33.0446 27.9461L32.9414 26.9514L32.507 26.9965L32.2449 27.3458L33.0446 27.9461ZM13 18C13 13.0294 17.0294 9 22 9V7C15.9249 7 11 11.9249 11 18H13ZM21.0581 26.9514C16.5307 26.4812 13 22.6523 13 18H11C11 23.6875 15.3156 28.3657 20.8515 28.9407L21.0581 26.9514ZM20.155 28.5463L24.6004 34.4691L26.2 33.2685L21.7546 27.3457L20.155 28.5463ZM24.6004 34.4691C25.8004 36.068 28.1991 36.068 29.3991 34.4691L27.7995 33.2685C27.3995 33.8015 26.6 33.8015 26.2 33.2685L24.6004 34.4691ZM29.3991 34.4691L33.8444 28.5464L32.2449 27.3458L27.7995 33.2685L29.3991 34.4691ZM41 18C41 22.6524 37.469 26.4815 32.9414 26.9514L33.1479 28.9407C38.6841 28.3661 43 23.6877 43 18H41ZM32 9C36.9706 9 41 13.0294 41 18H43C43 11.9249 38.0751 7 32 7V9ZM22 9H32V7H22V9Z" fill="url(#gradient)" stroke-linejoin="round" stroke-linecap="round"/>
          </g>
          <path d="M32.9995 19C32.9995 22.3137 30.3132 25 26.9995 25C23.6858 25 20.9995 22.3137 20.9995 19C20.9995 15.6863 23.6858 13 26.9995 13C30.3132 13 32.9995 15.6863 32.9995 19ZM26.3055 22.177L30.7571 17.7253C30.9083 17.5742 30.9083 17.3291 30.7571 17.1779L30.2097 16.6305C30.0585 16.4793 29.8134 16.4793 29.6622 16.6305L26.0318 20.2609L24.3368 18.5659C24.1856 18.4148 23.9405 18.4148 23.7893 18.5659L23.2419 19.1134C23.0908 19.2645 23.0908 19.5096 23.2419 19.6608L25.758 22.1769C25.9092 22.3281 26.1543 22.3281 26.3055 22.177V22.177Z" fill="white" stroke-linejoin="round" stroke-linecap="round"/>
          <defs>
            <filter id="shadow" x="0" y="0" width="54" height="50.6682" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="r1"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
              <feOffset dy="4"/>
              <feGaussianBlur stdDeviation="6"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
              <feBlend mode="normal" in2="r1" result="r2"/>
              <feBlend mode="normal" in="SourceGraphic" in2="r2" result="shape"/>
            </filter>
            <linearGradient id="gradient" x1="12" y1="-29.1606" x2="50.2842" y2="-24.7557" gradientUnits="userSpaceOnUse">
              <stop stop-color="#91D8BA"/>
              <stop offset="0.557292" stop-color="#97B1F1"/>
              <stop offset="0.979167" stop-color="#F7D780"/>
            </linearGradient>
          </defs>`,
      };
    }
    case MarkerShape.PIN: {
      const size = [47, 60] as Size;
      return {
        badgeAnchor: [25, -10],
        badgeShapeIncreaser: [8, 8],
        shapeAnchor: [size[0] / 2, size[1]], // bottom center of pin icon,
        shapeSize: size,
        shapeSvg: `
          <path d="M21.7015 48.3919C8.4582 29.193 6 27.2226 6 20.1667C6 10.5017 13.835 2.66675 23.5 2.66675C33.165 2.66675 41 10.5017 41 20.1667C41 27.2226 38.5418 29.193 25.2985 48.3919C24.4294 49.6473 22.5705 49.6472 21.7015 48.3919Z" fill="${fillColor}"/>
          <path d="M20.8784 48.9597L20.8793 48.961C22.1459 50.7908 24.854 50.791 26.1207 48.9611L26.1216 48.9597C28.4591 45.5711 30.4671 42.7103 32.1876 40.2592C35.3165 35.8016 37.4943 32.699 38.9724 30.2411C40.1306 28.3154 40.8973 26.7267 41.3678 25.1377C41.8406 23.5411 42 21.9932 42 20.1667C42 9.94945 33.7173 1.66675 23.5 1.66675C13.2827 1.66675 5 9.94945 5 20.1667C5 21.9932 5.15936 23.5411 5.63218 25.1377C6.10273 26.7267 6.86943 28.3154 8.02755 30.2411C9.50575 32.699 11.6835 35.8016 14.8124 40.2592C16.5329 42.7103 18.5409 45.5711 20.8784 48.9597Z" stroke="${strokeColor}" stroke-width="3" stroke-linejoin="round" stroke-linecap="round"/>
        `,
      };
    }
    case MarkerShape.SQUARE: {
      const size = [42, 42] as Size;
      return {
        badgeAnchor: [22, -14],
        badgeShapeIncreaser: [10, 10],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon
        shapeSize: size,
        shapeSvg: `
          <path d="M6 6.28571C6 3.91853 7.91853 2 10.2857 2H31.7143C34.0781 2 36 3.91853 36 6.28571V27.7143C36 30.0781 34.0781 32 31.7143 32H10.2857C7.91853 32 6 30.0781 6 27.7143V6.28571Z" fill="${fillColor}"/>
          <path d="M10.2857 1C7.36624 1 5 3.36624 5 6.28571V27.7143C5 30.6299 7.36573 33 10.2857 33H31.7143C34.6304 33 37 30.6304 37 27.7143V6.28571C37 3.36573 34.6299 1 31.7143 1H10.2857Z" stroke="${strokeColor}" stroke-width="3" stroke-linejoin="round"/>
        `,
      };
    }
    case MarkerShape.STAR: {
      const size = [52, 52] as Size;
      return {
        badgeAnchor: [22, -10],
        badgeShapeIncreaser: [8, 8],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon,
        shapeSize: size,
        shapeSvg: `
          <path d="M34.429 12.8413L46.6738 14.6521C47.6878 14.7973 48.5399 15.5148 48.8637 16.4971C49.1875 17.4879 48.9233 18.5642 48.1905 19.2988L39.3116 28.0282L41.4078 40.5502C41.5782 41.5752 41.1606 42.6172 40.3085 43.2237C39.465 43.8302 38.3487 43.907 37.437 43.4202L26.496 37.5692L15.5635 43.4202C14.6432 43.907 13.5269 43.8302 12.6834 43.2237C11.8398 42.6172 11.4137 41.5752 11.5927 40.5502L13.6888 28.0282L4.80822 19.2988C4.07456 18.5642 3.81382 17.4879 4.13591 16.4971C4.45716 15.5148 5.3067 14.7973 6.32838 14.6521L18.5629 12.8413L24.0504 1.53826C24.502 0.597321 25.4564 0 26.496 0C27.544 0 28.4984 0.597321 28.95 1.53826L34.429 12.8413Z" fill="${fillColor}"/>
          <path d="M33.5292 13.2775L33.7598 13.7532L34.2827 13.8306L46.5275 15.6414L46.532 15.642C47.1653 15.7327 47.7069 16.1839 47.9134 16.8087C48.118 17.436 47.9534 18.1184 47.4854 18.5896C47.4845 18.5906 47.4835 18.5915 47.4825 18.5925L38.6105 27.3152L38.2394 27.68L38.3253 28.1933L40.4213 40.7142C40.5306 41.3717 40.2614 42.0298 39.7287 42.409L39.7248 42.4117C39.1881 42.7976 38.4807 42.8439 37.908 42.538L26.9675 36.6874L26.4958 36.4351L26.0241 36.6875L15.0958 42.5362C15.0951 42.5366 15.0944 42.537 15.0937 42.5373C14.5095 42.8454 13.8016 42.796 13.2671 42.4117C12.7346 42.0289 12.4648 41.369 12.5778 40.7222L12.5789 40.7153L14.6751 28.1933L14.7611 27.68L14.3899 27.3151L5.51294 18.5893C5.04628 18.1199 4.88201 17.4376 5.08666 16.8071C5.29069 16.1843 5.82824 15.7333 6.46909 15.6422L6.46909 15.6422L6.47479 15.6414L18.7093 13.8305L19.2318 13.7532L19.4625 13.2781L24.95 1.97501L24.952 1.97096C25.2366 1.3779 25.8397 1 26.496 1C27.1625 1 27.7646 1.37949 28.0485 1.97097L28.0502 1.97445L33.5292 13.2775Z" stroke="${strokeColor}" stroke-width="3"/>
        `,
      };
    }
    case MarkerShape.TRIANGLE: {
      const size = [47, 44] as Size;
      return {
        badgeAnchor: [20, -12],
        badgeShapeIncreaser: [10, 10],
        shapeAnchor: [size[0] / 2, size[1] / 2], // center of icon
        shapeSize: size,
        shapeSvg: `
          <path d="M40.624 29.0098C41.123 29.8506 41.123 30.8965 40.6377 31.751C40.1523 32.5986 39.2431 33.125 38.2656 33.125H8.73437C7.7541 33.125 6.84834 32.5986 6.36141 31.751C5.87428 30.8965 5.87996 29.8506 6.37618 29.0098L21.1416 3.8501C21.6338 3.01406 22.5293 2.5 23.5 2.5C24.4707 2.5 25.3662 3.01406 25.8584 3.8501L40.624 29.0098Z" fill="${fillColor}"/>
          <path d="M41.5055 32.2479L41.5072 32.2449C42.165 31.0869 42.1709 29.6585 41.4852 28.5015C41.4848 28.5008 41.4844 28.5001 41.484 28.4994L26.7208 3.34395L26.7201 3.34277C26.0489 2.20266 24.8263 1.5 23.5 1.5C22.1737 1.5 20.951 2.20266 20.2798 3.34277L20.2791 3.34395L5.51497 28.5015C5.51476 28.5019 5.51455 28.5022 5.51434 28.5026C4.83477 29.6549 4.8297 31.0833 5.49266 32.2462L5.4943 32.2491C6.1591 33.4064 7.39512 34.125 8.73437 34.125H38.2656C39.601 34.125 40.8417 33.4072 41.5055 32.2479Z" stroke="${strokeColor}" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        `,
      };
    }
    case MarkerShape.TRIP_FINISH: {
      const size = [35, 38] as Size;
      return {
        badgeAnchor: [30, -8],
        badgeShapeIncreaser: [8, 8],
        shapeAnchor: [8, size[1] - 10], // near left bottom corner of flag icon
        shapeSize: size,
        shapeSvg: `
          <path d="M8.50396 2C9.30294 2 10.0079 2.70312 10.0079 3.5V4.25L13.2508 3.45312C15.0368 3.03125 16.9168 3.21875 18.5617 4.0625C20.7237 5.14063 23.3086 5.14063 25.4705 4.0625L25.9405 3.82813C26.8805 3.35938 28.0555 4.0625 28.0555 5.14063V18.2188C28.0555 18.875 27.6325 19.4375 27.0685 19.625L25.4235 20.2344C23.2616 21.0781 20.8177 20.9375 18.7497 19.9062C16.9638 19.0156 14.9428 18.7812 13.0159 19.25L10.0079 20V24.5C10.0079 25.3438 9.30294 26 8.50396 26C7.65798 26 7 25.3438 7 24.5V3.5C7 2.70312 7.65798 2 8.50396 2ZM10.0079 10.8125L13.0159 10.1562V13.2031L10.0079 13.8594V16.9063L12.2639 16.3438C12.4989 16.2969 12.7339 16.25 13.0159 16.2031V13.2031L14.8018 12.8281C15.2248 12.7344 15.6008 12.6875 16.0238 12.7344V9.73438C16.6348 9.73438 17.2927 9.82813 17.9037 10.0156L19.0317 10.3438V13.4844L17.0577 12.875C16.7288 12.7812 16.3528 12.7344 16.0238 12.7344V16.0625C17.0108 16.1563 18.0447 16.3906 19.0317 16.7188V13.4844L20.0657 13.7656C20.7237 13.9531 21.3816 14.0938 22.0396 14.1406V11.0938C21.6636 11.0938 21.2876 11 20.9117 10.9062L19.0317 10.3438V7.4375C18.4207 7.25 17.8097 7.01562 17.1987 6.73438C16.8228 6.54688 16.3998 6.40625 16.0238 6.3125V9.73438C15.4128 9.6875 14.8018 9.73438 14.1908 9.875L13.0159 10.1562V6.59375L10.0079 7.34375V10.8125ZM22.0396 17.75C22.7916 17.8438 23.5906 17.7031 24.3896 17.4219L25.0476 17.1875V13.8125L24.6716 13.9062C23.7786 14.0938 22.8856 14.1875 22.0396 14.1406V17.75ZM25.0476 10.7656V7.4375C24.0606 7.71875 23.0266 7.85938 22.0396 7.85938V11.0938C22.6506 11.1875 23.3556 11.1406 23.9666 11L25.0476 10.7656Z" fill="${fillColor}" />
          <path d="M13.0434 2.57492L13.0434 2.57488L13.0355 2.57682L10.8762 3.10743C10.6802 1.98728 9.66609 1.09762 8.50396 1.09762C7.14364 1.09762 6.09762 2.22088 6.09762 3.5V24.5C6.09762 25.8444 7.16186 26.9024 8.50396 26.9024C9.78065 26.9024 10.9103 25.8625 10.9103 24.5V20.705L13.2291 20.1268C13.2299 20.1266 13.2307 20.1264 13.2315 20.1262C14.9478 19.7093 16.7498 19.9173 18.347 20.7138C20.6333 21.8539 23.3392 22.014 25.7441 21.0779L27.3695 20.4758C28.2985 20.159 28.9579 19.2525 28.9579 18.2188V5.14063C28.9579 3.38201 27.0687 2.25718 25.5378 3.02059L25.0678 3.25496C23.1609 4.20592 20.8762 4.20663 18.9687 3.2571C17.1221 2.31143 15.023 2.1073 13.0434 2.57492ZM12.1135 7.74876V9.42952L10.9103 9.69202V8.04876L12.1135 7.74876ZM15.1214 10.6386V11.832V11.8534C14.9484 11.8767 14.7795 11.9089 14.6115 11.946L13.9182 12.0916V10.8681L14.3969 10.7535C14.6393 10.6979 14.8807 10.6597 15.1214 10.6386ZM10.9103 14.5861L12.1135 14.3236V15.4536C12.1061 15.4551 12.0987 15.4566 12.0913 15.458L12.0873 15.4588L12.0663 15.463L12.0456 15.4682L10.9103 15.7512V14.5861ZM22.942 15.0437C23.3424 15.0246 23.7448 14.9817 24.1452 14.9196V16.551L24.0899 16.5707C24.0894 16.5709 24.0889 16.5711 24.0883 16.5713C23.6842 16.7134 23.301 16.8077 22.942 16.8504V15.1096V15.0437ZM16.9262 8.88892V7.60185C17.3261 7.78264 17.7272 7.94493 18.1293 8.08851V9.14107C17.7371 9.02325 17.3303 8.93931 16.9262 8.88892ZM16.9262 15.2694V13.7788L18.1293 14.1502V15.5172C17.73 15.4176 17.3273 15.3341 16.9262 15.2694ZM19.9341 12.7947V11.5557L20.653 11.7708L20.673 11.7768L20.6933 11.7818C20.7058 11.7849 20.7184 11.7881 20.7311 11.7913C20.8536 11.8218 20.9903 11.856 21.1372 11.8875V13.1024C20.8677 13.0468 20.5933 12.9777 20.313 12.8978L20.313 12.8977L20.3025 12.8949L19.9341 12.7947ZM22.942 10.2343V10.1914V8.7264C23.3419 8.6953 23.7446 8.64368 24.1452 8.57153V10.0379L23.7754 10.1181L23.7754 10.118L23.7642 10.1206C23.5 10.1814 23.2182 10.2204 22.942 10.2343Z" stroke="${strokeColor}" stroke-width="1.80476"/>
        `,
      };
    }
    case MarkerShape.TRIP_START: {
      const size = [35, 38] as Size;
      return {
        badgeAnchor: [30, -8],
        badgeShapeIncreaser: [8, 8],
        shapeAnchor: [8, size[1] - 10], // near left bottom corner of flag icon
        shapeSize: size,
        shapeSvg: `
          <path d="M10.0079 3.5V4.25L13.2508 3.45312C15.0368 3.03125 16.9168 3.21875 18.5617 4.0625C20.7237 5.14062 23.3086 5.14062 25.4705 4.0625L25.9405 3.82813C26.8805 3.35938 28.0555 4.0625 28.0555 5.14063V16.7188C28.0555 17.375 27.6325 17.8906 27.0685 18.125L25.4235 18.7344C23.2616 19.5781 20.8647 19.4375 18.7497 18.4062C16.9638 17.5156 14.9428 17.2813 13.0159 17.75L10.0079 18.5V24.5C10.0079 25.3438 9.30294 26 8.50396 26C7.65798 26 7 25.3438 7 24.5V3.5C7 2.70312 7.65798 2 8.50396 2C9.30294 2 10.0079 2.70312 10.0079 3.5Z" fill="${fillColor}" />
          <path d="M13.0434 2.57492L13.0434 2.57488L13.0355 2.57682L10.8762 3.10743C10.6802 1.98728 9.66609 1.09762 8.50396 1.09762C7.14364 1.09762 6.09762 2.22088 6.09762 3.5V24.5C6.09762 25.8444 7.16186 26.9024 8.50396 26.9024C9.78065 26.9024 10.9103 25.8625 10.9103 24.5V19.205L13.2291 18.6268C13.23 18.6266 13.2309 18.6264 13.2318 18.6262C14.9479 18.2094 16.7499 18.4173 18.347 19.2138L18.347 19.2138L18.3542 19.2173C20.6803 20.3515 23.3363 20.5151 25.7441 19.5779L27.382 18.9712L27.3985 18.9651L27.4148 18.9583C28.2541 18.6095 28.9579 17.8018 28.9579 16.7188V5.14063C28.9579 3.38201 27.0687 2.25718 25.5378 3.02059L25.0678 3.25496C23.1609 4.20592 20.8762 4.20663 18.9687 3.2571C17.1221 2.31143 15.023 2.1073 13.0434 2.57492Z" stroke="${strokeColor}" stroke-width="1.80476"/>
        `,
      };
    }
  }
};

export default getSvgForShape;
