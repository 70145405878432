import React, { useCallback } from "react";

import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "antd/es/form";
import { FormInstance } from "antd/es/form/hooks/useForm";
import Input from "antd/es/input";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";

import isEmailActivity from "@app/util/email/isEmailActivity";
import { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";

import FormValues from "../../types/FormValues";
import ActivityCompleted from "../ActivityCompleted";
import ActivityDate from "../ActivityDate";
import ActivityEndDate from "../ActivityEndDate";
import ActivityRepeat from "../ActivityRepeat";
import ActivityTime from "../ActivityTime";

import styles from "./ActivityFooter.module.scss";

const messages = defineMessages({
  autoEmailDisabledTooltip: {
    id: "createActivityModal.footer.scheduleActivity.autoEmail.disabledTooltip",
    defaultMessage:
      "Auto Email time can't be changed because they are logged once an email is successfully sent.",
    description: "Email activities cannot be scheduled",
  },
  disabledTooltip: {
    id: "createActivityModal.footer.scheduleActivity.disabled.tooltip",
    defaultMessage: "Unable to Edit Record",
    description: "Unable to edit record tooltip",
  },
});

interface Props {
  activity?: Activity;
  creating?: boolean;
  disabled?: boolean;
  form: FormInstance<FormValues>;
  onChange?: () => void;
}

const ActivityFooter: React.FC<Props> = ({ activity, creating, disabled, form, onChange }) => {
  const intl = useIntl();

  const handleChange = useCallback(() => {
    onChange?.();
  }, [onChange]);

  const dateValue = form.getFieldValue(ActivityFieldName.START_AT);
  const endDateValue = form.getFieldValue(ActivityFieldName.END_AT);
  const isValidDate = dateValue && Date.parse(dateValue.toString());
  const isValidEndDate = endDateValue && Date.parse(endDateValue.toString());

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={cn(styles.icon, styles.top)}>
          <FontAwesomeIcon icon={faClock} />
        </div>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.startAt !== curValues.startAt || prevValues.endAt !== curValues.endAt
          }
        >
          {({ getFieldValue }) => {
            const dateValue = getFieldValue(ActivityFieldName.START_AT);
            const isValidDate = dateValue && Date.parse(dateValue.toString());
            const disabledTooltip = isEmailActivity(activity)
              ? intl.formatMessage(messages.autoEmailDisabledTooltip)
              : disabled
              ? intl.formatMessage(messages.disabledTooltip)
              : undefined;
            return (
              <div className={styles.group}>
                <ActivityDate
                  disabledTooltip={disabledTooltip}
                  form={form}
                  onChange={handleChange}
                />
                {isValidDate && (
                  <>
                    <ActivityTime
                      disabledTooltip={disabledTooltip}
                      form={form}
                      onChange={handleChange}
                    />
                    {isValidEndDate && (
                      <ActivityEndDate
                        disabledTooltip={disabledTooltip}
                        form={form}
                        onChange={handleChange}
                      />
                    )}
                  </>
                )}
              </div>
            );
          }}
        </Form.Item>
      </div>

      {isValidDate && (
        <ActivityRepeat
          disableText={
            isEmailActivity(activity)
              ? intl.formatMessage(messages.disabledTooltip)
              : disabled
              ? intl.formatMessage(messages.disabledTooltip)
              : undefined
          }
          form={form}
          onChange={handleChange}
        />
      )}

      <ActivityCompleted
        creating={creating}
        disabled={isEmailActivity(activity) || disabled}
        form={form}
        onChange={handleChange}
        tooltip={
          isEmailActivity(activity) ? intl.formatMessage(messages.disabledTooltip) : undefined
        }
      />

      <Form.Item hidden name="recurIntervalType">
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item hidden name="isRecurrent">
        <Input disabled={disabled} />
      </Form.Item>
    </div>
  );
};

export default ActivityFooter;
