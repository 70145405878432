import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "antd/es/dropdown";
import type { ItemType } from "antd/es/menu/hooks/useItems";
import Modal from "antd/es/modal";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import StandardActivityTypeName from "@mapmycustomers/shared/enum/activity/ActivityType";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";

import ButtonLink from "@app/component/ButtonLink";
import { getActiveActivityTypes } from "@app/store/activity";
import { checkEmailSupporting, showEmailCreationModal } from "@app/store/email/actions";
import { doesOrganizationSupportEmailService, isCurrentUserReadOnly } from "@app/store/iam";
import sendEmailViaClient from "@app/util/email/sendEmailViaClient";

import { RootState } from "../../store/rootReducer";
import useAnalytics from "../../util/contexts/useAnalytics";
import useEmailSendVariants from "../../util/email/useEmailSendVariants";

import styles from "./Email.module.scss";

interface Props {
  activityTypes: ActivityType[];
  checkEmailSupporting: typeof checkEmailSupporting;
  className?: string;
  entity?: EntitiesSupportedByEmailFeature;
  isReadOnlyMember: boolean;
  onShowEmailCreationModal: typeof showEmailCreationModal;
  showIcon?: boolean;
  value: null | string | undefined;
}

const Email: React.FC<Props> = ({
  activityTypes,
  checkEmailSupporting,
  className,
  entity,
  isReadOnlyMember,
  onShowEmailCreationModal,
  showIcon = true,
  value,
}) => {
  const intl = useIntl();
  const analyticService = useAnalytics();
  const [modal, contextHolder] = Modal.useModal();
  const isEmailActivityTypeActive = useMemo(
    () =>
      activityTypes.some(({ active, name }) => name === StandardActivityTypeName.EMAIL && active),
    [activityTypes]
  );

  const handleOpenLink = useCallback(() => {
    sendEmailViaClient(
      value ?? "",
      intl,
      entity?.name ?? "",
      isEmailActivityTypeActive ? modal : undefined,
      () => { }
    );
    analyticService?.clicked(["Email from email client"], { email: value });
  }, [value, intl, entity?.name, isEmailActivityTypeActive, modal, analyticService]);

  const handleShowViaMmc = useCallback(() => {
    if (entity) {
      checkEmailSupporting({
        modal,
        successfulCallback: () => {
          onShowEmailCreationModal({ recipients: [entity] });
        },
      });
      analyticService?.clicked(["Email from MMC"], { email: value });
    }
  }, [analyticService, checkEmailSupporting, entity, modal, onShowEmailCreationModal, value]);

  const menuItems: ItemType[] = useEmailSendVariants(
    intl,
    handleShowViaMmc,
    handleOpenLink,
    value ?? "",
    isReadOnlyMember
  );

  if (!value) {
    return <>{""}</>;
  }

  return (
    <div className={className}>
      <Tooltip
        title={intl.formatMessage({
          id: "component.field.email.tooltip",
          defaultMessage: "Send email",
          description: "Tooltip for email field",
        })}
      >
        <Dropdown menu={{ items: menuItems }} trigger={["hover"]}>
          <ButtonLink>
            {value}
            {showIcon && (
              <FontAwesomeIcon className={styles.emailIcon} icon={faEnvelope} size="xs" />
            )}
          </ButtonLink>
        </Dropdown>
      </Tooltip>
      {contextHolder}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activityTypes: getActiveActivityTypes(state),
  isReadOnlyMember: isCurrentUserReadOnly(state),
  organizationSupportEmailService: doesOrganizationSupportEmailService(state),
});

const mapDispatchToProps = {
  checkEmailSupporting,
  onShowEmailCreationModal: showEmailCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Email);
