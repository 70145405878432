import { defineMessages, IntlShape } from "react-intl";

import TripMode from "@mapmycustomers/shared/enum/mileageTracking/TripMode";

const messages = defineMessages<TripMode>({
  [TripMode.AUTO]: {
    id: "tripMode.auto",
    defaultMessage: "Auto",
    description: "Name of the Auto trip mode",
  },
  [TripMode.MANUAL]: {
    id: "tripMode.manual",
    defaultMessage: "Manual",
    description: "Name of the Manual trip mode",
  },
});

const getTripModeDisplayName = (intl: IntlShape, tripMode: TripMode) =>
  intl.formatMessage(messages[tripMode]);

export default getTripModeDisplayName;
