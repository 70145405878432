import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Alert, LoadingSpinner } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";
import { RootState } from "@app/store/rootReducer";

import { isEntityRelating, isEntityRelatingSuccessful } from "../../store";
import { relateUnrelateEntities } from "../../store/actions";

import { messages } from "./messages";
import styles from "./RelatedEntitiesMatching.module.scss";

interface Props {
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  entityRelatingSuccess: boolean;
  isEntityRelating: boolean;
  relateUnrelateEntities: typeof relateUnrelateEntities.request;
}

const RelatedEntitiesMatching: React.FC<Props> = ({
  associatedCompany,
  associatedDeal,
  associatedPerson,
  entityRelatingSuccess,
  isEntityRelating,
  relateUnrelateEntities,
}) => {
  const intl = useIntl();

  const [visible, show, hide] = useBoolean();
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [savedCompanyId, setSavedCompanyId] = useState<Company["id"] | undefined>();
  const [savedDealId, setSavedDealId] = useState<Deal["id"] | undefined>();
  const [savedPersonId, setSavedPersonId] = useState<Person["id"] | undefined>();

  const isPersonCorrectlyRelatedToCompany =
    !associatedCompany ||
    !associatedPerson ||
    (associatedPerson?.accounts ?? []).some(({ id }) => associatedCompany?.id === id);
  const isDealCorrectlyRelatedToCompany =
    !associatedDeal || !associatedCompany || associatedCompany?.id === associatedDeal?.account?.id;
  const isDealCorrectlyRelatedToPerson =
    !associatedDeal || !associatedPerson || associatedPerson?.id === associatedDeal?.contact?.id;

  useEffect(() => {
    setSavedCompanyId(associatedCompany?.id);
    setSavedDealId(associatedDeal?.id);
    setSavedPersonId(associatedPerson?.id);
  }, [
    associatedCompany?.id,
    associatedDeal?.id,
    associatedPerson?.id,
    savedCompanyId,
    savedDealId,
    savedPersonId,
  ]);

  const message = useMemo(() => {
    if (entityRelatingSuccess) {
      return successMessage;
    }
    if (
      !isPersonCorrectlyRelatedToCompany ||
      !isDealCorrectlyRelatedToCompany ||
      !isDealCorrectlyRelatedToPerson
    ) {
      return intl.formatMessage(messages.recommend);
    }
    return "";
  }, [
    intl,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    entityRelatingSuccess,
    successMessage,
  ]);

  const handleRelateEntities = useCallback(() => {
    relateUnrelateEntities({
      associatedCompany,
      associatedDeal,
      associatedPerson,
      isDealCorrectlyRelatedToCompany,
      isDealCorrectlyRelatedToPerson,
      isPersonCorrectlyRelatedToCompany,
    });
  }, [
    associatedCompany,
    associatedDeal,
    associatedPerson,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    relateUnrelateEntities,
  ]);

  const handleUndo = useCallback(() => {
    relateUnrelateEntities({
      associatedCompany,
      associatedDeal,
      associatedPerson,
      isDealCorrectlyRelatedToCompany,
      isDealCorrectlyRelatedToPerson,
      isPersonCorrectlyRelatedToCompany,
      unrelate: true,
    });
  }, [
    associatedCompany,
    associatedDeal,
    associatedPerson,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
    relateUnrelateEntities,
  ]);

  useEffect(() => {
    if (
      isPersonCorrectlyRelatedToCompany ||
      isDealCorrectlyRelatedToCompany ||
      isDealCorrectlyRelatedToPerson
    ) {
      setSuccessMessage(intl.formatMessage(messages.nowRelated));
    }
  }, [
    intl,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
  ]);

  useEffect(() => {
    if (
      entityRelatingSuccess ||
      !isDealCorrectlyRelatedToCompany ||
      !isDealCorrectlyRelatedToPerson ||
      !isPersonCorrectlyRelatedToCompany
    ) {
      show();
    } else {
      hide();
    }
  }, [
    show,
    hide,
    entityRelatingSuccess,
    isDealCorrectlyRelatedToCompany,
    isDealCorrectlyRelatedToPerson,
    isPersonCorrectlyRelatedToCompany,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <Alert
      action={
        isEntityRelating ? (
          <LoadingSpinner micro />
        ) : entityRelatingSuccess ? (
          <ButtonLink onClick={handleUndo}>{intl.formatMessage(messages.undo)}</ButtonLink>
        ) : (
          <ButtonLink onClick={handleRelateEntities}>
            {intl.formatMessage(messages.relateRecords)}
          </ButtonLink>
        )
      }
      className={styles.container}
      closable={entityRelatingSuccess}
      message={message}
      onClose={hide}
      showIcon
      type={entityRelatingSuccess ? "success" : "info"}
    />
  );
};

export const mapStateToProps = (state: RootState) => ({
  entityRelatingSuccess: isEntityRelatingSuccessful(state),
  isEntityRelating: isEntityRelating(state),
});

const mapDispatchToProps = {
  relateUnrelateEntities: relateUnrelateEntities.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedEntitiesMatching);
