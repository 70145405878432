import React from "react";

import Dropdown from "antd/es/dropdown";
import Menu from "antd/es/menu";
import { defineMessages, useIntl } from "react-intl";

import styles from "./CreateEditActivityModal.module.scss";
import { RecurringActionType } from "./enum/RecurringActionType";

const messages = defineMessages({
  deleteMenuAll: {
    id: "editActivityModal.footer.delete.popover.menu.all",
    defaultMessage: "Delete all activities (past and future)",
    description: "Delete all option from edit modal popover menu",
  },
  deleteMenuAssigneeNotCreatorTitle: {
    id: "editActivityModal.footer.delete.popover.assigneeNotCreator.title",
    defaultMessage:
      "Please note, updates will only apply to activity instances you are the creator or assignee of.",
    description:
      "Delete activity from edit modal popover title where assignee is not the creator of activity",
  },
  deleteMenuOnlyThis: {
    id: "editActivityModal.footer.delete.popover.menu.onlyThis",
    defaultMessage: "Delete only this activity",
    description: "Deletee only this option from edit modal popover menu",
  },
  deleteMenuThisAndFuture: {
    id: "editActivityModal.footer.delete.popover.menu.thisAndFuture",
    defaultMessage: "Delete This and future activities",
    description: "Delete this and future option from edit modal popover menu",
  },
  deleteMenuTitle: {
    id: "editActivityModal.footer.delete.popover.title",
    defaultMessage: "Delete repeating activity?",
    description: "Delete activity from edit modal popover title",
  },
});

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  isAssigneeNotCreator: boolean;
  onDelete: (deleteType?: RecurringActionType) => void;
}

export const RecurringDeleteDropdown: React.FC<Props> = ({
  children,
  disabled,
  isAssigneeNotCreator,
  onDelete,
}) => {
  const intl = useIntl();

  return (
    <Dropdown
      disabled={disabled}
      overlay={
        <Menu>
          <Menu.ItemGroup
            title={
              isAssigneeNotCreator
                ? intl.formatMessage(messages.deleteMenuAssigneeNotCreatorTitle)
                : intl.formatMessage(messages.deleteMenuTitle)
            }
          >
            <Menu.Item onClick={() => onDelete()}>
              {intl.formatMessage(messages.deleteMenuOnlyThis)}
            </Menu.Item>

            <Menu.Item onClick={() => onDelete(RecurringActionType.Future)}>
              {intl.formatMessage(messages.deleteMenuThisAndFuture)}
            </Menu.Item>

            <Menu.Item onClick={() => onDelete(RecurringActionType.All)}>
              {intl.formatMessage(messages.deleteMenuAll)}
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>
      }
      overlayClassName={styles.actionDropdown}
      trigger={disabled ? [] : ["click"]}
    >
      {children}
    </Dropdown>
  );
};
